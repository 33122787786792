import moment from "moment";
import { JournalEntry_Entity } from "../entities/journalEntry";
import { Payable_Entity } from "../entities/payable";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

export function getEmptyPayable(): Payable_Entity {
  return {
    id: 0,
    companyName: "",
    vendorName: "",
    priorityLevelID: 2,
    referenceNumber: "",
    assignedToUUID: "",
    dualAssignedToUUID: "",
    memo: "",
    date: moment().format("YYYY-MM-DD"),
    transactionType: 0,
    approved: false,
    dualApproved: false,
    createdAt: "",
    updatedAt: "",
    locked: false,
    deleted: false,
    files: [],
    lineItems: [],
    lockable: false,
    toCreate: true,
  };
}

export function getEmptyJournalEntry(): JournalEntry_Entity {
  return {
    id: 0,
    companyName: "",
    referenceNumber: "",
    memo: "",
    locked: false,
    deleted: false,
    files: [],
    journalEntryItems: [],
    createdAt: "string",
    updatedAt: "string",
    toCreate: true,
  };
}

export function getWidthFromLocalStorage(
  entityName: string,
  field: string,
  fallback: number
): number {
  const match = localStorage.getItem(`${entityName}_${field}_width`);
  if (match) {
    try {
      return parseFloat(match);
    } catch (error) {
      return fallback;
    }
  }
  return fallback;
}
