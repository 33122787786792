/**
 * GLAccount_Entity type guard.
 *
 * @param {any} gLAccountJson GLAccount object from API
 * @returns {boolean} Return true if type is GLAccount_Entity
 */
function validator(gLAccountJson: unknown): gLAccountJson is GLAccount_Entity {
  if (typeof gLAccountJson === "object" && gLAccountJson != null) {
    GLAccount_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(gLAccountJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class GLAccount_Entity {
  static requiredFields = [
    "id",
    "companyID",
    "companyName",
    "name",
    "type",
    "description",
    "accountNumber",
    "createdAt",
    "updatedAt",
    "refNum",
    "timestamp",
    "hidden",
    "delcount",
  ];

  constructor(gLAccountJson: unknown) {
    if (validator(gLAccountJson)) {
      this.id = gLAccountJson.id;
      this.companyID = gLAccountJson.companyID;
      this.companyName = gLAccountJson.companyName;
      this.name = gLAccountJson.name;
      this.type = gLAccountJson.type;
      this.description = gLAccountJson.description;
      this.accountNumber = gLAccountJson.accountNumber;
      this.createdAt = gLAccountJson.createdAt;
      this.updatedAt = gLAccountJson.updatedAt;
      this.refNum = gLAccountJson.refNum;
      this.timestamp = gLAccountJson.timestamp;
      this.hidden = gLAccountJson.hidden;
      this.delcount = gLAccountJson.delcount;
    } else {
      throw new Error(
        "Failed to create new instance of ${ GLAccount_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  companyID: number;
  companyName: string;
  name: string;
  type: Type;
  description: string;
  accountNumber: string;
  createdAt: string;
  updatedAt: string;
  refNum: number;
  timestamp: number;
  hidden: boolean;
  delcount: number;
}

enum Type {
  Ap = "AP",
  Ar = "AR",
  Bank = "BANK",
  Ccard = "CCARD",
  Cogs = "COGS",
  Equity = "EQUITY",
  Exinc = "EXINC",
  Exp = "EXP",
  Fixasset = "FIXASSET",
  Inc = "INC",
  Ltliab = "LTLIAB",
  Nonposting = "NONPOSTING",
  Oasset = "OASSET",
  Ocasset = "OCASSET",
  Ocliab = "OCLIAB",
}
