import { Filters, SearchMetadata } from "@nerdjs/nerd-core";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { File_Entity } from "../../entities/file";
import { LineItem_Entity } from "../../entities/lineItem";
import { Payable_Entity } from "../../entities/payable";
import { VendorLineItem_Entity } from "../../entities/vendorLineItem";

export const stagedPayableUpdateRequest = createAction(
  "stagedPayableUpdateRequest",
  function prepare(
    navigate: NavigateFunction,
    close?: boolean,
    body?: Partial<Payable_Entity>,
    callback?: () => void
  ) {
    return {
      payload: {
        navigate,
        close,
        body,
        callback,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const stagedPayableCreateRequest = createAction(
  "stagedPayableCreateRequest",
  function prepare(navigate: NavigateFunction, close?: boolean) {
    return {
      payload: {
        navigate,
        close,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setStagedPayable = createAction(
  "setStagedPayable",
  function prepare(stagedPayable?: Payable_Entity) {
    return {
      payload: {
        stagedPayable,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPayableLoading = createAction(
  "setPayableLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setStagedPayableLoading = createAction(
  "setStagedPayableLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPayableSearchOpen = createAction(
  "setPayableSearchOpen",
  function prepare(searchOpen: boolean) {
    return {
      payload: {
        searchOpen,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPayableFilters = createAction(
  "setPayableFilters",
  function prepare(filters?: Filters) {
    return {
      payload: {
        filters,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedPayableLineItem = createAction(
  "updateStagedPayableLineItem",
  function prepare(lineItem?: LineItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const reorderStagedPayableLineItems = createAction(
  "reorderStop",
  function prepare(oldIndex: number, targetIndex: number) {
    return {
      payload: {
        oldIndex,
        targetIndex,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteStagedPayableLineItem = createAction(
  "deleteStagedPayableLineItem",
  function prepare(lineItem?: LineItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteStagedPayableFile = createAction(
  "deleteStagedPayableFile",
  function prepare(file: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const newStagedPayableFile = createAction(
  "newStagedPayableFile",
  function prepare(file: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const newStagedPayableLineItem = createAction(
  "newStagedPayableLineItem",
  function prepare(lineItem?: LineItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedPayable = createAction(
  "updateStagedPayable",
  function prepare(stagedPayable?: Partial<Payable_Entity>) {
    return {
      payload: {
        stagedPayable,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedPayableID = createAction(
  "setSelectedPayableID",
  function prepare(payableID?: number) {
    return {
      payload: {
        payableID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getMyPayables = createAction(
  "ap-dashboard/payable/getMyPayables",
  function prepare(query?: { [key: string]: unknown }) {
    const _query = query ?? {};
    if (!_query.limit) _query.limit = 25;
    if (!_query.offset) _query.offset = 0;
    _query.sorter = JSON.stringify([{ name: "payables.id", asc: false }]);
    const request = new GetAction(`/ap-dashboard/my/payables`, _query);

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity[]) => {
      dispatch(getMyPayablesSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getMyPayablesSuccess = createAction(
  "ap-dashboard/payable/getMyPayablesSuccess",
  function prepare(payables: Payable_Entity[]) {
    return {
      payload: {
        payables,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPayables = createAction(
  "ap-dashboard/payable/getPayables",
  function prepare() {
    const request = new GetAction(
      `/ap-dashboard/payables?limit=25&sorter=[{"name":"payables.id","asc":false}]`
    );

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity[]) => {
      dispatch(getPayablesSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPayablesSuccess = createAction(
  "ap-dashboard/payable/getPayableSuccess",
  function prepare(payables: Payable_Entity[]) {
    return {
      payload: {
        payables,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPayablesByFilter = createAction(
  "ap-dashboard/payable/getPayablesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    if (!query.limit) query.limit = 25;
    if (!query.offset) query.offset = 0;
    query.sorter = JSON.stringify([{ name: "payables.id", asc: false }]);
    const request = new GetAction(`/ap-dashboard/payables`, query);

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity[]) => {
      dispatch(getPayablesSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getMyPayablesByFilter = createAction(
  "ap-dashboard/payable/getMyPayablesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    if (!query.limit) query.limit = 25;
    if (!query.offset) query.offset = 0;
    query.sorter = JSON.stringify([{ name: "payables.id", asc: false }]);
    const request = new GetAction(`/ap-dashboard/my/payables`, query);

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity[]) => {
      dispatch(getMyPayablesSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPayablesReadyToExport = createAction(
  "ap-dashboard/payable/getPayablesByFilter",
  function prepare(companyID: number, transactionType: number, mine?: boolean) {
    const filters = [
      {
        name: "payables.transactionType",
        comparison: "eq",
        value: transactionType,
      },
      { name: "payables.companyID", comparison: "eq", value: companyID },
      { name: "payables.locked", comparison: "eq", value: true },
      { name: "payables.exportID", comparison: "eq", value: 0 },
    ];
    const query = { filters: JSON.stringify(filters) };
    let request;
    if (mine) {
      request = new GetAction(`/ap-dashboard/my/payables`, query);
    } else {
      request = new GetAction(`/ap-dashboard/payables`, query);
    }

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity[]) => {
      dispatch(getPayablesReadyToExportSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPayablesReadyToExportSuccess = createAction(
  "ap-dashboard/payable/getPayablesReadyToExportSuccess",
  function prepare(payables?: Payable_Entity[]) {
    return {
      payload: {
        payables,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPayableById = createAction(
  "ap-dashboard/payable/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/payables/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity) => {
      dispatch(getPayableByIdSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPayableByIdSuccess = createAction(
  "ap-dashboard/payable/getPayableByIdSuccess",
  function prepare(payable?: Payable_Entity) {
    return {
      payload: {
        payable,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deletePayable = createAction(
  "ap-dashboard/removePayableById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/payables/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deletePayableSuccess = createAction(
  "ap-dashboard/payables/deletePayableSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updatePayable = createAction(
  "ap-dashboard/payables/updatePayable",
  function prepare(id: number, body: Partial<Payable_Entity>) {
    const request = new PutAction(`/ap-dashboard/payables/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updatePayableSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const lockPayable = createAction(
  "ap-dashboard/payables/lockPayable",
  function prepare(
    id: number,
    body: {
      locked: boolean;
    }
  ) {
    const request = new PutAction(`/ap-dashboard/payables/${id}/lock`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(lockPayableSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const lockPayableSuccess = createAction(
  "ap-dashboard/payables/lockPayableSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updatePayableSuccess = createAction(
  "ap-dashboard/payables/updatePayableSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createPayable = createAction(
  "ap-dashboard/payables/createPayable",
  function prepare(body: Partial<Payable_Entity>) {
    const request = new PostAction(`/ap-dashboard/payables`, body);

    request.decodeResponse = (dispatch: Dispatch, json: Payable_Entity) => {
      dispatch(createPayableSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

// POST new Payable files
export const uploadPayablefile = createAction(
  "ap-dashboard/payables/uploadPayablefile",
  function prepare(id: number, file: File) {
    const data = new FormData();
    data.append("file", file);

    const request = new PostAction(`/ap-dashboard/payables/${id}/upload`, data);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(uploadPayableFilesSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const uploadPayableFilesSuccess = createAction(
  "ap-dashboard/payables/uploadPayableFilesSuccess",
  function prepare(isFileUploadOk: boolean) {
    return {
      payload: {
        isFileUploadOk,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

// DELETE new Payable files
export const deletePayablefile = createAction(
  "ap-dashboard/payables/deletePayablefile",
  function prepare(payableID: number, fileID: number) {
    const request = new DeleteAction(
      `/ap-dashboard/payables/${payableID}/files/${fileID}`
    );

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(deletePayableFilesSuccess());
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deletePayableFilesSuccess = createAction(
  "ap-dashboard/payables/deletePayableFilesSuccess",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createPayableSuccess = createAction(
  "ap-dashboard/payables/createPayableSuccess",
  function prepare(payable: Payable_Entity) {
    return {
      payload: {
        payable,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPayablesSearchMetadata = createAction(
  "ap-dashboard/getPayablesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/payables/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: SearchMetadata) => {
      dispatch(getPayablesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPayablesSearchMetadataSuccess = createAction(
  "ap-dashboard/getPayablesSearchMetadataSuccess",
  function prepare(payablesSearchMetadata: SearchMetadata) {
    return {
      payload: {
        payablesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteStagedVendorLineItem = createAction(
  "deleteStagedVendorLineItem",
  function prepare(lineItem?: VendorLineItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const newStagedVendorLineItem = createAction(
  "newStagedVendorLineItem",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedVendorLineItem = createAction(
  "updateStagedVendorLineItem",
  function prepare(lineItem?: VendorLineItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
