import Company_Entity from "./company";
export default class Company extends Company_Entity {
  static fromList(companysJSON: unknown): Array<Company> {
    const companys: Company[] = [];
    if (companysJSON)
      Array.isArray(companysJSON) &&
        companysJSON.forEach((companyJSON) => {
          companys.push(new Company(companyJSON));
        });
    return companys;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  lockedBy(): string {
    const createdBy = this.glLockDate.createdBy;
    if (createdBy) {
      return createdBy.fullname();
    }
    return "";
  }
}
