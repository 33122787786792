import {
  Button,
  IconButton,
  Input,
  List,
  ListItemButton,
  Typography,
} from "@mui/joy";
import { Box, Dialog, Tooltip } from "@mui/material";
import { User } from "@nerdjs/account-kit";
import "allotment/dist/style.css";
import moment from "moment";
import { MouseEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUsersByFilter } from "../../redux/user/userActions";
import { usersSelector } from "../../redux/user/userSelector";
import { useAppSelector } from "../hooks";

export default function Reviewer({
  user,
  secondary,
  approved = false,
  date,
  onChange,
  disabled,
  disableHelperText = false,
}: {
  user?: User;
  date?: string;
  secondary?: boolean;
  approved: boolean;
  onChange: (user: User | undefined) => void;
  disabled?: boolean;
  disableHelperText?: boolean;
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const users = useAppSelector(usersSelector);

  useEffect(() => {
    if (users.length === 0)
      dispatch(
        getUsersByFilter({
          filters: JSON.stringify([
            {
              name: "roles.serviceNameID",
              value: 17,
              comparison: "eq",
            },
          ]),
        })
      );
  }, []);

  useEffect(() => {
    if (!open) setSearch("");
  }, [open]);

  let color:
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "neutral"
    | "danger"
    | undefined = "neutral";
  if (user) color = "primary";
  if (user && approved) color = "success";

  const handleClick = (_e: MouseEvent) => {
    setOpen(true);
  };

  return (
    <Box>
      <Button
        disabled={disabled}
        onClick={handleClick}
        color={color}
        variant={user ? "soft" : "outlined"}
        startDecorator={
          user ? (
            <i className="fa-solid fa-user"></i>
          ) : (
            <i className="fa-regular fa-circle-plus"></i>
          )
        }
        endDecorator={
          user ? (
            <Tooltip title="Remove">
              <IconButton
                disabled={disabled}
                size="sm"
                color={color}
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange(undefined);
                }}
              >
                <i className="fa-regular fa-xmark"></i>
              </IconButton>
            </Tooltip>
          ) : (
            []
          )
        }
      >
        {user
          ? `${user.firstName} ${user.lastName}`
          : `Add${secondary ? " Secondary" : ""} Reviewer`}
      </Button>
      {user && approved && date && !disableHelperText ? (
        <Tooltip title={moment(date).format("MM/DD/YYYY HH:mm")}>
          <Typography level="body3" color={color}>
            Approved {moment(date).fromNow()}
          </Typography>
        </Tooltip>
      ) : (
        []
      )}

      {user && !approved && !disableHelperText ? (
        <Typography level="body3" color={color}>
          Has not approved yet
        </Typography>
      ) : (
        []
      )}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Box sx={{ width: 300, height: 600 }}>
          <Box sx={{ p: 1 }}>
            <Input
              startDecorator={<i className="fa-solid fa-magnifying-glass"></i>}
              autoFocus
              fullWidth
              placeholder="Search..."
              onChange={(e) => setSearch(e.target.value)}
              size="sm"
            />
          </Box>
          <List sx={{ maxHeight: 600, overflow: "scroll" }}>
            {users
              .filter(
                (u) =>
                  u.fullname().toLowerCase().indexOf(search.toLowerCase()) >= 0
              )
              .map((u) => (
                <ListItemButton
                  onClick={() => {
                    onChange(u);
                    setOpen(false);
                  }}
                  key={u.id}
                >
                  {u.fullname()}
                </ListItemButton>
              ))}
          </List>
        </Box>
      </Dialog>
    </Box>
  );
}
