import { Box, Button, Typography } from "@mui/material";
import { DataGridPro, GridColumns, useGridApiRef } from "@mui/x-data-grid-pro";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Company } from "../../entities/company";
import { useAppSelector } from "../../atoms/hooks";
import { companiesSelector } from "../../redux/company/companySelector";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { setSelectedCompanyID } from "../../redux/company/companyActions";
import { CompanyLockDate } from "./companyLockDate";

/**
 *
 * @returns {ReactElement} CompaniesDataGrid component
 */
export function CompaniesDataGrid() {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const companies = useAppSelector(companiesSelector);

  const columns: GridColumns<Company> = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "glLockDate",
      headerName: "Lock Info",
      flex: 1,
      renderCell: (p) => (
        <Box sx={{ lineHeight: 0.8 }}>
          <Typography variant="body2">
            {moment(p.row.glLockDate.date).format("MM/DD/YYYY")}
          </Typography>
          <Typography variant="caption">
            Set by {p.row.lockedBy()} on{" "}
            {moment(p.row.glLockDate.date).format("MM/DD/YYYY")}
          </Typography>
        </Box>
      ),
    },
    {
      field: "newLockDate",
      headerName: "Set Date",
      flex: 1,
      renderCell: (p) => (
        <Box sx={{ lineHeight: 0.8 }}>
          <Button
            onClick={() => {
              dispatch(setSelectedCompanyID(p.row.id));
              dispatch(setFormOpen(true, "companyLockDate"));
            }}
            size="small"
            variant="contained"
          >
            Set Lock Date
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      <DataGridPro
        rows={companies}
        columns={columns}
        experimentalFeatures={{ newEditingApi: true }}
        apiRef={apiRef}
        density="compact"
        rowHeight={60}
        getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
        components={{
          Footer: CustomFooter,
          Toolbar: CustomToolbar,
        }}
        componentsProps={{ toolbar: {}, footer: {} }}
        initialState={{
          pinnedColumns: {
            left: ["id"],
          },
        }}
        disableSelectionOnClick
      />
      <CompanyLockDate />
    </>
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewCompany = () => {
    console.log("Do someting!", dispatch);
  };

  useHotkeys(`ctrl+enter`, handleNewCompany);

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, position: "relative" }}
    >
      {/* <Tooltip title="ctrl + enter">
        <Button size="small" onClick={handleNewCompany}>
          + New Company
        </Button>
      </Tooltip> */}
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  return <Box className="footer" sx={{ display: "flex" }}></Box>;
}
