import { User } from "@nerdjs/account-kit";
import { File } from "../file";
import { VendorLineItem } from "../vendorLineItem";
import Vendor_Entity from "./vendor";
export default class Vendor extends Vendor_Entity {
  defaultLineItems: Array<VendorLineItem>;
  defaultDualAssignedTo?: User;
  defaultAssignedTo?: User;
  files?: Array<File>;

  constructor(vendorJson: Vendor_Entity) {
    super(vendorJson);
    if (vendorJson.defaultDualAssignedTo)
      this.defaultDualAssignedTo = new User(vendorJson.defaultDualAssignedTo);
    if (vendorJson.defaultAssignedTo)
      this.defaultAssignedTo = new User(vendorJson.defaultAssignedTo);
    if (vendorJson.defaultLineItems) {
      this.defaultLineItems = VendorLineItem.fromList(
        vendorJson.defaultLineItems
      );
      if (vendorJson.files) {
        this.files = File.fromList(vendorJson.files);
      } else {
        {
          this.files = [];
        }
      }
    } else {
      this.defaultLineItems = [];
    }
  }

  static fromList(vendorsJSON: unknown): Array<Vendor> {
    const vendors: Vendor[] = [];
    if (vendorsJSON)
      Array.isArray(vendorsJSON) &&
        vendorsJSON.forEach((vendorJSON) => {
          vendors.push(new Vendor(vendorJSON));
        });
    return vendors;
  }

  body(): Partial<Vendor_Entity> {
    const body: Partial<Vendor_Entity> = {
      defaultAssignedToUUID: this.defaultAssignedToUUID ?? null,
      defaultDualAssignedToUUID: this.defaultDualAssignedToUUID ?? null,
    };

    return body;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  lineItemsToCreate() {
    return this.defaultLineItems.filter((li) => li.toCreate && !li.toDelete);
  }

  lineItemsToDelete() {
    return this.defaultLineItems.filter((li) => li.toDelete && !li.toCreate);
  }

  lineItemsToUpdate() {
    return this.defaultLineItems.filter(
      (li) => li.toUpdate && !li.toDelete && !li.toCreate
    );
  }

  filesToUpload() {
    return this.files?.filter((file) => {
      return file.id === -1;
    });
  }
}
