/**
 * PriorityLevel_Entity type guard.
 *
 * @param {any} priorityLevelJson PriorityLevel object from API
 * @returns {boolean} Return true if type is PriorityLevel_Entity
 */
function validator(
  priorityLevelJson: unknown
): priorityLevelJson is PriorityLevel_Entity {
  if (typeof priorityLevelJson === "object" && priorityLevelJson != null) {
    PriorityLevel_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(priorityLevelJson).includes(element))
        console.log(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class PriorityLevel_Entity {
  static requiredFields = ["id"];

  constructor(priorityLevelJson: unknown) {
    if (validator(priorityLevelJson)) {
      this.id = priorityLevelJson.id;
      this.name = priorityLevelJson.name;
      this.followUpDays = priorityLevelJson.followUpDays;
      this.createdAt = priorityLevelJson.createdAt;
      this.updatedAt = priorityLevelJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${PriorityLevel_Entity.name}`
      );
  }

  id: number;
  name: string;
  followUpDays: number;
  createdAt: Date;
  updatedAt: Date;
}
