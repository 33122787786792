import { NerdAppBarProps } from "@nerdjs/nerd-ui";
import { NerdAppBarDarkModeButton } from "../nerdAppBarDarkModeButton/nerdAppBarDarkModeButton";
import NerdAppsMenuWrapper from "../nerdAppsMenuWrapper/nerdAppsMenuWrapper";
import { NerdUserMenu } from "../nerdUserMenu/nerdUserMenu";
import { Typography } from "@mui/joy";
import { NerdImpersonate } from "../nerdImpersonate/nerdImpersonate";

export function appBarConfig(
  mode: "dark" | "light" | "system" = "light",
  isDownSm: boolean
): NerdAppBarProps {
  const logo =
    mode === "light"
      ? "https://storage.googleapis.com/archer-public/App%20Icons/logo_black.png"
      : "https://storage.googleapis.com/archer-public/App%20Icons/logo_white.png";

  return {
    title: "Archer Accounting",
    logo,
    divider: false,
    content: [
      <Typography key="version" level="body4">
        v{window.app_info?.tag}
      </Typography>,
      <div style={{ flexGrow: 1 }} key={"nerdAppMenustylingDiv"} />,
      <NerdAppsMenuWrapper key={"NerdAppsMenuWrapper"} />,
      <NerdAppBarDarkModeButton key={"nerdAppBarDarkModeButton"} />,
      <NerdUserMenu key={"myAccountButton"} />,
      <NerdImpersonate key={"NerdImpersonate"} />,
    ],
  };
}
