import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { JournalEntryItem } from "../../entities/journalEntryItem";

export const setSelectedJournalEntryItemID = createAction(
  "setSelectedJournalEntryItemID",
  function prepare(journalEntryItemID?: number) {
    return {
      payload: {
        journalEntryItemID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntryItems = createAction(
  "ap-dashboard/journalEntryItem/getJournalEntryItems",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/journalEntryItems`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const journalEntryItems = JournalEntryItem.fromList(json);
      dispatch(getJournalEntryItemsSuccess(journalEntryItems));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntryItemsSuccess = createAction(
  "ap-dashboard/journalEntryItem/getJournalEntryItemSuccess",
  function prepare(journalEntryItems: JournalEntryItem[]) {
    return {
      payload: {
        journalEntryItems,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntryItemsByFilter = createAction(
  "ap-dashboard/journalEntryItem/getJournalEntryItemsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/journalEntryItems`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const journalEntryItems = JournalEntryItem.fromList(json);
      dispatch(getJournalEntryItemsByFilterSuccess(journalEntryItems));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntryItemsByFilterSuccess = createAction(
  "ap-dashboard/journalEntryItem/getJournalEntryItemsByFilterSuccess",
  function prepare(journalEntryItems: JournalEntryItem[]) {
    return {
      payload: {
        journalEntryItems,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntryItemById = createAction(
  "ap-dashboard/journalEntryItem/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/journalEntryItems/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const journalEntryItem = new JournalEntryItem(json);
      dispatch(getJournalEntryItemByIdSuccess(journalEntryItem));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntryItemByIdSuccess = createAction(
  "ap-dashboard/journalEntryItem/getJournalEntryItemByIdSuccess",
  function prepare(journalEntryItem?: JournalEntryItem) {
    return {
      payload: {
        journalEntryItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteJournalEntryItem = createAction(
  "ap-dashboard/removeJournalEntryItemById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/journalEntryItems/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteJournalEntryItemSuccess = createAction(
  "ap-dashboard/journalEntryItems/deleteJournalEntryItemSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateJournalEntryItem = createAction(
  "ap-dashboard/journalEntryItems/updateJournalEntryItem",
  function prepare(id: number, body: Partial<JournalEntryItem>) {
    const request = new PutAction(
      `/ap-dashboard/journalEntryItems/${id}`,
      body
    );

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateJournalEntryItemSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateJournalEntryItemSuccess = createAction(
  "ap-dashboard/journalEntryItems/updateJournalEntryItemSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createJournalEntryItem = createAction(
  "ap-dashboard/journalEntryItems/createJournalEntryItem",
  function prepare(id: number, body: Partial<JournalEntryItem>) {
    const request = new PostAction(
      `/ap-dashboard/journalEntries/${id}/journalEntryItems`,
      body
    );

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const journalEntryItem = new JournalEntryItem(json);
      dispatch(createJournalEntryItemSuccess(journalEntryItem));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createJournalEntryItemSuccess = createAction(
  "ap-dashboard/journalEntryItems/createJournalEntryItemSuccess",
  function prepare(journalEntryItem: JournalEntryItem) {
    return {
      payload: {
        journalEntryItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntryItemsSearchMetadata = createAction(
  "ap-dashboard/getJournalEntryItemsSearchMetadata",
  function prepare() {
    const request = new GetAction(
      `/ap-dashboard/journalEntryItems/searchMetadata`
    );

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getJournalEntryItemsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntryItemsSearchMetadataSuccess = createAction(
  "ap-dashboard/getJournalEntryItemsSearchMetadataSuccess",
  function prepare(journalEntryItemsSearchMetadata: unknown) {
    return {
      payload: {
        journalEntryItemsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
