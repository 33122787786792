import { Button } from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { createCompanyLockDate } from "../../redux/company/companyActions";
import { selectedCompanySelector } from "../../redux/company/companySelector";

/**
 *
 * @returns {ReactElement} CompanyLockDate page
 */
export function CompanyLockDate() {
  const company = useAppSelector(selectedCompanySelector);
  const open = useAppSelector(formOpenSelector("companyLockDate"));
  const dispatch = useDispatch();

  const [lockDate, setLockDate] = useState<moment.Moment | undefined | null>(
    company?.glLockDate?.date ? moment(company.glLockDate.date) : null
  );

  useEffect(() => {
    if (company?.glLockDate?.date) {
      setLockDate(moment(company.glLockDate.date));
    }
  }, [company]);

  const onClose = () => dispatch(setFormOpen(false, "companyLockDate"));

  const submit = () => {
    if (!company) return;
    dispatch(
      createCompanyLockDate(
        { date: lockDate?.format("YYYY-MM-DD") },
        company.id
      )
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Set new lock date for <b> {company?.name}</b>
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CalendarPicker
            date={lockDate}
            onChange={(newDate) => setLockDate(newDate)}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="soft" color="neutral">
          Cancel
        </Button>
        <Button onClick={submit}>Set Date</Button>
      </DialogActions>
    </Dialog>
  );
}
