import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectItemClasses = (state: AppState) => state.itemClassState.itemClasses;
const selectSelectedItemClassID = (state: AppState) =>
  state.itemClassState.selectedItemClassID;

export const itemClassesSelector = createSelector(
  [selectItemClasses],
  (itemClasses) => mapToArray(itemClasses)
);

export const itemClassesMapSelector = createSelector(
  [selectItemClasses],
  (itemClasses) => itemClasses
);

export const selectedItemClassSelector = createSelector(
  [selectItemClasses, selectSelectedItemClassID],
  (itemClasses, selectedItemClassID) => {
    if (selectedItemClassID) return itemClasses[selectedItemClassID];
    return undefined;
  }
);

export const itemClassSelector = (id?: number) => {
  return createSelector([selectItemClasses], (itemClasses) => {
    if (id) return itemClasses[id];
    return undefined;
  });
};
