import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GlAccountsDataGrid } from "../hooks/glAccount/glAccountDataGrid";
import { getGlAccounts } from "../redux/glAccount/glAccountActions";

/**
 *
 * @returns {ReactElement} GL Accounts
 */
export function GlAccounts() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGlAccounts());
  }, []);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <GlAccountsDataGrid />
    </Paper>
  );
}
