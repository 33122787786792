import { User } from "@nerdjs/account-kit";
import { GlLockDate } from "../glLockDate";

/**
 * Company_Entity type guard.
 *
 * @param {any} companyJson Company object from API
 * @returns {boolean} Return true if type is Company_Entity
 */
function validator(companyJson: unknown): companyJson is Company_Entity {
  if (typeof companyJson === "object" && companyJson != null) {
    Company_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(companyJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Company_Entity {
  static requiredFields = [
    "id",
    "name",
    "glLockDate",
    "accountsPayable",
    "createdAt",
    "updatedAt",
  ];

  constructor(companyJson: unknown) {
    if (validator(companyJson)) {
      this.id = companyJson.id;
      this.name = companyJson.name;
      this.glLockDate = new GlLockDate(companyJson.glLockDate);
      this.accountsPayable = companyJson.accountsPayable;
      this.createdAt = companyJson.createdAt;
      this.updatedAt = companyJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Company_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  glLockDate: GlLockDate;
  accountsPayable: {
    id: number;
    companyID: number;
    companyName: string;
    name: string;
    type: string;
    description: string;
    accountNumber: string;
    createdAt: Date;
    updatedAt: Date;
    refNum: number;
    timestamp: number;
    hidden: boolean;
    delcount: number;
  };
  createdAt: string;
  updatedAt: string;
}
