import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { File_Entity } from "../../entities/file";
import { LineItem, LineItem_Entity } from "../../entities/lineItem";
import { Payable_Entity } from "../../entities/payable";

export const setSelectedLineItemID = createAction(
  "setSelectedLineItemID",
  function prepare(lineItemID?: number) {
    return {
      payload: {
        lineItemID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const newLineItemFile = createAction(
  "newLineItemFile",
  function prepare(file: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteStagedPayableLineItemFile = createAction(
  "deleteStagedPayableLineItemFile",
  function prepare(file: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

// DELETE Line Item file
export const deleteLineItemfile = createAction(
  "ap-dashboard/lineItems/deleteLineItemfile",
  function prepare(payableID: number, fileID: number) {
    const request = new DeleteAction(
      `/ap-dashboard/lineItems/${payableID}/files/${fileID}`
    );

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(deleteLineItemFilesSuccess());
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteLineItemFilesSuccess = createAction(
  "ap-dashboard/lineItems/deleteLineItemFilesSuccess",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

// UPLOAD Payable line item files
export const uploadPayableLineItemsFile = createAction(
  "ap-dashboard/payables/lineItem/uploadLineItemsFile",
  function prepare(lineItemID: number, file: File) {
    const data = new FormData();
    data.append("file", file);

    const request = new PostAction(
      `/ap-dashboard/lineItems/${lineItemID}/upload`,
      data
    );

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const payableLineitemFile = json as File;
      dispatch(uploadPayableLineItemFilesSuccess(payableLineitemFile));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const uploadPayableLineItemFilesSuccess = createAction(
  "ap-dashboard/payables/lineItem/uploadPayableLineItemFilesSuccess",
  function prepare(payableLineItemFileUpload: File) {
    return {
      payload: {
        payableLineItemFileUpload,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLineItems = createAction(
  "ap-dashboard/lineItem/getLineItems",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/lineItems`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const lineItems = LineItem.fromList(json);
      dispatch(getLineItemsSuccess(lineItems));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLineItemsSuccess = createAction(
  "ap-dashboard/lineItem/getLineItemSuccess",
  function prepare(lineItems: LineItem[]) {
    return {
      payload: {
        lineItems,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLineItemsByFilter = createAction(
  "ap-dashboard/lineItem/getLineItemsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/lineItems`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const lineItems = LineItem.fromList(json);
      dispatch(getLineItemsByFilterSuccess(lineItems));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLineItemsByFilterSuccess = createAction(
  "ap-dashboard/lineItem/getLineItemsByFilterSuccess",
  function prepare(lineItems: LineItem[]) {
    return {
      payload: {
        lineItems,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLineItemById = createAction(
  "ap-dashboard/lineItem/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/lineItems/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: LineItem_Entity) => {
      const lineItem = new LineItem(json);
      dispatch(getLineItemByIdSuccess(lineItem));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLineItemByIdSuccess = createAction(
  "ap-dashboard/lineItem/getLineItemByIdSuccess",
  function prepare(lineItem?: LineItem) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteLineItem = createAction(
  "ap-dashboard/removeLineItemById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/lineItems/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteLineItemSuccess = createAction(
  "ap-dashboard/lineItems/deleteLineItemSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateLineItem = createAction(
  "ap-dashboard/lineItems/updateLineItem",
  function prepare(id: number, body: Partial<LineItem>) {
    const request = new PutAction(`/ap-dashboard/lineItems/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateLineItemSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateLineItemSuccess = createAction(
  "ap-dashboard/lineItems/updateLineItemSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createLineItem = createAction(
  "ap-dashboard/lineItems/createLineItem",
  function prepare(
    payableID: number,
    body: Partial<LineItem>,
    callback: (v: LineItem_Entity) => void
  ) {
    const request = new PostAction(
      `/ap-dashboard/payables/${payableID}/lineItems`,
      body
    );

    request.decodeResponse = (dispatch: Dispatch, json: LineItem_Entity) => {
      const lineItem = new LineItem(json);
      callback(json);
      dispatch(createLineItemSuccess(lineItem));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createLineItemSuccess = createAction(
  "ap-dashboard/lineItems/createLineItemSuccess",
  function prepare(lineItem: LineItem) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getLineItemsSearchMetadata = createAction(
  "ap-dashboard/getLineItemsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/lineItems/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getLineItemsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getLineItemsSearchMetadataSuccess = createAction(
  "ap-dashboard/getLineItemsSearchMetadataSuccess",
  function prepare(lineItemsSearchMetadata: unknown) {
    return {
      payload: {
        lineItemsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
