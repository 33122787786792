import { createReducer } from "@reduxjs/toolkit";
import { PriorityLevel } from "../../entities/priorityLevel";
import { arrayToMap } from "../../helpers/reducerHelpers";

import {
  deletePriorityLevelSuccess,
  getPriorityLevelByIdSuccess,
  getPriorityLevelsSearchMetadataSuccess,
  getPriorityLevelsSuccess,
  setSelectedPriorityLevelID,
} from "./priorityLevelActions";
import { PriorityLevelState } from "./priorityLevelState";

function initState(): PriorityLevelState {
  return {
    priorityLevels: {},
    searchMetadata: undefined,
    selectedPriorityLevelID: undefined,
  };
}

export const priorityLevelReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedPriorityLevelID, (state, action) => {
    state.selectedPriorityLevelID = action.payload.priorityLevelID;
    return state;
  });
  builder.addCase(getPriorityLevelsSuccess, (state, action) => {
    const priorityLevels = action.payload.priorityLevels;
    state.priorityLevels = arrayToMap<PriorityLevel>(priorityLevels);
    return state;
  });
  builder.addCase(getPriorityLevelsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.priorityLevelsSearchMetadata;
    return state;
  });
  builder.addCase(deletePriorityLevelSuccess, (state, action) => {
    const priorityLevelID = action.payload.id;
    if (state.priorityLevels[priorityLevelID]) {
      delete state.priorityLevels[priorityLevelID];
    }
    return state;
  });
  builder.addCase(getPriorityLevelByIdSuccess, (state, action) => {
    const priorityLevel = action.payload.priorityLevel;
    if (priorityLevel) {
      const priorityLevels = { ...state.priorityLevels };
      priorityLevels[priorityLevel.id] = priorityLevel;
      state.priorityLevels = priorityLevels;
    }
    return state;
  });
});
