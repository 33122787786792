import VendorLineItem_Entity from "./vendorLineItem";
export default class VendorLineItem extends VendorLineItem_Entity {
  static fromList(vendorLineItemsJSON: unknown): Array<VendorLineItem> {
    const vendorLineItems: VendorLineItem[] = [];
    if (vendorLineItemsJSON)
      Array.isArray(vendorLineItemsJSON) &&
        vendorLineItemsJSON.forEach((vendorLineItemJSON) => {
          vendorLineItems.push(new VendorLineItem(vendorLineItemJSON));
        });
    return vendorLineItems;
  }

  body() {
    return {
      id: this.id,
      glAccountID: this.glAccountID,
      classID: this.classID,
      position: this.position,
    };
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
