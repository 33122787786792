import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CompaniesDataGrid } from "../hooks/company/companyDatagrid";
import { getCompanies } from "../redux/company/companyActions";

/**
 *
 * @returns {ReactElement} Companies page
 */
export function Companies() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <CompaniesDataGrid />
    </Paper>
  );
}
