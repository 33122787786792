import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { VendorsDataGrid } from "../hooks/vendor/vendorDataGrid";
import { getVendors } from "../redux/vendor/vendorActions";

/**
 *
 * @returns {ReactElement} Vendors Page
 */
export function Vendors() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendors());
  }, []);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <VendorsDataGrid />
    </Paper>
  );
}
