import { Typography } from "@mui/joy";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import hotkeys from "hotkeys-js";

type Content = {
  title?: string;
  options?: Options;
  actions?: string[];
};

type Options = {
  subtitle?: string | ReactNode;
  render?: (c: any) => ReactElement;
  icon?: ReactNode;
};

export const AskQuestionContext = createContext(
  (title: string, actions: string[], options?: Options | undefined) =>
    new Promise<number>((r) => r(1))
);

let resolve: (value: number | PromiseLike<number>) => void;

export default function AskQuestion({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<Content>({});

  hotkeys("enter", function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();
    setOpen(false);
    resolve(1);
  });

  const ask = (
    title: string,
    actions: string[],
    options?: Options | undefined
  ) => {
    setContent({
      title,
      actions,
      options,
    });
    setOpen(true);

    return new Promise<number>((r) => {
      resolve = r;
    });
  };

  return (
    <AskQuestionContext.Provider value={ask}>
      {children}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          resolve(0);
        }}
      >
        <DialogTitle>
          {content.options?.icon}
          {content.title}
        </DialogTitle>
        <DialogContent>
          <Typography>{content.options?.subtitle}</Typography>
          {content.options?.render ? (
            <Typography>
              {content.options.render(() => {
                setOpen(false);
                resolve(0);
              })}
            </Typography>
          ) : (
            []
          )}
        </DialogContent>
        {content.actions?.length ? (
          <DialogActions>
            {content.actions.map((a, i) => (
              <Button
                variant={
                  Number(i) === (content?.actions?.length ?? 0) - 1
                    ? "contained"
                    : "text"
                }
                onClick={() => {
                  setOpen(false);
                  resolve(i);
                }}
                key={a}
              >
                {a}
              </Button>
            ))}
          </DialogActions>
        ) : (
          []
        )}
      </Dialog>
    </AskQuestionContext.Provider>
  );
}

export const useAskQuestion = () => {
  const context = useContext(AskQuestionContext);
  return context;
};
