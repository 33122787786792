import { Filters } from "@nerdjs/nerd-core";
import { NerdAdvancedSearch } from "@nerdjs/nerd-ui";
import { AdvancedSearchDefinition } from "@nerdjs/nerd-ui/dist/nerdAdvancedSearch/types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import { useQuery } from "../../nerdPaginationFooter/utils";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  getJournalEntries,
  getJournalEntriesByFilter,
  getJournalEntriesSearchMetadata,
  setJournalEntryFilters,
  setJournalEntrySearchOpen,
} from "../../redux/journalEntry/journalEntryActions";
import {
  journalEntryFiltersSelector,
  journalEntrySearchMetadataSelector,
  journalEntrySearchOpenSelector,
} from "../../redux/journalEntry/journalEntrySelector";
import { CompanyAutocomplete } from "../company/companyAutocomplete";
import { UserAutocomplete } from "../user/userAutocomplete";
/**
 *
 * @returns {ReactElement} JournalEntrySearch page
 */
export function JournalEntrySearch() {
  const dispatch = useDispatch();
  const searchOpen = useAppSelector(journalEntrySearchOpenSelector);
  const query = useQuery();
  const filtersString = query.get("filters");
  const filters: Filters = filtersString ? JSON.parse(filtersString) : [];
  const filtersInRedux = useAppSelector(journalEntryFiltersSelector);
  const searchMetadata = useAppSelector(journalEntrySearchMetadataSelector);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getJournalEntriesSearchMetadata());

    //We restore the filters from redux if they exists
    if (filtersInRedux && filters.length === 0) {
      query.set("filters", JSON.stringify(filtersInRedux));
      navigate({ search: query.toString() });
    }
  }, []);

  useEffect(() => {
    // we save the filters in redux whenever they change
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      dispatch(setJournalEntryFilters(filters));
    } else {
      dispatch(setJournalEntryFilters());
    }
  }, [filtersString]);

  const definition: AdvancedSearchDefinition[] = [
    {
      id: "referenceNumber",
      name: "journalEntries.referenceNumber",
      label: "Reference Number",
      type: "string",
      faIcon: "fa-solid fa-tag",
    },
    {
      id: "companyID",
      name: "journalEntries.companyID",
      label: "Company",
      type: "string",
      faIcon: "fa-solid fa-building",
      renderInput: (value, onChange) => (
        <CompanyAutocomplete
          companyID={value as unknown as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "createdByUUID",
      name: "journalEntries.createdByUUID",
      label: "Created By",
      type: "string",
      faIcon: "fa-solid fa-user",
      renderInput: (value, onChange) => (
        <UserAutocomplete
          userUUID={value as unknown as string}
          onChange={(e) => onChange(e?.uuid)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "locked",
      name: "journalEntries.locked",
      label: "Locked",
      type: "bool",
      faIcon: "fa-solid fa-lock",
      getFaIcon: (v) => (v ? "fa-solid fa-lock" : "fa-solid fa-lock-open"),
    },
    {
      id: "exportID",
      name: "journalEntries.exportID",
      label: "Export #",
      type: "int",
      faIcon: "fa-solid fa-file-export",
      getFaIcon: (v) => (v ? "fa-solid fa-lock" : "fa-solid fa-lock-open"),
    },
    {
      id: "date",
      name: "journalEntries.date",
      label: "Date",
      type: "date",
      faIcon: "fa-solid fa-calendar-days",
    },
    {
      id: "lockedDate",
      name: "journalEntries.lockedDate",
      label: "Locked Date",
      type: "date",
      faIcon: "fa-solid fa-calendar-days",
    },
  ];

  return (
    <NerdAdvancedSearch
      definitions={definition}
      searchMetadata={searchMetadata}
      open={searchOpen}
      onNewSearchPreset={() => dispatch(setFormOpen(true, "searchPreset"))}
      setOpen={(o) => dispatch(setJournalEntrySearchOpen(o))}
      onSearch={(q) =>
        q
          ? dispatch(getJournalEntriesByFilter(q))
          : dispatch(getJournalEntries())
      }
    />
  );
}
