import { User } from "@nerdjs/account-kit";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";

export const setSelectedUserID = createAction(
  "setSelectedUserID",
  function prepare(userID?: number) {
    return {
      payload: {
        userID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getUsers = createAction(
  "ap-dashboard/user/getUsers",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/users`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const users = User.fromList(json);
      dispatch(getUsersSuccess(users));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getUsersSuccess = createAction(
  "ap-dashboard/user/getUserSuccess",
  function prepare(users: User[]) {
    return {
      payload: {
        users,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getUsersByFilter = createAction(
  "ap-dashboard/user/getUsersByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/users`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const users = User.fromList(json);
      dispatch(getUsersSuccess(users));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getUsersByFilterSuccess = createAction(
  "ap-dashboard/user/getUsersByFilterSuccess",
  function prepare(users: User[]) {
    return {
      payload: {
        users,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getUserById = createAction(
  "ap-dashboard/user/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/users/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const user = new User(json);
      dispatch(getUserByIdSuccess(user));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getUserByIdSuccess = createAction(
  "ap-dashboard/user/getUserByIdSuccess",
  function prepare(user?: User) {
    return {
      payload: {
        user,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteUser = createAction(
  "ap-dashboard/removeUserById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/users/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteUserSuccess = createAction(
  "ap-dashboard/users/deleteUserSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateUser = createAction(
  "ap-dashboard/users/updateUser",
  function prepare(id: number, body: Partial<User>) {
    const request = new PutAction(`/ap-dashboard/users/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateUserSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateUserSuccess = createAction(
  "ap-dashboard/users/updateUserSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createUser = createAction(
  "ap-dashboard/users/createUser",
  function prepare(body: Partial<User>) {
    const request = new PostAction(`/ap-dashboard/users`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const user = new User(json);
      dispatch(createUserSuccess(user));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createUserSuccess = createAction(
  "ap-dashboard/users/createUserSuccess",
  function prepare(user: User) {
    return {
      payload: {
        user,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getUsersSearchMetadata = createAction(
  "ap-dashboard/getUsersSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/users/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getUsersSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getUsersSearchMetadataSuccess = createAction(
  "ap-dashboard/getUsersSearchMetadataSuccess",
  function prepare(usersSearchMetadata: unknown) {
    return {
      payload: {
        usersSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
