import { createReducer } from "@reduxjs/toolkit";
import { LineItem } from "../../entities/lineItem";
import { arrayToMap } from "../../helpers/reducerHelpers";

import {
  deleteLineItemSuccess,
  getLineItemByIdSuccess,
  getLineItemsSearchMetadataSuccess,
  getLineItemsSuccess,
  setSelectedLineItemID,
} from "./lineItemActions";
import { LineItemState } from "./lineItemState";

function initState(): LineItemState {
  return {
    lineItems: {},
    searchMetadata: undefined,
  };
}

export const lineItemReducer = createReducer(initState(), (builder) => {
  builder.addCase(getLineItemsSuccess, (state, action) => {
    const lineItems = action.payload.lineItems;
    state.lineItems = arrayToMap<LineItem>(lineItems);
    return state;
  });
  builder.addCase(getLineItemsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.lineItemsSearchMetadata;
    return state;
  });
  builder.addCase(deleteLineItemSuccess, (state, action) => {
    const lineItemID = action.payload.id;
    if (state.lineItems[lineItemID]) {
      delete state.lineItems[lineItemID];
    }
    return state;
  });
  builder.addCase(getLineItemByIdSuccess, (state, action) => {
    const lineItem = action.payload.lineItem;
    if (lineItem) {
      const lineItems = { ...state.lineItems };
      lineItems[lineItem.id] = lineItem;
      state.lineItems = lineItems;
    }
    return state;
  });
});
