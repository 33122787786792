import { IconButton, Option, Select, Typography } from "@mui/joy";
import { Box, Divider, Hidden, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "./utils";
import { NerdPaginationFooterProps } from "./types";
import { useGridApiContext } from "@mui/x-data-grid-pro";

const itemsPerPage = [25, 50, 75, 100];

/**
 *
 * @returns {ReactElement} PaginationFooter page
 */
export function NerdPaginationFooter({
  onSearch,
  count,
}: NerdPaginationFooterProps) {
  const query = useQuery();
  const offsetString = query.get("offset");
  const limitString = query.get("limit");
  const offset = offsetString ? parseInt(offsetString) : 0;
  const limit = limitString ? parseInt(limitString) : 25;
  const navigate = useNavigate();
  const apiRef = useGridApiContext();

  const currentPage = offset / limit;

  const onOffsetChange = (o?: number) => {
    if (o != undefined) {
      query.set("offset", `${o}`);
      navigate({ search: query.toString() });
      if (onSearch)
        onSearch({
          filters: query.get("filters"),
          offset: query.get("offset") ?? 0,
          limit: query.get("limit") ?? 25,
        });
    }
  };

  const onLimitChange = (l: number | null) => {
    if (l != undefined) {
      query.set("limit", `${l}`);
      navigate({ search: query.toString() });
      if (onSearch)
        onSearch({
          filters: query.get("filters"),
          offset: query.get("offset") ?? 0,
          limit: query.get("limit") ?? 25,
        });
    }
  };

  const nextPage = () => {
    onOffsetChange((currentPage + 1) * limit);
    apiRef.current?.scrollToIndexes({ rowIndex: 0 });
  };

  const previousPage = () => {
    onOffsetChange((currentPage - 1) * limit);
    apiRef.current?.scrollToIndexes({ rowIndex: 0 });
  };

  const goToPage = (i: number) => {
    onOffsetChange(i * limit);
    apiRef.current?.scrollToIndexes({ rowIndex: 0 });
  };

  return (
    <Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Hidden smDown>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography level="body2" sx={{ fontWeight: 500 }}>
              Items per page
            </Typography>
            <Select
              placeholder="Items per page"
              value={limit}
              size="sm"
              onChange={(_e, v) => onLimitChange(v)}
            >
              {itemsPerPage.map((i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Box>
        </Hidden>
        <Typography level="body2">
          {apiRef.current?.getRowsCount()} Items
        </Typography>
        <Box
          className="noScrollBar"
          sx={{ display: "flex", gap: 1, maxWidth: 400, overflow: "scroll" }}
        >
          <Tooltip title="Previous Page">
            <IconButton
              sx={{ position: "sticky", left: 0, zIndex: 99 }}
              disabled={offset === 0}
              color={offset === 0 ? "neutral" : "primary"}
              size="sm"
              onClick={previousPage}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </IconButton>
          </Tooltip>
          {[...Array(offset / limit + 1).keys()].map((i) => (
            <IconButton
              size="sm"
              key={i}
              onClick={() => goToPage(i)}
              color={currentPage === i ? "primary" : "neutral"}
              variant={currentPage === i ? "solid" : "soft"}
            >
              {i + 1}
            </IconButton>
          ))}
          <Tooltip title="Next Page">
            <IconButton
              disabled={count !== limit}
              color={count !== limit ? "neutral" : "primary"}
              size="sm"
              onClick={nextPage}
              sx={{ position: "sticky", right: 0, zIndex: 99 }}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
