import { Button } from "@mui/joy";
import { Box, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { Vendor } from "../../entities/vendor";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { setSelectedVendorID } from "../../redux/vendor/vendorActions";
import { vendorsSelector } from "../../redux/vendor/vendorSelector";

/**
 *
 * @returns {ReactElement} VendorsDataGrid component
 */
export function VendorsDataGrid() {
  const vendors = useAppSelector(vendorsSelector);
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const columns: GridColumns<Vendor> = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "status",
      headerName: "",
      width: 20,
      renderCell: (p) => {
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
            {p.row.files?.length ? (
              <Tooltip title={`${p.row.files.length} file(s)`}>
                <i className="fa-regular fa-paperclip"></i>
              </Tooltip>
            ) : (
              []
            )}
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 150,
    },
    {
      field: "defaultAssignedTo",
      headerName: "Default Assigned To",
      valueGetter: (p) => p.row.defaultAssignedTo?.fullname(),
      width: 170,
    },
    {
      field: "defaultDualAssignedTo",
      headerName: "Default Dual Assigned To",
      valueGetter: (p) => p.row.defaultDualAssignedTo?.fullname(),
      width: 170,
    },
    {
      field: "defaultLineItems",
      headerName: "Default Line Items",
      valueGetter: (p) =>
        p.value.length
          ? `${p.value.length} line${p.value.length > 1 ? "s" : ""}`
          : "",
      width: 140,
    },
    {
      field: "taxID",
      headerName: "Tax ID",
      width: 150,
    },
    {
      field: "tenNintyNine",
      headerName: "1099",
      type: "boolean",
      width: 40,
    },
    {
      field: "terms",
      headerName: "Terms",
      valueGetter: (p) => `${p.value.Name}`,
    },
    {
      field: "contact",
      headerName: "Contact",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },
  ];

  return (
    <DataGridPro
      rows={vendors}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
      apiRef={apiRef}
      density="compact"
      getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
      components={{
        Toolbar: CustomToolbar,
        Footer: CustomFooter,
      }}
      onRowClick={(p) => {
        dispatch(setSelectedVendorID(p.row.id));
        dispatch(setFormOpen(true, "vendor"));
      }}
      componentsProps={{ toolbar: {}, footer: {} }}
      initialState={{
        pinnedColumns: {
          left: ["id"],
        },
      }}
      disableSelectionOnClick
    />
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewVendor = () => {
    console.log("Do someting!", dispatch);
  };

  useHotkeys(`ctrl+enter`, handleNewVendor);

  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        gap: 1,
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <GridToolbarFilterButton />
      <Box>
        <Button
          endDecorator={<i className="fa-duotone fa-up-from-bracket"></i>}
          size="sm"
          onClick={() => dispatch(setFormOpen(true, "importVendors"))}
          variant="plain"
        >
          Import Vendors
        </Button>
      </Box>
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  return <Box className="footer" sx={{ display: "flex" }}></Box>;
}
