import { Button, IconButton } from "@mui/joy";
import { Dialog, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { createApExport } from "../../redux/apExport/apExportActions";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import {
  getJournalEntriesReadyToExport,
  getJournalEntriesReadyToExportSuccess,
} from "../../redux/journalEntry/journalEntryActions";
import { journalEntriesReadyToExportSelector } from "../../redux/journalEntry/journalEntrySelector";
import { CompanyAutocomplete } from "../company/companyAutocomplete";
import { JournalEntriesDataGrid } from "./journalEntryDataGrid";

/**
 *
 * @returns {ReactElement} JournalEntryExport page
 */
export function JournalEntryExport() {
  const dispatch = useDispatch();
  const open = useAppSelector(formOpenSelector("journalEntryExport"));

  const [companyID, setCompanyID] = useState<number | undefined>();
  const [selectedJournalEntries, setSelectedJournalEntries] = useState<
    number[]
  >([]);
  const journalEntriesReadyToExport = useAppSelector(
    journalEntriesReadyToExportSelector
  );

  useEffect(() => {
    return () => {
      dispatch(getJournalEntriesReadyToExportSuccess());
    };
  }, []);

  const close = () => {
    dispatch(setFormOpen(false, "journalEntryExport"));
  };

  const getExportableJournalEntries = () => {
    dispatch(getJournalEntriesReadyToExport(companyID!));
  };

  const exportNow = () => {
    if (selectedJournalEntries.length) {
      const body = {
        companyID,
        transactionType: 4,
        journalIDs: selectedJournalEntries,
      };
      dispatch(createApExport(body));
      dispatch(setFormOpen(false, "journalEntryExport"));
    }
  };

  const beforeYouExport = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <i style={{ fontSize: 32 }} className="fa-regular fa-file-export"></i>
      <Box>
        <Typography variant="h4">
          Before you export Journal Entries...
        </Typography>
        <Typography>
          Please select the company you wish to export journal entries from:
        </Typography>
      </Box>
      <CompanyAutocomplete
        companyID={companyID}
        size="small"
        onChange={(e) => (e ? setCompanyID(e.id) : setCompanyID(undefined))}
      />
      <Button disabled={!companyID} onClick={getExportableJournalEntries}>
        Go to Selection
      </Button>
    </Box>
  );

  const selection = (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        p: 2,
        flex: 1,
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h4">
        Select the journal entries to export:
      </Typography>
      <JournalEntriesDataGrid
        select
        journalEntries={journalEntriesReadyToExport}
        noToolbar
        noFooter
        onSelect={(p) => setSelectedJournalEntries(p as number[])}
      />
      <Button
        onClick={exportNow}
        disabled={selectedJournalEntries.length === 0}
      >
        Export Now
      </Button>
    </Box>
  );

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          position: "relative",
          justifyContent: "center",
          alignItems: "stretch",
          flexDirection: "column",
        }}
      >
        <Tooltip title="Cancel Export">
          <IconButton
            color="neutral"
            size="lg"
            onClick={close}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Tooltip>
        {journalEntriesReadyToExport === undefined
          ? beforeYouExport
          : selection}
      </Box>
    </Dialog>
  );
}
