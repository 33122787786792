import { Filters, SearchMetadata } from "@nerdjs/nerd-core";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Company, Company_Entity } from "../../entities/company";
import { GlLockDate } from "../../entities/glLockDate";

export const setStagedCompany = createAction(
  "setStagedCompany",
  function prepare(stagedCompany?: Company_Entity) {
    return {
      payload: {
        stagedCompany,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setCompanyLoading = createAction(
  "setCompanyLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setCompanySearchOpen = createAction(
  "setCompanySearchOpen",
  function prepare(searchOpen: boolean) {
    return {
      payload: {
        searchOpen,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setCompanyFilters = createAction(
  "setCompanyFilters",
  function prepare(filters?: Filters) {
    return {
      payload: {
        filters,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedCompany = createAction(
  "updateStagedCompany",
  function prepare(stagedCompany?: Partial<Company_Entity>) {
    return {
      payload: {
        stagedCompany,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedCompanyID = createAction(
  "setSelectedCompanyID",
  function prepare(companyID?: number) {
    return {
      payload: {
        companyID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCompanies = createAction(
  "ap-dashboard/company/getCompanies",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/companies`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const companies = Company.fromList(json);
      dispatch(getCompaniesSuccess(companies));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCompaniesSuccess = createAction(
  "ap-dashboard/company/getCompanySuccess",
  function prepare(companies: Company[]) {
    return {
      payload: {
        companies,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCompaniesByFilter = createAction(
  "ap-dashboard/company/getCompaniesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/companies`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const companies = Company.fromList(json);
      dispatch(getCompaniesByFilterSuccess(companies));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCompaniesByFilterSuccess = createAction(
  "ap-dashboard/company/getCompaniesByFilterSuccess",
  function prepare(companies: Company[]) {
    return {
      payload: {
        companies,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCompanyById = createAction(
  "ap-dashboard/company/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/companies/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const company = new Company(json);
      dispatch(getCompanyByIdSuccess(company));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCompanyByIdSuccess = createAction(
  "ap-dashboard/company/getCompanyByIdSuccess",
  function prepare(company?: Company) {
    return {
      payload: {
        company,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteCompany = createAction(
  "ap-dashboard/removeCompanyById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/companies/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteCompanySuccess = createAction(
  "ap-dashboard/companies/deleteCompanySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateCompany = createAction(
  "ap-dashboard/companies/updateCompany",
  function prepare(id: number, body: Partial<Company>) {
    const request = new PutAction(`/ap-dashboard/companies/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateCompanySuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateCompanySuccess = createAction(
  "ap-dashboard/companies/updateCompanySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createCompany = createAction(
  "ap-dashboard/companies/createCompany",
  function prepare(body: Partial<Company>) {
    const request = new PostAction(`/ap-dashboard/companies`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const company = new Company(json);
      dispatch(createCompanySuccess(company));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createCompanySuccess = createAction(
  "ap-dashboard/companies/createCompanySuccess",
  function prepare(company: Company) {
    return {
      payload: {
        company,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createCompanyLockDate = createAction(
  "ap-dashboard/companies/glLockDates",
  function prepare(body: Partial<GlLockDate>, id: number) {
    const request = new PostAction(
      `/ap-dashboard/companies/${id}/glLockDates`,
      body
    );

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      console.log(json);
      dispatch(createCompanyLockDateSuccess());
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createCompanyLockDateSuccess = createAction(
  "ap-dashboard/companies/createCompanyLockDateSuccess",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getCompaniesSearchMetadata = createAction(
  "ap-dashboard/getCompaniesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/companies/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: SearchMetadata) => {
      dispatch(getCompaniesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getCompaniesSearchMetadataSuccess = createAction(
  "ap-dashboard/getCompaniesSearchMetadataSuccess",
  function prepare(companiesSearchMetadata: SearchMetadata) {
    return {
      payload: {
        companiesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
