import { useMemo } from "react";
import { ApExport } from "../../entities/apExport";
import { useGetApExportQuery } from "./apExportEndpoints";

export function useApExport(apExportID: number | undefined) {
  const apExportQuery = useGetApExportQuery(apExportID ?? 0, {
    skip: (apExportID ?? 0) <= 0,
  });
  const apExportEntity = apExportQuery.data;

  return useMemo(() => {
    const ret: typeof apExportQuery & { apExport?: ApExport | undefined } = {
      ...apExportQuery,
    };
    if (apExportEntity) ret.apExport = new ApExport(apExportEntity);
    return ret;
  }, [apExportEntity]);
}
