export function newIndexAfterReorder<T extends { position?: number }>(
  li: Array<T>,
  i: number,
  up: boolean
): number {
  // dragged on top
  if (i === 0) {
    return Math.round(li[0].position! / 2);
  }

  // dragged at the bottom
  if (i === li.length - 1) {
    return (li.length + 1) * 10000;
  }

  if (up) {
    return Math.round((li[i].position! + li[i + 1].position!) / 2);
  }

  return Math.round((li[i - 1].position! + li[i].position!) / 2);
}
