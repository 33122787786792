import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ExportsDataGrid } from "../hooks/export/exportDataGrid";
import { getApExports } from "../redux/apExport/apExportActions";

/**
 *
 * @returns {ReactElement} Exports page
 */
export function Exports() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApExports());
  }, []);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <ExportsDataGrid />
    </Paper>
  );
}
