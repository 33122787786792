import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { ItemClass } from "../../entities/itemClass";

export const setSelectedItemClassID = createAction(
  "setSelectedItemClassID",
  function prepare(itemClassID?: number) {
    return {
      payload: {
        itemClassID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getItemClasses = createAction(
  "ap-dashboard/itemClass/getItemClasses",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/itemClasses`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const itemClasses = ItemClass.fromList(json);
      dispatch(getItemClassesSuccess(itemClasses));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getItemClassesSuccess = createAction(
  "ap-dashboard/itemClass/getItemClassSuccess",
  function prepare(itemClasses: ItemClass[]) {
    return {
      payload: {
        itemClasses,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getItemClassesByFilter = createAction(
  "ap-dashboard/itemClass/getItemClassesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/itemClasses`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const itemClasses = ItemClass.fromList(json);
      dispatch(getItemClassesByFilterSuccess(itemClasses));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getItemClassesByFilterSuccess = createAction(
  "ap-dashboard/itemClass/getItemClassesByFilterSuccess",
  function prepare(itemClasses: ItemClass[]) {
    return {
      payload: {
        itemClasses,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getItemClassById = createAction(
  "ap-dashboard/itemClass/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/itemClasses/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const itemClass = new ItemClass(json);
      dispatch(getItemClassByIdSuccess(itemClass));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getItemClassByIdSuccess = createAction(
  "ap-dashboard/itemClass/getItemClassByIdSuccess",
  function prepare(itemClass?: ItemClass) {
    return {
      payload: {
        itemClass,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteItemClass = createAction(
  "ap-dashboard/removeItemClassById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/itemClasses/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteItemClassSuccess = createAction(
  "ap-dashboard/itemClasses/deleteItemClassSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateItemClass = createAction(
  "ap-dashboard/itemClasses/updateItemClass",
  function prepare(id: number, body: Partial<ItemClass>) {
    const request = new PutAction(`/ap-dashboard/itemClasses/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateItemClassSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateItemClassSuccess = createAction(
  "ap-dashboard/itemClasses/updateItemClassSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createItemClass = createAction(
  "ap-dashboard/itemClasses/createItemClass",
  function prepare(body: Partial<ItemClass>) {
    const request = new PostAction(`/ap-dashboard/itemClasses`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const itemClass = new ItemClass(json);
      dispatch(createItemClassSuccess(itemClass));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createItemClassSuccess = createAction(
  "ap-dashboard/itemClasses/createItemClassSuccess",
  function prepare(itemClass: ItemClass) {
    return {
      payload: {
        itemClass,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getItemClassesSearchMetadata = createAction(
  "ap-dashboard/getItemClassesSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/itemClasses/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getItemClassesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getItemClassesSearchMetadataSuccess = createAction(
  "ap-dashboard/getItemClassesSearchMetadataSuccess",
  function prepare(itemClassesSearchMetadata: unknown) {
    return {
      payload: {
        itemClassesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
