import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LineItem_Entity } from "../../entities/lineItem";
import { newStagedPayableLineItem } from "../../redux/payable/payableActions";

import { useMyAccess, user } from "../myAccess/myAccess";

/**
 *
 * @returns {ReactElement} BatchLineItems page
 */
export function BatchLineItems({ disabled }: { disabled: boolean }) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const myAccess = useMyAccess();

  const dispatch = useDispatch();

  const addLineItems = () => {
    const rows = text.split("\n");
    const re = /\s+/g;

    let id = new Date().getTime();

    for (const i in rows) {
      const cells = rows[i].split("\t");
      const body: LineItem_Entity = {
        id,
        amount: Number(cells[0].replace(",", "")),
        memo: cells.splice(1).join(" ").trim().replaceAll(re, " "),
        toCreate: true,
      };
      id++;
      dispatch(newStagedPayableLineItem(body));
      setOpen(false);
      setText("");
    }
  };

  return (
    <>
      <Button
        disabled={disabled || myAccess === user}
        size="small"
        onClick={(e) => setOpen(true)}
      >
        Batch
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Copy/Paste from Sheets</DialogTitle>
        <DialogContent>
          <img
            style={{ width: "100%" }}
            src={
              "https://storage.googleapis.com/archer-public/assets/columns.png"
            }
          />
          <Typography>
            Pasted columns should have the amount as the first column and all
            other columns will be put in the memo
          </Typography>
          <TextField
            placeholder="Paste here..."
            fullWidth
            multiline
            onChange={(e) => setText(e.target.value)}
            maxRows={10}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={addLineItems}>Add Line Items</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
