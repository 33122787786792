import { Button, IconButton, Sheet } from "@mui/joy";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "../../atoms/config/routerConfig";
import { useAppSelector } from "../../atoms/hooks";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  deletePayable,
  deletePayableSuccess,
  setSelectedPayableID,
  setStagedPayable,
  stagedPayableCreateRequest,
  stagedPayableUpdateRequest,
} from "../../redux/payable/payableActions";
import { stagedPayableSelector } from "../../redux/payable/payableSelector";
import { useAskQuestion } from "../askQuestion/askQuestion";

/**
 *
 * @returns {ReactElement} PayableToolbar page
 */
export function PayableToolbar() {
  const stagedPayable = useAppSelector(stagedPayableSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const askQuestion = useAskQuestion();

  const close = () => {
    dispatch(setSelectedPayableID());
    dispatch(setStagedPayable());
    dispatch(setFormOpen(false, "payable"));
    navigate({
      pathname:
        window.location.pathname.toLowerCase().indexOf("mypayables") !== -1
          ? RouterConfig.myPayables
          : RouterConfig.payables,
      search: window.location.search,
    });
  };

  const onDelete = () => {
    if (stagedPayable) {
      askQuestion(
        `You are about to delete the payable (#${
          stagedPayable?.referenceNumber ?? ""
        })`,
        ["Cancel", "Yes"],
        {
          subtitle: <Typography>Please confirm</Typography>,
        }
      ).then((i) => {
        if (i == 1) {
          dispatch(deletePayable(stagedPayable.id, deletePayableSuccess));
          close();
        }
      });
    }
  };

  const onCreate = () => {
    dispatch(stagedPayableCreateRequest(navigate, true));
  };

  const onUpdate = () => {
    dispatch(stagedPayableUpdateRequest(navigate, true));
  };

  const onClose = () => {
    if (!stagedPayable?.canClose()) {
      askQuestion(
        `You are about to close the window without saving. All your changes will be lost if you proceed.`,
        ["Cancel", "Yes"],
        {
          subtitle: <Typography>Please confirm</Typography>,
        }
      ).then((i) => {
        if (i == 1) close();
      });
    } else {
      close();
    }
  };

  if (!stagedPayable) return <div />;
  return (
    <Sheet
      sx={{
        p: 1,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
      }}
      variant="soft"
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={onClose}
            size="sm"
            color="neutral"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          {stagedPayable.id ? (
            <Button size="sm" variant="outlined" onClick={onUpdate}>
              Save & Close
            </Button>
          ) : (
            []
          )}

          {stagedPayable.toCreate ? (
            <Button size="sm" variant="outlined" onClick={onCreate}>
              Create
            </Button>
          ) : (
            []
          )}
        </Box>
      </Box>
    </Sheet>
  );
}
