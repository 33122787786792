import { createReducer } from "@reduxjs/toolkit";
import { SearchPreset } from "../../entities/searchPreset";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteSearchPresetSuccess,
  getSearchPresetByIdSuccess,
  getSearchPresetsSearchMetadataSuccess,
  getSearchPresetsSuccess,
  setSelectedSearchPresetID,
} from "./searchPresetActions";
import { SearchPresetState } from "./searchPresetState";

function initState(): SearchPresetState {
  return {
    searchPresets: {},
    searchMetadata: undefined,
    selectedSearchPresetID: undefined,
  };
}

export const searchPresetReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedSearchPresetID, (state, action) => {
    state.selectedSearchPresetID = action.payload.searchPresetID;
    return state;
  });
  builder.addCase(getSearchPresetsSuccess, (state, action) => {
    const searchPresets = action.payload.searchPresets;
    state.searchPresets = arrayToMap<SearchPreset>(searchPresets);
    return state;
  });
  builder.addCase(getSearchPresetsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.searchPresetsSearchMetadata;
    return state;
  });
  builder.addCase(deleteSearchPresetSuccess, (state, action) => {
    const searchPresetID = action.payload.id;
    if (state.searchPresets[searchPresetID]) {
      delete state.searchPresets[searchPresetID];
    }
    return state;
  });
  builder.addCase(getSearchPresetByIdSuccess, (state, action) => {
    const searchPreset = action.payload.searchPreset;
    if (searchPreset) {
      const searchPresets = { ...state.searchPresets };
      searchPresets[searchPreset.id] = searchPreset;
      state.searchPresets = searchPresets;
    }
    return state;
  });
});
