import { Autocomplete, Icon, InputBase, TextField } from "@mui/material";
import { User } from "@nerdjs/account-kit";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { getUsersByFilter } from "../../redux/user/userActions";
import { usersSelector, userUUIDSelector } from "../../redux/user/userSelector";

/**
 * @param {userAutocompleteProps} userAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} User Autocomplete
 */
export function UserAutocomplete({
  userUUID,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  autoFocus,
}: userAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const users = useAppSelector(usersSelector);
  const user = useAppSelector(userUUIDSelector(userUUID));
  const dispatch = useDispatch();

  useEffect(() => {
    if (users.length === 0) {
      dispatch(
        getUsersByFilter({
          filters: JSON.stringify([
            {
              name: "roles.serviceNameID",
              value: 17,
              comparison: "eq",
            },
          ]),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (user) {
      setInputValue(user?.fullname() ?? "");
    }
  }, [user]);

  return (
    <Autocomplete
      value={user ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      getOptionLabel={(option) => `${option.fullname()}`}
      options={users}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a user..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a user..."
            autoFocus={autoFocus}
            label="User"
            helperText={helperText}
          />
        )
      }
    />
  );
}

type userAutocompleteProps = {
  userUUID?: string;
  onChange: (user: User | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};
