import { createReducer } from "@reduxjs/toolkit";
import { setFormOpen, setTabIndex, showConfetti } from "./appStatusActions";
import { AppStatusState } from "./appStatusState";
/**
 * @returns AppStatusState
 */
function initState(): AppStatusState {
  return {
    tabIndexes: {},
    formOpen: {
      budget: localStorage.getItem("budget") === "true",
    },
    confetti: 0,
  };
}

export const appStatusReducer = createReducer(initState(), (builder) => {
  builder.addCase(setTabIndex, (state, action) => {
    const tabID = action.payload.tabID;
    const tabIndex = action.payload.tabIndex;
    state.tabIndexes = { ...state.tabIndexes, [tabID]: tabIndex };
    return state;
  });
  builder.addCase(showConfetti, (state) => {
    state.confetti += 1;
    return state;
  });
  builder.addCase(setFormOpen, (state, action) => {
    state.formOpen[action.payload.formID] = action.payload.open;
    localStorage.setItem(action.payload.formID, action.payload.open.toString());
    return state;
  });
});
