import GlLockDate_Entity from "./glLockDate";
export default class GlLockDate extends GlLockDate_Entity {
  static fromList(glLockDatesJSON: unknown): Array<GlLockDate> {
    const glLockDates: GlLockDate[] = [];
    if (glLockDatesJSON)
      Array.isArray(glLockDatesJSON) &&
        glLockDatesJSON.forEach((glLockDateJSON) => {
          glLockDates.push(new GlLockDate(glLockDateJSON));
        });
    return glLockDates;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
