import { createSelector } from "@reduxjs/toolkit";
import { Vendor } from "../../entities/vendor";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectVendors = (state: AppState) => state.vendorState.vendors;
const selectVendorSearchMetadata = (state: AppState) =>
  state.vendorState.searchMetadata;
const selectVendorFilters = (state: AppState) => state.vendorState.filters;
const selectVendorSearchOpen = (state: AppState) =>
  state.vendorState.searchOpen;
const selectSelectedVendorID = (state: AppState) =>
  state.vendorState.selectedVendorID;
const selectStageVendorSelector = (s: AppState) => s.vendorState.stagedVendor;
const selectVendorLoading = (s: AppState) => s.vendorState.loading;

const selectStagedVendorLoading = (s: AppState) =>
  s.vendorState.stagedVendorLoading;

export const stagedVendorLoadingSelector = createSelector(
  [selectStagedVendorLoading],
  (s) => s
);

export const vendorLoadingSelector = createSelector(
  [selectVendorLoading],
  (s) => s
);

export const vendorsSelector = createSelector([selectVendors], (vendors) =>
  Vendor.fromList(mapToArray(vendors)).filter((s) => !s.hidden)
);

export const vendorsMapSelector = createSelector([selectVendors], (v) => v);

export const selectedVendorSelector = createSelector(
  [selectVendors, selectSelectedVendorID],
  (vendors, selectedVendorID) => {
    if (selectedVendorID) return vendors[selectedVendorID];
    return undefined;
  }
);

export const stagedVendorSelector = createSelector(
  [selectStageVendorSelector],
  (s) => (s ? new Vendor(s) : s)
);

export const selectedVendorIDSelector = createSelector(
  [selectSelectedVendorID],
  (s) => s
);

export const vendorSearchOpenSelector = createSelector(
  [selectVendorSearchOpen],
  (s) => s
);

export const vendorSearchMetadataSelector = createSelector(
  [selectVendorSearchMetadata],
  (s) => s
);

export const vendorFiltersSelector = createSelector(
  [selectVendorFilters],
  (s) => s
);

export const vendorSelector = (id?: number) => {
  return createSelector([selectVendors], (vendors) => {
    if (id) return vendors[id];
    return undefined;
  });
};
