import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectGlAccounts = (state: AppState) => state.glAccountState.glAccounts;
const selectSelectedGlAccountID = (state: AppState) =>
  state.glAccountState.selectedGlAccountID;

export const glAccountsSelector = createSelector(
  [selectGlAccounts],
  (glAccounts) => mapToArray(glAccounts)
);

export const glAccountsMapSelector = createSelector(
  [selectGlAccounts],
  (glAccounts) => glAccounts
);

export const selectedGlAccountSelector = createSelector(
  [selectGlAccounts, selectSelectedGlAccountID],
  (glAccounts, selectedGlAccountID) => {
    if (selectedGlAccountID) return glAccounts[selectedGlAccountID];
    return undefined;
  }
);

export const glAccountSelector = (id?: number) => {
  return createSelector([selectGlAccounts], (glAccounts) => {
    if (id) return glAccounts[id];
    return undefined;
  });
};
