/**
 * VendorLineItem_Entity type guard.
 *
 * @param {any} vendorLineItemJson VendorLineItem object from API
 * @returns {boolean} Return true if type is VendorLineItem_Entity
 */
function validator(
  vendorLineItemJson: unknown
): vendorLineItemJson is VendorLineItem_Entity {
  if (typeof vendorLineItemJson === "object" && vendorLineItemJson != null) {
    VendorLineItem_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(vendorLineItemJson).includes(element))
        console.log(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class VendorLineItem_Entity {
  static requiredFields = ["id"];

  constructor(vendorLineItemJson: unknown) {
    if (validator(vendorLineItemJson)) {
      this.id = vendorLineItemJson.id;
      this.classID = vendorLineItemJson.classID;
      this.glAccountID = vendorLineItemJson.glAccountID;
      this.vendorID = vendorLineItemJson.vendorID;
      this.toUpdate = vendorLineItemJson.toUpdate;
      this.toCreate = vendorLineItemJson.toCreate;
      this.toDelete = vendorLineItemJson.toDelete;
      this.position = vendorLineItemJson.position;
    } else
      throw new Error(
        `Failed to create new instance of ${VendorLineItem_Entity.name}`
      );
  }

  id: number;
  classID?: number;
  glAccountID?: number;
  vendorID?: number;
  toUpdate?: boolean;
  toCreate?: boolean;
  toDelete?: boolean;
  position?: number;
}
