import { CssVarsProvider } from "@mui/joy";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import { Settings } from "luxon";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "allotment/dist/style.css";
import "./i18n";
import i18n from "./i18n";
import "./index.css";
import { store } from "./redux/store";
import { appTheme } from "./atoms/config/themeConfig";
import NerdApp from "./atoms/nerdApp/nerdApp";
import AskQuestion from "./hooks/askQuestion/askQuestion";
import * as serviceWorker from "./serviceWorker";

Settings.defaultLocale = i18n.language;

// document.title = AppConfig.app.title || "Nerd Home";
// const favIcon = document.getElementById("favicon");
// if (favIcon !== null && favIcon instanceof HTMLLinkElement)
//   favIcon.href = AppConfig.app.favicon || "";

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://c618c6fc985945db933935159ba73489@o4505109909602304.ingest.sentry.io/4505541418287104",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://sales.wa.archer-app.com/"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  Sentry.setTags({
    repo: window.app_info?.repo,
    tag: window.app_info?.tag,
    commit: window.app_info?.commit,
  });
}

//Mui X Pro License Key
LicenseInfo.setLicenseKey(
  "7908c976763f4c481913a58651a92565T1JERVI6MzkwMDIsRVhQSVJZPTE2Nzc5NTg1MjQwMDAsS0VZVkVSU0lPTj0x"
);

const container = document.getElementById("root");

if (container != null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <CssVarsProvider theme={appTheme}>
            <AskQuestion>
              <NerdApp />
            </AskQuestion>
          </CssVarsProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
