import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { GlAccount } from "../../entities/glAccount";

export const setSelectedGlAccountID = createAction(
  "setSelectedGlAccountID",
  function prepare(glAccountID?: number) {
    return {
      payload: {
        glAccountID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getGlAccounts = createAction(
  "ap-dashboard/glAccount/getGlAccounts",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/glAccounts`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const glAccounts = GlAccount.fromList(json);
      dispatch(getGlAccountsSuccess(glAccounts));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getGlAccountsSuccess = createAction(
  "ap-dashboard/glAccount/getGlAccountSuccess",
  function prepare(glAccounts: GlAccount[]) {
    return {
      payload: {
        glAccounts,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getGlAccountsByFilter = createAction(
  "ap-dashboard/glAccount/getGlAccountsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/glAccounts`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const glAccounts = GlAccount.fromList(json);
      dispatch(getGlAccountsByFilterSuccess(glAccounts));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getGlAccountsByFilterSuccess = createAction(
  "ap-dashboard/glAccount/getGlAccountsByFilterSuccess",
  function prepare(glAccounts: GlAccount[]) {
    return {
      payload: {
        glAccounts,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getGlAccountById = createAction(
  "ap-dashboard/glAccount/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/glAccounts/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const glAccount = new GlAccount(json);
      dispatch(getGlAccountByIdSuccess(glAccount));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getGlAccountByIdSuccess = createAction(
  "ap-dashboard/glAccount/getGlAccountByIdSuccess",
  function prepare(glAccount?: GlAccount) {
    return {
      payload: {
        glAccount,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteGlAccount = createAction(
  "ap-dashboard/removeGlAccountById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/glAccounts/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteGlAccountSuccess = createAction(
  "ap-dashboard/glAccounts/deleteGlAccountSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateGlAccount = createAction(
  "ap-dashboard/glAccounts/updateGlAccount",
  function prepare(id: number, body: Partial<GlAccount>) {
    const request = new PutAction(`/ap-dashboard/glAccounts/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateGlAccountSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateGlAccountSuccess = createAction(
  "ap-dashboard/glAccounts/updateGlAccountSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createGlAccount = createAction(
  "ap-dashboard/glAccounts/createGlAccount",
  function prepare(body: Partial<GlAccount>) {
    const request = new PostAction(`/ap-dashboard/glAccounts`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const glAccount = new GlAccount(json);
      dispatch(createGlAccountSuccess(glAccount));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createGlAccountSuccess = createAction(
  "ap-dashboard/glAccounts/createGlAccountSuccess",
  function prepare(glAccount: GlAccount) {
    return {
      payload: {
        glAccount,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getGlAccountsSearchMetadata = createAction(
  "ap-dashboard/getGlAccountsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/glAccounts/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getGlAccountsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getGlAccountsSearchMetadataSuccess = createAction(
  "ap-dashboard/getGlAccountsSearchMetadataSuccess",
  function prepare(glAccountsSearchMetadata: unknown) {
    return {
      payload: {
        glAccountsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
