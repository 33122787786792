import ItemClass_Entity from "./itemClass";
export default class ItemClass extends ItemClass_Entity {
  static fromList(itemClasssJSON: unknown): Array<ItemClass> {
    const itemClasss: ItemClass[] = [];
    if (itemClasssJSON)
      Array.isArray(itemClasssJSON) &&
        itemClasssJSON.forEach((itemClassJSON) => {
          itemClasss.push(new ItemClass(itemClassJSON));
        });
    return itemClasss;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
