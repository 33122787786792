import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectPriorityLevels = (state: AppState) =>
  state.priorityLevelState.priorityLevels;
const selectSelectedPriorityLevelID = (state: AppState) =>
  state.priorityLevelState.selectedPriorityLevelID;

export const priorityLevelsSelector = createSelector(
  [selectPriorityLevels],
  (priorityLevels) => mapToArray(priorityLevels).reverse()
);

export const selectedPriorityLevelSelector = createSelector(
  [selectPriorityLevels, selectSelectedPriorityLevelID],
  (priorityLevels, selectedPriorityLevelID) => {
    if (selectedPriorityLevelID) return priorityLevels[selectedPriorityLevelID];
    return undefined;
  }
);

export const priorityLevelSelector = (id?: number) => {
  return createSelector([selectPriorityLevels], (priorityLevels) => {
    if (id) return priorityLevels[id];
    return undefined;
  });
};
