import {
  Alert,
  Button,
  Chip,
  Divider,
  IconButton,
  Input,
  Tab,
  TabList,
  Tabs,
  Typography,
  useTheme,
} from "@mui/joy";
import {
  Box,
  ButtonGroup,
  Grid,
  Button as MuiButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { NerdCurrencyField, NerdFileUpload } from "@nerdjs/nerd-ui";
import "allotment/dist/style.css";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import Reviewer from "../../atoms/reviewer/reviewer";
import { transactionTypes } from "../../constants";
import { File_Entity } from "../../entities/file";
import { LineItem } from "../../entities/lineItem";
import { useApExport } from "../../redux/apExport/apExportHooks";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { companySelector } from "../../redux/company/companySelector";
import { glAccountSelector } from "../../redux/glAccount/glAccountSelector";
import {
  deletePayable,
  lockPayable,
  newStagedPayableFile,
  stagedPayableUpdateRequest,
  updateStagedPayable,
} from "../../redux/payable/payableActions";
import { stagedPayableSelector } from "../../redux/payable/payableSelector";
import { priorityLevelsSelector } from "../../redux/priorityLevel/priorityLevelSelector";
import { vendorSelector } from "../../redux/vendor/vendorSelector";
import { CompanyAutocomplete } from "../company/companyAutocomplete";
import { GlAccountAutocomplete } from "../glAccount/glAccountAutocomplete";
import { accountingManager, useMyAccess, user } from "../myAccess/myAccess";
import { VendorAutocomplete } from "../vendor/vendorAutocomplete";
import { PayableLineItems } from "./payableLineItems";
import { useAskQuestion } from "../askQuestion/askQuestion";

export function PayableForm({ mobile = false }: { mobile: boolean }) {
  const stagedPayable = useAppSelector(stagedPayableSelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const priorityLevelsMap = useAppSelector(
    (s) => s.priorityLevelState.priorityLevels
  );
  const askQuestion = useAskQuestion();
  const { data: me } = useGetCurrentUserQuery();
  const vendor = useAppSelector(vendorSelector(stagedPayable?.vendorID));
  const glAccount = useAppSelector(
    glAccountSelector(stagedPayable?.glAccountID)
  );
  const company = useAppSelector(companySelector(stagedPayable?.companyID));
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { apExport } = useApExport(stagedPayable?.exportID);
  const myAccess = useMyAccess();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  if (!stagedPayable || !me) return <Box />;

  const locked = stagedPayable.locked ?? false;

  let amountHelperText = "";
  let amountError = false;

  if (stagedPayable.transactionType === 0) {
    amountHelperText = "Note: The amount must be positive";
    amountError = (stagedPayable.amount ?? 0) < 0;
  }

  if (stagedPayable.transactionType === 1) {
    amountHelperText = "Note: The amount must be negative";
    amountError = (stagedPayable.amount ?? 0) > 0;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "relative",
        }}
      >
        {!expanded ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography level="h4">
                  Payable {stagedPayable.referenceNumber}
                </Typography>
                {stagedPayable?.id ? (
                  <Typography level="body1">
                    #{stagedPayable.id}, Created by{" "}
                    {stagedPayable?.createdBy?.fullname()}, on{" "}
                    {moment(stagedPayable.createdAt).format("MM/DD/YYYY")}
                  </Typography>
                ) : (
                  []
                )}
                {apExport ? (
                  <Typography
                    level="body2"
                    startDecorator={"Exported on"}
                    endDecorator={`#${apExport.id}`}
                  >
                    <Chip size="sm" variant="soft" color="primary">
                      {apExport.name}
                    </Chip>
                  </Typography>
                ) : (
                  []
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Tabs
                  size="sm"
                  value={stagedPayable.priorityLevelID}
                  onChange={(e, v) =>
                    !locked &&
                    dispatch(
                      updateStagedPayable({
                        priorityLevelID: v as number,
                        priorityLevel: priorityLevelsMap[v as number],
                      })
                    )
                  }
                >
                  <TabList>
                    {priorityLevels.map((pl) => (
                      <Tab
                        key={pl.id}
                        value={pl.id}
                        disabled={myAccess === user}
                        variant={
                          pl.id === stagedPayable.priorityLevelID
                            ? "solid"
                            : undefined
                        }
                        color={
                          pl.id === stagedPayable.priorityLevelID
                            ? "primary"
                            : undefined
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 0.3 }}>
                            {Array.from(
                              Array(priorityLevels.length + 1 - pl.id).keys()
                            ).map((i) => (
                              <i
                                style={{ fontSize: 11 }}
                                key={i}
                                className="fa-solid fa-flag"
                              ></i>
                            ))}
                          </Box>
                          <Typography sx={{ color: "inherit" }} level="body3">
                            {pl.name}
                          </Typography>
                        </Box>
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>
                <Typography level="body2">
                  {stagedPayable.priorityLevel?.followUpDays} Follow up day
                  {(stagedPayable.priorityLevel?.followUpDays ?? 0) > 1
                    ? "s"
                    : ""}
                </Typography>
              </Box>
            </Box>
            {stagedPayable.id &&
            myAccess !== user &&
            stagedPayable.lockable &&
            !stagedPayable.locked ? (
              <Alert
                startDecorator={<i className="fa-duotone fa-lock-keyhole"></i>}
                endDecorator={
                  <Button
                    color="warning"
                    variant="solid"
                    onClick={() => {
                      askQuestion(
                        `You are about to lock the payable (#${
                          stagedPayable?.referenceNumber ?? ""
                        })`,
                        ["Cancel", "Yes"]
                      ).then((i) => {
                        if (i == 1) {
                          dispatch(
                            stagedPayableUpdateRequest(
                              navigate,
                              false,
                              {},
                              () => {
                                dispatch(
                                  lockPayable(stagedPayable.id, {
                                    locked: true,
                                  })
                                );
                              }
                            )
                          );
                        }
                      });
                    }}
                  >
                    Lock Now
                  </Button>
                }
                variant="solid"
                color="warning"
              >
                This payable is ready to be locked
              </Alert>
            ) : (
              []
            )}
            {stagedPayable.locked ? (
              <Alert
                startDecorator={<i className="fa-duotone fa-lock-keyhole"></i>}
                variant="solid"
                color="neutral"
              >
                <Box>
                  This payable has been locked.
                  <Typography
                    level="body3"
                    style={{ opacity: 0.7, color: "inherit" }}
                  >
                    locked on {moment(stagedPayable.lockedDate).fromNow()}
                  </Typography>
                </Box>
              </Alert>
            ) : (
              []
            )}
            {stagedPayable.id && stagedPayable.canApprove(me) ? (
              <Alert
                startDecorator={
                  <i className="fa-sharp fa-solid fa-badge-check"></i>
                }
                endDecorator={
                  <Button
                    color="primary"
                    variant="solid"
                    onClick={() =>
                      dispatch(
                        stagedPayableUpdateRequest(navigate, false, {
                          approved: true,
                          approvedDate: moment().format("YYYY-MM-DD"),
                        })
                      )
                    }
                  >
                    Approve Now
                  </Button>
                }
                variant="solid"
              >
                <Box>
                  You need to review and approve the content of this payable.
                  <Typography
                    level="body3"
                    style={{ opacity: 0.7, color: "inherit" }}
                  >
                    Make sure to write a memo before you approve.
                  </Typography>
                </Box>
              </Alert>
            ) : (
              []
            )}
            {stagedPayable.id && stagedPayable.canDualApprove(me) ? (
              <Alert
                startDecorator={
                  <i className="fa-sharp fa-solid fa-badge-check"></i>
                }
                endDecorator={
                  <Button
                    color="primary"
                    variant="solid"
                    onClick={() =>
                      dispatch(
                        stagedPayableUpdateRequest(navigate, false, {
                          dualApproved: true,
                          dualApprovedDate: moment().format("YYYY-MM-DD"),
                        })
                      )
                    }
                  >
                    Dual Approve Now
                  </Button>
                }
                variant="solid"
              >
                <Box>
                  You need to double check the content of this payable.
                  <Typography
                    level="body3"
                    style={{ opacity: 0.7, color: "inherit" }}
                  >
                    Make sure to write a memo before you approve.
                  </Typography>
                </Box>
              </Alert>
            ) : (
              []
            )}
            <Divider sx={{ height: 4 }} />
            {sm ? (
              <>
                <Box>
                  <Typography
                    level="h6"
                    startDecorator={<i className="fa-solid fa-paperclip"></i>}
                  >
                    Attachments
                  </Typography>
                  <Typography level="body2">
                    Files attached to this payable
                  </Typography>
                </Box>
                <Grid container>
                  {stagedPayable.files
                    .filter((f) => !f.toDelete)
                    .map((f) => (
                      <Grid item key={f.id}>
                        <Button
                          onClick={() => window.open(f.previewURL())}
                          sx={{ maxWidth: 250 }}
                          endDecorator={
                            <i className="fa-regular fa-arrow-up-right-from-square"></i>
                          }
                          variant="outlined"
                          size="sm"
                          color="neutral"
                        >
                          <Typography level="body2" noWrap>
                            {f.name}
                          </Typography>
                        </Button>
                      </Grid>
                    ))}
                </Grid>
                <NerdFileUpload
                  dropZoneLabel="Attach to Payable"
                  uploadFile={(e: File) => {
                    const f: File_Entity = {
                      id: new Date().getTime(),
                      name: e.name,
                      blob: URL.createObjectURL(e),
                      file: e,
                      toCreate: true,
                    };
                    dispatch(newStagedPayableFile(f));
                  }}
                  hideFilesList
                  dense
                />
                <Divider sx={{ height: 4 }} />
              </>
            ) : (
              []
            )}
            <Box>
              <Typography
                level="h6"
                startDecorator={<i className="fa-regular fa-circle-info"></i>}
              >
                References
              </Typography>
              <Typography level="body2">
                Provide important information about the payable.
              </Typography>
            </Box>
            <ButtonGroup disabled={locked || myAccess === user}>
              {transactionTypes.map((e) => (
                <MuiButton
                  startIcon={
                    <i style={{ fontSize: 14 }} className={e.faClass}></i>
                  }
                  key={e.id}
                  variant={
                    stagedPayable.transactionType === e.id
                      ? "contained"
                      : undefined
                  }
                  onClick={() =>
                    dispatch(
                      updateStagedPayable({
                        transactionType: e.id,
                      })
                    )
                  }
                >
                  {e.name}
                </MuiButton>
              ))}
            </ButtonGroup>
            <Grid container spacing={2}>
              <Grid item xs={mobile ? 12 : 6}>
                <TextField
                  disabled={locked || myAccess === user}
                  label="Reference Number"
                  size="small"
                  fullWidth
                  defaultValue={stagedPayable.referenceNumber}
                  onChange={(e) =>
                    dispatch(
                      updateStagedPayable({
                        referenceNumber: e.target.value,
                      })
                    )
                  }
                  error={stagedPayable.referenceNumber.length > 20}
                  helperText={stagedPayable.charLeft()}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <NerdCurrencyField
                  signed
                  textFieldProps={{
                    size: "small",
                    helperText: amountHelperText,
                    error: amountError,
                    label: "Amount",
                    fullWidth: true,
                    disabled: locked || myAccess === user,
                  }}
                  multiplier={1}
                  value={stagedPayable.amount}
                  onChange={(e) => {
                    if (e != stagedPayable.amount) {
                      dispatch(
                        updateStagedPayable({
                          amount: Number(e),
                        })
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <CompanyAutocomplete
                  companyID={stagedPayable.companyID}
                  disabled={locked || myAccess === user}
                  size={"small"}
                  onChange={(c) =>
                    dispatch(
                      updateStagedPayable({
                        companyID: c ? c.id : undefined,
                      })
                    )
                  }
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <VendorAutocomplete
                  vendorID={stagedPayable.vendorID}
                  disabled={locked || !company || myAccess === user}
                  filter={(v) =>
                    v.companyID === stagedPayable.companyID &&
                    (stagedPayable.transactionType !== 2
                      ? v.nameListType === 0
                      : true)
                  }
                  error={vendor && company && vendor?.companyID !== company?.id}
                  size={"small"}
                  helperText={
                    vendor && company && vendor?.companyID !== company?.id
                      ? `${vendor.name} is not tied to ${company?.name ?? ""}`
                      : `${vendor?.addr2 ?? ""} ${vendor?.addr3 ?? ""}`
                  }
                  onChange={(v) => {
                    const defaultLI: LineItem[] = [];
                    (v?.defaultLineItems ?? []).forEach((dli) =>
                      defaultLI.push(new LineItem({ ...dli, toCreate: true }))
                    );

                    dispatch(
                      updateStagedPayable({
                        vendorID: v ? v.id : undefined,
                        assignedToUUID: v?.defaultAssignedToUUID,
                        assignedTo: v?.defaultAssignedTo,
                        dualAssignedToUUID: v?.defaultDualAssignedToUUID,
                        dualAssignedTo: v?.defaultDualAssignedTo,
                        lineItems: [...stagedPayable.lineItems, ...defaultLI],
                        dueDate: v?.terms.Days
                          ? moment(stagedPayable.date)
                              .add(v?.terms.Days, "days")
                              .format("YYYY-MM-DD")
                          : stagedPayable.date,
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <DatePicker
                  label="Date"
                  value={
                    stagedPayable.date ? moment(stagedPayable.date).utc() : null
                  }
                  disabled={locked || myAccess === user}
                  onChange={(d) => {
                    dispatch(
                      updateStagedPayable({
                        date: d?.format("YYYY-MM-DD"),
                        dueDate: vendor?.terms.Days
                          ? d
                              ?.clone()
                              .add(vendor?.terms.Days ?? 0, "days")
                              .format("YYYY-MM-DD")
                          : d?.format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={stagedPayable.errorOnDate(company)}
                      helperText={
                        company
                          ? `Lock Date:
                            ${moment(company?.glLockDate?.date).format(
                              "MM/DD/YYYY"
                            )}`
                          : ""
                      }
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <DatePicker
                  label="Due Date"
                  disabled={
                    locked ||
                    stagedPayable.transactionType !== 0 ||
                    myAccess === user
                  }
                  value={
                    stagedPayable.dueDate
                      ? moment(stagedPayable.dueDate).utc()
                      : null
                  }
                  onChange={(newValue) => {
                    dispatch(
                      updateStagedPayable({
                        dueDate: moment(newValue).format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={stagedPayable.dueDateDaysDifference()}
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <TextField
                  disabled={locked}
                  label="Memo"
                  size="small"
                  fullWidth
                  defaultValue={stagedPayable.memo}
                  onChange={(e) =>
                    dispatch(
                      updateStagedPayable({
                        memo: e.target.value,
                      })
                    )
                  }
                  multiline
                  maxRows={5}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <GlAccountAutocomplete
                  disabled={
                    locked ||
                    stagedPayable.transactionType < 2 ||
                    !company ||
                    myAccess === user
                  }
                  filter={(gla) => {
                    if (stagedPayable.transactionType === 2) {
                      return (
                        gla.companyID === company?.id && gla.type === "BANK"
                      );
                    }
                    return gla.companyID === company?.id;
                  }}
                  glAccountID={stagedPayable.glAccountID}
                  size={"small"}
                  helperText={
                    glAccount && company && glAccount?.companyID !== company?.id
                      ? `${glAccount.name} is not tied to ${
                          company?.name ?? ""
                        }`
                      : ""
                  }
                  error={
                    glAccount && company && glAccount?.companyID !== company?.id
                  }
                  onChange={(c) =>
                    dispatch(
                      updateStagedPayable({
                        glAccountID: c ? c.id : undefined,
                      })
                    )
                  }
                />
              </Grid>
            </Grid>
            <Divider sx={{ height: 4 }} />
            <Box>
              <Typography
                level="h6"
                startDecorator={<i className="fa-regular fa-thumbs-up"></i>}
              >
                Approval
              </Typography>
              <Typography level="body2">
                Assign this payable for review to up to 2 people. They will be
                notified by email to perform the audit by the due date.
              </Typography>
              <br />
              <Box sx={{ display: "flex", gap: 2 }}>
                <Reviewer
                  disabled={locked || myAccess === user}
                  approved={stagedPayable.approved}
                  date={stagedPayable.approvedDate}
                  user={stagedPayable.assignedTo}
                  onChange={(u) =>
                    dispatch(
                      updateStagedPayable({
                        assignedToUUID: u ? u.uuid : undefined,
                        assignedTo: u ? u : undefined,
                        approvedDate: undefined,
                      })
                    )
                  }
                />
                <Reviewer
                  disabled={locked || myAccess === user}
                  approved={stagedPayable.dualApproved}
                  secondary
                  date={stagedPayable.dualApprovedDate}
                  user={stagedPayable.dualAssignedTo}
                  onChange={(u) =>
                    dispatch(
                      updateStagedPayable({
                        dualAssignedToUUID: u ? u.uuid : undefined,
                        dualAssignedTo: u ? u : undefined,
                        dualApprovedDate: "",
                      })
                    )
                  }
                />
              </Box>
            </Box>
            <Divider sx={{ height: 4 }} />
          </>
        ) : (
          []
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography
              level="h6"
              startDecorator={<i className="fa-solid fa-list-ul"></i>}
            >
              Line Items
            </Typography>
            <Typography level="body2">
              Enter the Payable’s content. CTRL+SPACE to add new line item.
            </Typography>
          </Box>
          <Tooltip title={expanded ? "Minimize" : "Expand"}>
            <IconButton
              onClick={() => setExpanded((s) => !s)}
              color="neutral"
              size="sm"
              variant="outlined"
            >
              <i
                className={
                  expanded
                    ? "fa-solid fa-minimize"
                    : "fa-sharp fa-solid fa-up-right-and-down-left-from-center"
                }
              ></i>
            </IconButton>
          </Tooltip>
        </Box>
        <PayableLineItems />
        {stagedPayable?.id && myAccess === accountingManager ? (
          <DangerZone
            resource="payable"
            password={stagedPayable.referenceNumber}
            onDelete={() => {
              dispatch(deletePayable(stagedPayable.id));
              dispatch(setFormOpen(false, "payable"));
            }}
          />
        ) : (
          []
        )}
      </Box>
    </LocalizationProvider>
  );
}

export function DangerZone({
  password,
  onDelete,
  resource,
}: {
  password: string;
  onDelete: () => void;
  resource: string;
}) {
  const [value, setValue] = useState("");
  const askQuestion = useAskQuestion();

  const finalWarning = () => {
    askQuestion(
      `Final warning, you are about to delete this ${resource} for ever.`,
      ["Cancel", "Yes"],
      {
        subtitle: <Typography>Please confirm</Typography>,
      }
    ).then((i) => {
      if (i == 1) onDelete();
    });
  };
  return (
    <>
      <Divider sx={{ height: 4 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            level="h6"
            startDecorator={<i className="fa-duotone fa-radiation"></i>}
          >
            Danger Zone
          </Typography>
          <Typography level="body2">
            Enter the reference number in order to delete this {resource}. There
            is no way back.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Input
          onChange={(e) => setValue(e.target.value)}
          value={value}
          startDecorator="Password:"
          placeholder={password}
        />
        <Button
          color={value !== password ? "neutral" : "danger"}
          disabled={value !== password}
          onClick={finalWarning}
        >
          Delete {resource}
        </Button>
      </Box>
    </>
  );
}
