import { CircularProgress, Divider, useColorScheme } from "@mui/joy";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { useState } from "react";
import { useAppSelector } from "../../atoms/hooks";
import {
  stagedPayableLoadingSelector,
  stagedPayableSelector,
} from "../../redux/payable/payableSelector";
import PayableFiles from "./payableFiles";
import { PayableForm } from "./payableForm";
import { PayableToolbar } from "./payableToolbar";

/**
 *
 * @returns {ReactElement} Payable component
 */
export function Payable() {
  const [mobile, setMobile] = useState(false);
  const stagedPayable = useAppSelector(stagedPayableSelector);
  const allotmentPayableSize = JSON.parse(
    localStorage.getItem("allotmentPayableSize") ?? '["50%", "50%"]'
  );
  const { mode } = useColorScheme();
  const isDark = mode === "dark";
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const stagedPayableLoading = useAppSelector(stagedPayableLoadingSelector);

  const handleAllotmentChange = (e: number[]) => {
    if (e[1] < 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    localStorage.setItem("allotmentPayableSize", JSON.stringify(e));
  };

  if (!stagedPayable)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      {stagedPayableLoading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            backdropFilter: "blur(20px)",
            zIndex: 99,
            display: "flex",
            alignItems: "center",
            background: isDark ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography>Please wait, system processing...</Typography>
          </Box>
        </Box>
      ) : (
        []
      )}
      <PayableToolbar />
      <Divider />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          position: "relative",
        }}
      >
        {sm ? (
          <Box
            sx={{
              p: 2,
              height: "calc(100vh - 48px)",
              overflowY: "scroll",
            }}
          >
            <PayableForm mobile={mobile} />
          </Box>
        ) : (
          <Allotment
            vertical={false}
            onChange={handleAllotmentChange}
            defaultSizes={allotmentPayableSize}
          >
            <Allotment.Pane minSize={300}>
              <Box sx={{ height: "calc(100vh - 48px)", position: "relative" }}>
                <PayableFiles />
              </Box>
            </Allotment.Pane>
            <Allotment.Pane minSize={400} className="payableFormSplitView">
              <Box
                sx={{
                  p: 2,
                  height: "calc(100vh - 48px)",
                  overflowY: "scroll",
                }}
              >
                <PayableForm mobile={mobile} />
              </Box>
            </Allotment.Pane>
          </Allotment>
        )}
      </Box>
    </Box>
  );
}
