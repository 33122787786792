import {
  Button,
  Chip,
  ChipDelete,
  CircularProgress,
  Typography,
  useColorScheme,
} from "@mui/joy";
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NerdFileUpload, NerdFileUploadWrapper } from "@nerdjs/nerd-ui";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import Reviewer from "../../atoms/reviewer/reviewer";
import { File, File_Entity } from "../../entities/file";
import { VendorLineItem } from "../../entities/vendorLineItem";
import { newIndexAfterReorder } from "../../helpers/reorderHelpers";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { getGlAccounts } from "../../redux/glAccount/glAccountActions";
import {
  glAccountsMapSelector,
  glAccountsSelector,
} from "../../redux/glAccount/glAccountSelector";
import { getItemClasses } from "../../redux/itemClass/itemClassActions";
import {
  itemClassesMapSelector,
  itemClassesSelector,
} from "../../redux/itemClass/itemClassSelector";
import {
  deleteStagedVendorLineItem,
  newStagedVendorLineItem,
  updateStagedVendorLineItem,
} from "../../redux/payable/payableActions";
import {
  stagedVendorUpdateRequest,
  updateStagedVendor,
} from "../../redux/vendor/vendorActions";
import {
  stagedVendorLoadingSelector,
  stagedVendorSelector,
} from "../../redux/vendor/vendorSelector";
import { GlAccountAutocomplete } from "../glAccount/glAccountAutocomplete";
import { ItemClassAutocomplete } from "../itemClass/itemClassAutocomplete";

export function VendorForm() {
  const open = useAppSelector(formOpenSelector("vendor"));
  const glAccountsMap = useAppSelector(glAccountsMapSelector);
  const glAccounts = useAppSelector(glAccountsSelector);
  const itemClassesMap = useAppSelector(itemClassesMapSelector);
  const itemClasses = useAppSelector(itemClassesSelector);
  const stagedVendor = useAppSelector(stagedVendorSelector);
  const stagedVendorLoading = useAppSelector(stagedVendorLoadingSelector);
  const apiRef = useGridApiRef();
  const { mode } = useColorScheme();
  const lineItems = (stagedVendor?.defaultLineItems ?? [])
    .filter((li) => !li.toDelete)
    .sort((a, b) => (a?.position ?? 10000) - (b?.position ?? 10000));
  const isDark = mode === "dark";
  const navigate = useNavigate();

  const lineItemPositionChanged = (params: GridRowOrderChangeParams) => {
    const lineItem = lineItems[params.oldIndex];
    const up = params.oldIndex < params.targetIndex;
    lineItem.position = newIndexAfterReorder(lineItems, params.targetIndex, up);

    dispatch(updateStagedVendorLineItem(lineItem));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (glAccounts.length === 0) {
      dispatch(getGlAccounts());
    }
    if (itemClasses.length === 0) {
      dispatch(getItemClasses());
    }
  }, []);

  const lineItemUpdate = async (lineItem: VendorLineItem) => {
    return new Promise<VendorLineItem>((resolve) => {
      dispatch(updateStagedVendorLineItem(lineItem));
      setTimeout(() => {
        resolve(lineItem);
      }, 50);
    });
  };

  const uploadFile = (file: File_Entity) => {
    file.id = -1;
    dispatch(
      updateStagedVendor({
        files: [...(stagedVendor?.files ?? []), file],
      })
    );
  };

  const deleteFile = (file: File) => {
    const index = stagedVendor?.files?.indexOf(file);
    if (index != undefined && index != -1) {
      stagedVendor?.files?.splice(index, 1);
      dispatch(
        updateStagedVendor({
          files: stagedVendor?.files,
        })
      );
    }
  };

  if (!stagedVendor) return <div />;

  return (
    <Dialog
      onClose={() => {
        dispatch(setFormOpen(false, "vendor"));
      }}
      fullWidth
      maxWidth="md"
      open={open}
    >
      {stagedVendorLoading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            backdropFilter: "blur(20px)",
            zIndex: 99,
            display: "flex",
            alignItems: "center",
            background: isDark ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography>Please wait, system processing...</Typography>
          </Box>
        </Box>
      ) : (
        []
      )}
      <DialogTitle>{stagedVendor?.name}</DialogTitle>
      <DialogContent>
        <Box sx={{}}>
          <NerdFileUploadWrapper>
            <NerdFileUpload
              hideFilesList
              dropZoneLabel="File upload example"
              uploadFile={(e) => {
                const f: File_Entity = {
                  id: new Date().getTime(),
                  name: e.name,
                  blob: URL.createObjectURL(e),
                  file: e,
                  toCreate: true,
                };
                uploadFile(f);
              }}
            />
          </NerdFileUploadWrapper>

          <Box
            sx={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "thin",
              gap: 1,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Grid container spacing={1}>
              {stagedVendor?.files?.map((f, index) => (
                <Grid key={index} item>
                  <Chip
                    size="sm"
                    variant="soft"
                    color="neutral"
                    onClick={() => window.open(f.previewURL(), "_blank")}
                    startDecorator={<i className="fa-solid fa-file"></i>}
                    endDecorator={
                      f.id == -1 ? (
                        <Tooltip title={"Delete file"}>
                          <ChipDelete
                            variant="plain"
                            onClick={() => deleteFile(f)}
                          ></ChipDelete>
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    {f.name}
                  </Chip>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box>
              <Typography level="body2">Default assigned to:</Typography>
              <Reviewer
                onChange={(e) =>
                  dispatch(
                    updateStagedVendor({
                      ...stagedVendor,
                      defaultAssignedTo: e,
                      defaultAssignedToUUID: e?.uuid,
                    })
                  )
                }
                user={stagedVendor.defaultAssignedTo}
                approved={false}
                disableHelperText
              />
            </Box>
            <Box>
              <Typography level="body2">Default dual assigned to:</Typography>
              <Reviewer
                onChange={(e) =>
                  dispatch(
                    updateStagedVendor({
                      ...stagedVendor,
                      defaultDualAssignedTo: e,
                      defaultDualAssignedToUUID: e?.uuid,
                    })
                  )
                }
                user={stagedVendor.defaultDualAssignedTo}
                approved={false}
                secondary
                disableHelperText
              />
            </Box>
          </Box>
          <Box>
            <Typography level="body2">Default Line Items</Typography>
          </Box>
          <DataGridPro
            rows={lineItems}
            autoHeight
            processRowUpdate={lineItemUpdate}
            onRowOrderChange={lineItemPositionChanged}
            density="compact"
            experimentalFeatures={{ newEditingApi: true }}
            apiRef={apiRef}
            onCellClick={(e) => {
              apiRef.current?.startCellEditMode({ id: e.id, field: e.field });
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowReordering
            columns={[
              {
                field: "glAccountID",
                headerName: "GL Account",
                editable: true,
                renderEditCell: (p) => (
                  <GLAccountInputCell
                    {...p}
                    companyID={stagedVendor.companyID}
                  />
                ),
                valueFormatter: (p) => glAccountsMap[p.value]?.name ?? "",
                flex: 1,
              },
              {
                field: "classID",
                headerName: "Item Class",
                renderEditCell: (p) => (
                  <ItemClassInputCell
                    {...p}
                    companyID={stagedVendor.companyID}
                  />
                ),
                valueFormatter: (p) => itemClassesMap[p.value]?.name ?? "",
                editable: true,
                flex: 1,
              },
              {
                field: "actions",
                headerName: "",
                align: "right",
                type: "actions",
                width: 40,
                getActions: (p) => [
                  <GridActionsCellItem
                    key="Delete"
                    onClick={() => dispatch(deleteStagedVendorLineItem(p.row))}
                    icon={<i className="fa-solid fa-xmark"></i>}
                    label={"Delete"}
                    color="inherit"
                  />,
                ],
              },
            ]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="soft"
          color="neutral"
          onClick={() => {
            dispatch(setFormOpen(false, "vendor"));
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            dispatch(stagedVendorUpdateRequest(navigate, true));
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewJournalEntryLineItem = () => {
    dispatch(newStagedVendorLineItem());
  };

  useHotkeys(`ctrl+space`, handleNewJournalEntryLineItem);

  return (
    <Box
      className="header"
      sx={{ display: "flex", p: 1, justifyContent: "space-between" }}
    >
      <Tooltip title="ctrl + space">
        <MUIButton size="small" onClick={handleNewJournalEntryLineItem}>
          + New Line Item
        </MUIButton>
      </Tooltip>
    </Box>
  );
}

/**
 * ItemClassInputCell.
 *
 * @param {GridRenderCellParams<number>} props grid params
 * @returns {ReactElement} The autocomplete
 */
export function ItemClassInputCell(
  props: GridRenderCellParams<number> & { companyID: number }
) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();

  return (
    <ItemClassAutocomplete
      itemClassID={value}
      size="small"
      filter={(e) => e.companyID === props?.companyID}
      onChange={(e) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: e?.id,
        });
        if (e) apiRef.current.stopCellEditMode({ id, field });
      }}
    />
  );
}

/**
 * GLAccountInputCell.
 *
 * @param {GridRenderCellParams<number>} props grid params
 * @returns {ReactElement} The autocomplete
 */
export function GLAccountInputCell(
  props: GridRenderCellParams<number> & { companyID: number }
) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();

  return (
    <GlAccountAutocomplete
      glAccountID={value}
      size="small"
      filter={(e) => e.companyID === props?.companyID}
      onChange={(e) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: e?.id,
        });
        if (e) apiRef.current.stopCellEditMode({ id, field });
      }}
    />
  );
}
