import { showNotification } from "@nerdjs/nerd-ui";
import { nanoid } from "@reduxjs/toolkit";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppConfig } from "../environement";
import {
  createApExportSuccess,
  getApExports,
} from "./apExport/apExportActions";
import {
  createCompanyLockDateSuccess,
  getCompanies,
} from "./company/companyActions";
import {
  createJournalEntrySuccess,
  deleteJournalEntrySuccess,
  getJournalEntriesByFilter,
  getJournalEntryById,
  getJournalEntryByIdSuccess,
  lockJournalEntrySuccess,
  setSelectedJournalEntryID,
  setStagedJournalEntry,
  updateJournalEntrySuccess,
} from "./journalEntry/journalEntryActions";
import {
  createPayableSuccess,
  deletePayableSuccess,
  getMyPayablesByFilter,
  getPayableById,
  getPayableByIdSuccess,
  getPayablesByFilter,
  getPayablesSuccess,
  lockPayableSuccess,
  setPayableLoading,
  setSelectedPayableID,
  setStagedPayable,
  updatePayableSuccess,
} from "./payable/payableActions";
import {
  createSearchPresetSuccess,
  deleteSearchPresetSuccess,
  getSearchPresets,
  updateSearchPresetSuccess,
} from "./searchPreset/searchPresetActions";
import { store } from "./store";
import {
  getVendorById,
  getVendorByIdSuccess,
  getVendorFilesById,
  getVendors,
  importVendorsSuccess,
  setSelectedVendorID,
  setStagedVendor,
} from "./vendor/vendorActions";

const getPayables = () =>
  window.location.pathname.toLowerCase().indexOf("mypayables") !== -1
    ? getMyPayablesByFilter
    : getPayablesByFilter;

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (
      action.type == lockPayableSuccess ||
      action.type == updatePayableSuccess ||
      action.type == createPayableSuccess ||
      action.type == deletePayableSuccess
    ) {
      const search = new URLSearchParams(window.location.search);
      if (search.get("filters")) {
        store.dispatch(
          getPayables()({
            filters: JSON.stringify(JSON.parse(search.get("filters") ?? "[]")),
            limit: search.get("limit")
              ? JSON.parse(search.get("limit") ?? "")
              : 25,
            offset: search.get("offset")
              ? JSON.parse(search.get("offset") ?? "")
              : 0,
          })
        );
      }
    }

    if (action.type == createCompanyLockDateSuccess) {
      store.dispatch(getCompanies());
    }

    if (
      action.type == lockJournalEntrySuccess ||
      action.type == updateJournalEntrySuccess ||
      action.type == createJournalEntrySuccess ||
      action.type == deleteJournalEntrySuccess
    ) {
      const search = new URLSearchParams(window.location.search);
      if (search.get("filters")) {
        store.dispatch(
          getJournalEntriesByFilter({
            filters: JSON.stringify(JSON.parse(search.get("filters") ?? "[]")),
            limit: search.get("limit")
              ? JSON.parse(search.get("limit") ?? "")
              : 25,
            offset: search.get("offset")
              ? JSON.parse(search.get("offset") ?? "")
              : 0,
          })
        );
      }
    }

    if (action.type == importVendorsSuccess) {
      store.dispatch(getVendors());
    }

    if (action.type == createApExportSuccess) {
      store.dispatch(getApExports());
    }

    if (
      action.type == createSearchPresetSuccess ||
      action.type == updateSearchPresetSuccess ||
      action.type == deleteSearchPresetSuccess
    ) {
      store.dispatch(getSearchPresets());
    }

    if (action.type == getPayables || action.type == getPayablesByFilter) {
      store.dispatch(setPayableLoading(true));
    }

    if (action.type == getPayablesSuccess) {
      store.dispatch(setPayableLoading(false));
    }

    if (action.type == setSelectedPayableID) {
      const payableID = action.payload.payableID;
      if (payableID) {
        store.dispatch(getPayableById(payableID));
      } else {
        store.dispatch(setStagedPayable());
      }
    }

    if (action.type == setSelectedVendorID) {
      const vendorID = action.payload.vendorID;
      if (vendorID) {
        store.dispatch(getVendorById(vendorID));
        store.dispatch(getVendorFilesById(vendorID));
      } else {
        store.dispatch(setStagedVendor());
      }
    }

    if (action.type == setSelectedJournalEntryID) {
      const journalEntryID = action.payload.journalEntryID;
      if (journalEntryID) {
        store.dispatch(getJournalEntryById(journalEntryID));
      } else {
        store.dispatch(setStagedJournalEntry());
      }
    }

    if (action.type == getPayableByIdSuccess) {
      store.dispatch(setStagedPayable(action.payload.payable));
    }

    if (action.type == getVendorByIdSuccess) {
      store.dispatch(setStagedVendor(action.payload.vendor));
    }

    if (action.type == getJournalEntryByIdSuccess) {
      store.dispatch(setStagedJournalEntry(action.payload.journalEntry));
    }

    if (action.type == createApExportSuccess) {
      const apExport = action.payload.apExport;
      store.dispatch(
        showNotification({
          notification: {
            uuid: nanoid(),
            title: `Export #${apExport.id} Success`,
            body: `Your items have been exported on batch #${apExport.id}`,
            severity: "success",
            autohide: false,
          },
        })
      );
    }
    if (action.type === "ApiErrorAction") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      if (status === 401) {
        window.location.replace(AppConfig.front["401Redirect"]);
      }

      store.dispatch(
        showNotification({
          notification: {
            uuid: uuid,
            title: `${status} - ${description}`,
            body: message,
            severity: status > 300 && status < 500 ? "warning" : "error",
            autohide: true,
          },
        })
      );
    }

    return next(action);
  };
