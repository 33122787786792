import {
  Autocomplete,
  Icon,
  InputBase,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Vendor_Entity } from "../../entities/vendor";
import { useAppSelector } from "../../atoms/hooks";
import { getVendors } from "../../redux/vendor/vendorActions";
import {
  vendorsSelector,
  vendorSelector,
} from "../../redux/vendor/vendorSelector";

/**
 * @param {vendorAutocompleteProps} vendorAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} Vendor Autocomplete
 */
export function VendorAutocomplete({
  vendorID,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  error,
  filter,
  autoFocus,
}: vendorAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const vendors = useAppSelector(vendorsSelector);
  const vendor = useAppSelector(vendorSelector(vendorID));
  const dispatch = useDispatch();

  useEffect(() => {
    if (vendors.length === 0) {
      dispatch(getVendors());
    }
  }, []);

  useEffect(() => {
    if (vendor) {
      setInputValue(vendor.name);
    }
  }, [vendor]);

  return (
    <Autocomplete
      value={vendor ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      // renderOption={(props, o) => (
      //   <MenuItem {...props}>
      //     <ListItemText primary={o.name} secondary={o.companyName} />
      //   </MenuItem>
      // )}
      sx={{ flex: 1 }}
      getOptionLabel={(option) => `${option.name} (${option.companyName})`}
      options={filter ? vendors.filter(filter) : vendors}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a vendor..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            error={error}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a vendor..."
            autoFocus={autoFocus}
            label="Vendor"
            helperText={helperText}
          />
        )
      }
    />
  );
}

type vendorAutocompleteProps = {
  vendorID?: number;
  filter?: (s: Vendor_Entity) => boolean;
  onChange: (vendor: Vendor_Entity | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};
