import { User } from "@nerdjs/account-kit";
import ApExport_Entity from "./apExport";
export default class ApExport extends ApExport_Entity {
  createdBy?: User;

  constructor(apExportJson: ApExport_Entity) {
    super(apExportJson);
    if (apExportJson.createdBy)
      this.createdBy = new User(apExportJson.createdBy);
  }

  static fromList(apExportsJSON: unknown): Array<ApExport> {
    const apExports: ApExport[] = [];
    if (apExportsJSON)
      Array.isArray(apExportsJSON) &&
        apExportsJSON.forEach((apExportJSON) => {
          apExports.push(new ApExport(apExportJSON));
        });
    return apExports;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
