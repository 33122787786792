import GlAccount_Entity from "./glAccount";
export default class GlAccount extends GlAccount_Entity {
  static fromList(glAccountsJSON: unknown): Array<GlAccount> {
    const glAccounts: GlAccount[] = [];
    if (glAccountsJSON)
      Array.isArray(glAccountsJSON) &&
        glAccountsJSON.forEach((glAccountJSON) => {
          glAccounts.push(new GlAccount(glAccountJSON));
        });
    return glAccounts;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  formatName() {
    return `${this.accountNumber ? `${this.accountNumber} - ` : ""}${
      this.name
    } (${this.type})`;
  }
}
