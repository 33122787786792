import { User_Entity } from "@nerdjs/account-kit";
import { File_Entity } from "../file";
import { LineItem_Entity } from "../lineItem";
import { PriorityLevel_Entity } from "../priorityLevel";

/**
 * Payable_Entity type guard.
 *
 * @param {any} payableJson Payable object from API
 * @returns {boolean} Return true if type is Payable_Entity
 */
function validator(payableJson: unknown): payableJson is Payable_Entity {
  if (typeof payableJson === "object" && payableJson != null) {
    Payable_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(payableJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Payable_Entity {
  static requiredFields = [
    "id",
    "companyID",
    "companyName",
    "priorityLevelID",
    "vendorID",
    "vendorName",
    "referenceNumber",
    "memo",
    "amount",
    "transactionType",
    "assignedToUUID",
    "dualAssignedToUUID",
    "approved",
    "dualApproved",
    "createdByUUID",
    "createdAt",
    "updatedAt",
    "glTransactionID",
    "glEntryID",
    "glAccountID",
    "locked",
    "deleted",
    "exportID",
    "files",
    "lineItems",
    "lockable",
  ];

  constructor(payableJson: unknown) {
    if (validator(payableJson)) {
      this.id = payableJson.id;
      this.companyID = payableJson.companyID;
      this.companyName = payableJson.companyName;
      this.date = payableJson.date;
      this.dueDate = payableJson.dueDate;
      this.priorityLevelID = payableJson.priorityLevelID;
      this.priorityLevel = payableJson.priorityLevel;
      this.vendorID = payableJson.vendorID;
      this.vendorName = payableJson.vendorName;
      this.referenceNumber = payableJson.referenceNumber;
      this.memo = payableJson.memo;
      this.amount = payableJson.amount;
      this.transactionType = payableJson.transactionType;
      this.assignedToUUID = payableJson.assignedToUUID;
      this.dualAssignedToUUID = payableJson.dualAssignedToUUID;
      this.approved = payableJson.approved;
      this.approvedDate = payableJson.approvedDate;
      this.dualApproved = payableJson.dualApproved;
      this.dualApprovedDate = payableJson.dualApprovedDate;
      this.createdByUUID = payableJson.createdByUUID;
      this.createdAt = payableJson.createdAt;
      this.updatedAt = payableJson.updatedAt;
      this.assignedTo = payableJson.assignedTo;
      this.dualAssignedTo = payableJson.dualAssignedTo;
      this.createdBy = payableJson.createdBy;
      this.glTransactionID = payableJson.glTransactionID;
      this.glEntryID = payableJson.glEntryID;
      this.glAccountID = payableJson.glAccountID;
      this.locked = payableJson.locked;
      this.deleted = payableJson.deleted;
      this.exportID = payableJson.exportID;
      this.lockedDate = payableJson.lockedDate;
      this.files = payableJson.files;
      this.lineItems = payableJson.lineItems;
      this.lockable = payableJson.lockable;
      this.toDelete = payableJson.toDelete;
      this.toCreate = payableJson.toCreate;
      this.toUpdate = payableJson.toUpdate;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Payable_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  companyID?: number;
  companyName: string;
  date?: string;
  dueDate?: string;
  priorityLevelID: number;
  priorityLevel?: PriorityLevel_Entity;
  vendorID?: number;
  vendorName: string;
  referenceNumber: string;
  memo: string;
  amount?: number;
  transactionType: number;
  assignedToUUID: string;
  dualAssignedToUUID: string;
  approved: boolean;
  approvedDate?: string;
  dualApproved: boolean;
  dualApprovedDate?: string;
  createdByUUID?: string;
  createdAt: string;
  updatedAt: string;
  assignedTo?: User_Entity;
  dualAssignedTo?: User_Entity;
  createdBy?: User_Entity;
  glTransactionID?: number;
  glEntryID?: number;
  glAccountID?: number;
  locked: boolean;
  deleted: boolean;
  exportID?: number;
  lockedDate?: string;
  files: Array<File_Entity>;
  lineItems: Array<LineItem_Entity>;
  lockable: boolean;
  toUpdate?: boolean;
  toCreate?: boolean;
  toDelete?: boolean;
}
