import { Autocomplete, Icon, InputBase, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Company } from "../../entities/company";
import { useAppSelector } from "../../atoms/hooks";
import { getCompanies } from "../../redux/company/companyActions";
import {
  companiesSelector,
  companySelector,
} from "../../redux/company/companySelector";

/**
 * @param {companyAutocompleteProps} companyAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} Company Autocomplete
 */
export function CompanyAutocomplete({
  companyID,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  autoFocus,
}: companyAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const companies = useAppSelector(companiesSelector);
  const company = useAppSelector(companySelector(companyID));
  const dispatch = useDispatch();

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(getCompanies());
    }
  }, []);

  useEffect(() => {
    if (company) {
      setInputValue(company.name);
    }
  }, [company]);

  return (
    <Autocomplete
      value={company ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      getOptionLabel={(option) => `${option.name}`}
      options={companies}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a company..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a company..."
            autoFocus={autoFocus}
            label="Company"
            helperText={helperText}
          />
        )
      }
    />
  );
}

type companyAutocompleteProps = {
  companyID?: number;
  onChange: (company: Company | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};
