import { createReducer } from "@reduxjs/toolkit";
import { Vendor } from "../../entities/vendor";
import { VendorLineItem_Entity } from "../../entities/vendorLineItem";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteStagedVendorLineItem,
  newStagedVendorLineItem,
  updateStagedVendorLineItem,
} from "../payable/payableActions";
import {
  deleteVendorSuccess,
  getVendorByIdSuccess,
  getVendorFilesByIdSuccess,
  getVendorsSearchMetadataSuccess,
  getVendorsSuccess,
  setSelectedVendorID,
  setStagedVendor,
  setStagedVendorLoading,
  setVendorFilters,
  setVendorLoading,
  setVendorSearchOpen,
  updateStagedVendor,
} from "./vendorActions";
import { VendorState } from "./vendorState";

function initState(): VendorState {
  return {
    vendors: {},
    searchOpen: false,
    searchMetadata: undefined,
    loading: false,
    stagedVendor: undefined,
    stagedVendorLoading: false,
    selectedVendorID: undefined,
  };
}

export const vendorReducer = createReducer(initState(), (builder) => {
  builder.addCase(setVendorLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setStagedVendorLoading, (state, action) => {
    state.stagedVendorLoading = action.payload.loading;
    return state;
  });
  builder.addCase(setStagedVendor, (state, action) => {
    state.stagedVendor = action.payload.stagedVendor;
    return state;
  });
  builder.addCase(setVendorFilters, (state, action) => {
    state.filters = action.payload.filters;
    return state;
  });
  builder.addCase(setVendorSearchOpen, (state, action) => {
    state.searchOpen = action.payload.searchOpen;
    return state;
  });
  builder.addCase(updateStagedVendor, (state, action) => {
    if (!state.stagedVendor) return state;
    state.stagedVendor = {
      ...state.stagedVendor,
      ...action.payload.stagedVendor,
      toUpdate: true,
    };
    return state;
  });
  builder.addCase(setSelectedVendorID, (state, action) => {
    state.selectedVendorID = action.payload.vendorID;
    return state;
  });
  builder.addCase(getVendorsSuccess, (state, action) => {
    const vendors = action.payload.vendors;
    state.vendors = arrayToMap<Vendor>(vendors);
    return state;
  });
  builder.addCase(getVendorsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.vendorsSearchMetadata;
    return state;
  });
  builder.addCase(deleteVendorSuccess, (state, action) => {
    const vendorID = action.payload.id;
    if (state.vendors[vendorID]) {
      delete state.vendors[vendorID];
    }
    return state;
  });
  builder.addCase(getVendorByIdSuccess, (state, action) => {
    const vendor = action.payload.vendor;
    if (vendor) {
      const vendors = { ...state.vendors };
      vendors[vendor.id] = vendor;
      state.vendors = vendors;
    }
    return state;
  });
  builder.addCase(newStagedVendorLineItem, (state) => {
    if (!state.stagedVendor) return state;
    const newLi = {
      id: new Date().getTime(),
      toCreate: true,
      position: state.stagedVendor.defaultLineItems.length * 10000 + 10000,
    };
    if (state.stagedVendor.defaultLineItems) {
      state.stagedVendor.defaultLineItems.push(newLi);
    } else {
      state.stagedVendor.defaultLineItems = [newLi];
    }
    return state;
  });
  builder.addCase(deleteStagedVendorLineItem, (state, action) => {
    if (action.payload.lineItem) {
      const lineItem: VendorLineItem_Entity = { ...action.payload.lineItem };
      if (!state.stagedVendor) return state;
      const index = state.stagedVendor?.defaultLineItems.findIndex(
        (l) => l.id === lineItem.id
      );
      if (index >= 0) {
        state.stagedVendor.defaultLineItems[index].toDelete = true;
      }
    }
    return state;
  });
  builder.addCase(updateStagedVendorLineItem, (state, action) => {
    if (action.payload.lineItem) {
      const lineItem: VendorLineItem_Entity = { ...action.payload.lineItem };
      if (!state.stagedVendor) return state;
      const index = state.stagedVendor?.defaultLineItems.findIndex(
        (l) => l.id === lineItem.id
      );
      if (index >= 0) {
        state.stagedVendor.defaultLineItems[index] = {
          ...state.stagedVendor.defaultLineItems[index],
          ...lineItem,
          toUpdate: true,
        };
      }
    }
    return state;
  });
  builder.addCase(getVendorFilesByIdSuccess, (state, action) => {
    const vendorID = action.payload.vendorID;
    const files = action.payload.files;
    state.vendors[vendorID].files = files;
    return state;
  });
});
