import { Box, Button, Tooltip } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";

/**
 *
 * @returns {ReactElement} ReportsDataGrid component
 */
export function ReportsDataGrid() {
  const apiRef = useGridApiRef();
  return (
    <DataGridPro
      rows={[]}
      columns={[]}
      experimentalFeatures={{ newEditingApi: true }}
      apiRef={apiRef}
      density="compact"
      getCellClassName={(p) => (!p.isEditable ? "read-only" : "")}
      components={{
        Toolbar: CustomToolbar,
        Footer: CustomFooter,
      }}
      componentsProps={{ toolbar: {}, footer: {} }}
      initialState={{
        pinnedColumns: {
          left: ["id"],
        },
      }}
    />
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewReport = () => {
    console.log("Do someting!", dispatch);
  };

  useHotkeys(`ctrl+enter`, handleNewReport);

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <Tooltip title="ctrl + enter">
        <Button size="small" onClick={handleNewReport}>
          + New Report
        </Button>
      </Tooltip>
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  return <Box className="footer" sx={{ display: "flex" }}></Box>;
}
