import { createReducer } from "@reduxjs/toolkit";
import { File_Entity } from "../../entities/file";
import { LineItem_Entity } from "../../entities/lineItem";
import { Payable_Entity } from "../../entities/payable";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteStagedPayableLineItemFile,
  newLineItemFile,
  setSelectedLineItemID,
} from "../lineItem/lineItemActions";

import {
  deletePayableSuccess,
  deleteStagedPayableFile,
  deleteStagedPayableLineItem,
  getMyPayablesSuccess,
  getPayableByIdSuccess,
  getPayablesReadyToExportSuccess,
  getPayablesSearchMetadataSuccess,
  getPayablesSuccess,
  newStagedPayableFile,
  newStagedPayableLineItem,
  reorderStagedPayableLineItems,
  setPayableFilters,
  setPayableLoading,
  setPayableSearchOpen,
  setSelectedPayableID,
  setStagedPayable,
  setStagedPayableLoading,
  updateStagedPayable,
  updateStagedPayableLineItem,
} from "./payableActions";
import { PayableState } from "./payableState";

function initState(): PayableState {
  return {
    payables: {},
    myPayables: {},
    payablesReadyToExport: undefined,
    searchOpen: false,
    searchMetadata: undefined,
    loading: false,
    stagedPayableLoading: false,
    stagedPayable: undefined,
    selectedPayableID: undefined,
    selectedLineItemID: undefined,
  };
}

export const payableReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedLineItemID, (state, action) => {
    state.selectedLineItemID = action.payload.lineItemID;
    return state;
  });
  builder.addCase(setStagedPayable, (state, action) => {
    if (action.payload.stagedPayable) {
      state.stagedPayable = { ...action.payload.stagedPayable };
    } else {
      state.stagedPayable = undefined;
    }
    return state;
  });
  builder.addCase(updateStagedPayableLineItem, (state, action) => {
    if (action.payload.lineItem) {
      const lineItem: LineItem_Entity = { ...action.payload.lineItem };
      if (!state.stagedPayable) return state;
      const index = state.stagedPayable?.lineItems.findIndex(
        (l) => l.id === lineItem.id
      );
      if (index >= 0) {
        state.stagedPayable.lineItems[index] = {
          ...state.stagedPayable.lineItems[index],
          ...lineItem,
          toUpdate: true,
        };
      }
    }
    return state;
  });
  builder.addCase(deleteStagedPayableLineItem, (state, action) => {
    if (action.payload.lineItem) {
      const lineItem: LineItem_Entity = { ...action.payload.lineItem };
      if (!state.stagedPayable) return state;
      const index = state.stagedPayable?.lineItems.findIndex(
        (l) => l.id === lineItem.id
      );
      if (index >= 0) {
        state.stagedPayable.lineItems[index].toDelete = true;
      }
    }
    return state;
  });
  builder.addCase(deleteStagedPayableFile, (state, action) => {
    if (action.payload.file) {
      const file: File_Entity = { ...action.payload.file };
      if (!state.stagedPayable) return state;
      const index = state.stagedPayable?.files.findIndex(
        (l) => l.id === file.id
      );
      if (index >= 0) {
        state.stagedPayable.files[index].toDelete = true;
      }
    }
    return state;
  });
  builder.addCase(deleteStagedPayableLineItemFile, (state, action) => {
    if (action.payload.file) {
      if (!state.stagedPayable) return state;
      if (!state.selectedLineItemID) return state;
      const lineItemIndex = state.stagedPayable.lineItems.findIndex(
        (li) => li.id === state.selectedLineItemID
      );
      if (lineItemIndex < 0) return state;
      const lineItem = state.stagedPayable.lineItems[lineItemIndex];
      const file: File_Entity = { ...action.payload.file };

      const fileIndex = (lineItem.files ?? []).findIndex(
        (l) => l.id === file.id
      );
      if (fileIndex < 0) return state;
      state.stagedPayable.lineItems[lineItemIndex].toUpdate = true;
      state.stagedPayable.lineItems[lineItemIndex].files![fileIndex].toDelete =
        true;
    }
    return state;
  });
  builder.addCase(setPayableLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setStagedPayableLoading, (state, action) => {
    state.stagedPayableLoading = action.payload.loading;
    return state;
  });
  builder.addCase(setPayableFilters, (state, action) => {
    state.filters = action.payload.filters;
    return state;
  });
  builder.addCase(setPayableSearchOpen, (state, action) => {
    state.searchOpen = action.payload.searchOpen;
    return state;
  });
  builder.addCase(newStagedPayableLineItem, (state, action) => {
    if (!state.stagedPayable) return state;
    const lastLineItem = state.stagedPayable.lineItems?.length
      ? state.stagedPayable.lineItems[state.stagedPayable.lineItems.length - 1]
      : undefined;
    const newLi = action.payload.lineItem ?? {
      id: new Date().getTime(),
      amount: 0,
      classID: lastLineItem?.classID,
      glAccountID: lastLineItem?.glAccountID,
      position: (state.stagedPayable.lineItems?.length ?? 0) * 10000 + 10000,
      toCreate: true,
    };
    if (state.stagedPayable.lineItems) {
      state.stagedPayable.lineItems.push(newLi);
    } else {
      state.stagedPayable.lineItems = [newLi];
    }
    return state;
  });
  builder.addCase(newLineItemFile, (state, action) => {
    if (!state.stagedPayable) return state;
    if (!state.selectedLineItemID) return state;
    const index = state.stagedPayable.lineItems.findIndex(
      (li) => li.id === state.selectedLineItemID
    );
    if (index < 0) return state;
    state.stagedPayable.lineItems[index].toUpdate = true;
    if (state.stagedPayable.lineItems[index].files) {
      state.stagedPayable.lineItems[index].files!.push(action.payload.file);
    } else {
      state.stagedPayable.lineItems[index].files = [action.payload.file];
    }
    return state;
  });
  builder.addCase(newStagedPayableFile, (state, action) => {
    if (!state.stagedPayable) return state;
    if (state.stagedPayable.files) {
      state.stagedPayable.files.push(action.payload.file);
    } else {
      state.stagedPayable.files = [action.payload.file];
    }
    return state;
  });
  builder.addCase(reorderStagedPayableLineItems, (state, action) => {
    const { oldIndex, targetIndex } = action.payload;
    if (!state.stagedPayable) return state;
    const rowsClone = [...state.stagedPayable.lineItems];
    const row = rowsClone.splice(oldIndex, 1)[0];
    rowsClone.splice(targetIndex, 0, row);

    state.stagedPayable.lineItems = rowsClone;

    return state;
  });
  builder.addCase(updateStagedPayable, (state, action) => {
    if (action.payload.stagedPayable) {
      const update: Partial<Payable_Entity> = action.payload.stagedPayable;
      if (!state.stagedPayable) return state;
      state.stagedPayable = {
        ...state.stagedPayable,
        ...update,
        toUpdate: true,
      };
    }
    return state;
  });
  builder.addCase(setSelectedPayableID, (state, action) => {
    state.selectedPayableID = action.payload.payableID;
    return state;
  });
  builder.addCase(getPayablesSuccess, (state, action) => {
    const payables = action.payload.payables;
    state.payables = arrayToMap<Payable_Entity>(payables);
    return state;
  });
  builder.addCase(getPayablesReadyToExportSuccess, (state, action) => {
    const payables = action.payload.payables;
    if (payables) {
      state.payablesReadyToExport = arrayToMap<Payable_Entity>(payables);
    } else {
      state.payablesReadyToExport = undefined;
    }
    return state;
  });
  builder.addCase(getMyPayablesSuccess, (state, action) => {
    const payables = action.payload.payables;
    state.myPayables = arrayToMap<Payable_Entity>(payables);
    return state;
  });
  builder.addCase(getPayablesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.payablesSearchMetadata;
    return state;
  });
  builder.addCase(deletePayableSuccess, (state, action) => {
    const payableID = action.payload.id;
    if (state.payables[payableID]) {
      delete state.payables[payableID];
    }
    return state;
  });
  builder.addCase(getPayableByIdSuccess, (state, action) => {
    const payable = action.payload.payable;
    if (payable) {
      const payables = { ...state.payables };
      payables[payable.id] = payable;
      state.payables = payables;
    }
    return state;
  });
});
