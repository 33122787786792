import {
  Button,
  IconButton,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuItem,
  Typography,
} from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import { MouseEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { File as FileClass, File_Entity } from "../../entities/file";
import {
  deleteStagedPayableLineItemFile,
  newLineItemFile,
  setSelectedLineItemID,
} from "../../redux/lineItem/lineItemActions";
import {
  selectedLineItemIDSelector,
  stagedPayableSelector,
} from "../../redux/payable/payableSelector";

/**
 *
 * @returns {ReactElement} LineItemFiles page
 */
export function LineItemFiles() {
  const dispatch = useDispatch();
  const selectedLineItemID = useAppSelector(selectedLineItemIDSelector);
  const stagedPayable = useAppSelector(stagedPayableSelector);

  const lineItem = stagedPayable?.lineItems.find(
    (li) => li.id === selectedLineItemID
  );
  const close = () => {
    dispatch(setSelectedLineItemID());
  };

  const upload = (
    <Box sx={{ flexShrink: 0 }}>
      <NerdFileUpload
        dropZoneLabel="Attach to Line Item"
        uploadFile={(e: File) => {
          const f: File_Entity = {
            id: new Date().getTime(),
            modelID: lineItem?.id,
            modelType: "lineItem",
            name: e.name,
            blob: URL.createObjectURL(e),
            file: e,
            toCreate: true,
          };
          dispatch(newLineItemFile(f));
        }}
        hideFilesList
        dense
      />
    </Box>
  );

  return (
    <Dialog
      onClose={close}
      open={selectedLineItemID != undefined}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Attachments for Line Item</DialogTitle>
      <DialogContent>
        {upload}
        <Box sx={{ display: "flex", gap: 1 }}>
          {lineItem?.files.length === 0
            ? "No files attached to this line item."
            : ""}
          {lineItem?.files
            ?.filter((s) => !s.toDelete)
            .map((f) => (
              <LineItemFile file={f} key={f.id} />
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function LineItemFile({ file }: { file: FileClass }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick: MouseEventHandler<HTMLAnchorElement> = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(deleteStagedPayableLineItemFile(file));
    handleClose();
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <Tooltip title={file.name}>
        <Button
          color={"neutral"}
          variant={"soft"}
          size="sm"
          sx={{ minWidth: 100, maxWidth: 200 }}
          onClick={() => window.open(file.previewURL(), "_blank")}
          endDecorator={
            <IconButton
              size="sm"
              variant="outlined"
              onClick={handleClick}
              color={"neutral"}
            >
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </IconButton>
          }
        >
          <Typography noWrap level="body2">
            {file.toDelete ? "[d]" : ""}
            {file.name}
          </Typography>
        </Button>
      </Tooltip>
      <Menu
        id="positioned-demo-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="positioned-demo-button"
        placement="bottom-end"
        size="sm"
      >
        <MenuItem
          onClick={() => {
            window.open(file.previewURL(), "_blank");
            handleClose();
          }}
        >
          <ListItemDecorator>
            <i className="fa-regular fa-arrow-up-right-from-square"></i>
          </ListItemDecorator>{" "}
          Open in new tab
        </MenuItem>
        <ListDivider />
        <MenuItem onClick={handleDelete} variant="soft" color="danger">
          <ListItemDecorator sx={{ color: "inherit" }}>
            <i className="fa-sharp fa-solid fa-trash"></i>
          </ListItemDecorator>{" "}
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
}
