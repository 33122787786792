import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

export default function SlidingDialog({
  children,
  onClose,
  open,
}: {
  children: ReactElement | undefined | number;
  onClose: () => void;
  open: boolean;
}) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen
      onClose={() => (onClose ? onClose() : navigate(-1))}
      aria-describedby="alert-dialog-slide-description"
    >
      {children}
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
