import SearchPreset_Entity from "./searchPreset";
import { Filters } from "@nerdjs/nerd-core";
import { User, User_Entity } from "@nerdjs/account-kit";

export default class SearchPreset extends SearchPreset_Entity {
  static fromList(searchPresetsJSON: unknown): Array<SearchPreset> {
    const searchPresets: SearchPreset[] = [];
    if (searchPresetsJSON)
      Array.isArray(searchPresetsJSON) &&
        searchPresetsJSON.forEach((searchPresetJSON) => {
          searchPresets.push(new SearchPreset(searchPresetJSON));
        });
    return searchPresets;
  }

  getFilters(): Filters | undefined {
    try {
      const filters: Filters = JSON.parse(this.filter);
      return filters;
    } catch (error) {
      return undefined;
    }
    return undefined;
  }

  isMine(me: User_Entity | undefined): boolean {
    return this.userUUID === me?.uuid;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
