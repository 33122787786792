import { Option, Select } from "@mui/joy";
import { Filters } from "@nerdjs/nerd-core";
import { NerdAdvancedSearch } from "@nerdjs/nerd-ui";
import { AdvancedSearchDefinition } from "@nerdjs/nerd-ui/dist/nerdAdvancedSearch/types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import { transactionTypes } from "../../constants";
import { useQuery } from "../../nerdPaginationFooter/utils";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  getMyPayables,
  getMyPayablesByFilter,
  getPayables,
  getPayablesByFilter,
  getPayablesSearchMetadata,
  setPayableFilters,
  setPayableSearchOpen,
} from "../../redux/payable/payableActions";
import {
  payableFiltersSelector,
  payableSearchMetadataSelector,
  payableSearchOpenSelector,
} from "../../redux/payable/payableSelector";
import { CompanyAutocomplete } from "../company/companyAutocomplete";
import { PriorityLevelAutocomplete } from "../priorityLevel/priorityLevelAutocomplete";
import { UserAutocomplete } from "../user/userAutocomplete";
import { VendorAutocomplete } from "../vendor/vendorAutocomplete";
/**
 *
 * @returns {ReactElement} PayableSearch page
 */
export function PayableSearch({ my = false }: { my: boolean }) {
  const dispatch = useDispatch();
  const searchOpen = useAppSelector(payableSearchOpenSelector);
  const query = useQuery();
  const filtersString = query.get("filters");
  const filters: Filters = filtersString ? JSON.parse(filtersString) : [];
  const filtersInRedux = useAppSelector(payableFiltersSelector);
  const searchMetadata = useAppSelector(payableSearchMetadataSelector);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPayablesSearchMetadata());

    //We restore the filters from redux if they exists
    if (filtersInRedux && filters.length === 0) {
      query.set("filters", JSON.stringify(filtersInRedux));
      navigate({ search: query.toString() });
    }
  }, []);

  useEffect(() => {
    // we save the filters in redux whenever they change
    if (filtersString) {
      const filters = JSON.parse(filtersString);
      dispatch(setPayableFilters(filters));
    } else {
      dispatch(setPayableFilters());
    }
  }, [filtersString]);

  const definition: AdvancedSearchDefinition[] = [
    {
      id: "referenceNumber",
      name: "payables.referenceNumber",
      label: "Reference Number",
      type: "string",
      faIcon: "fa-solid fa-tag",
    },
    {
      id: "transactionType",
      name: "payables.transactionType",
      label: "Transaction Type",
      type: "int",
      getFaIcon: (value) =>
        transactionTypes.find((t) => t.id === value)?.faClass ?? "",
      renderInput: (value, onChange) => (
        <TransactionTypeSelect
          value={value as unknown as number}
          onChange={(e) => onChange(e ?? "")}
        />
      ),
    },
    {
      id: "createdByUUID",
      name: "payables.createdByUUID",
      label: "Created By",
      type: "string",
      faIcon: "fa-solid fa-user",
      renderInput: (value, onChange) => (
        <UserAutocomplete
          userUUID={value as unknown as string}
          onChange={(e) => onChange(e?.uuid)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "assignedToUUID",
      name: "payables.assignedToUUID",
      label: "Assigned to",
      type: "string",
      faIcon: "fa-solid fa-user-check",
      renderInput: (value, onChange) => (
        <UserAutocomplete
          userUUID={value as unknown as string}
          onChange={(e) => onChange(e?.uuid)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "companyID",
      name: "payables.companyID",
      label: "Company",
      type: "string",
      faIcon: "fa-solid fa-building",
      renderInput: (value, onChange) => (
        <CompanyAutocomplete
          companyID={value as unknown as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "Creation date",
      name: "payables.date",
      label: "Date",
      type: "date",
      faIcon: "fa-solid fa-calendar-days",
    },
    {
      id: "dueDate",
      name: "payables.dueDate",
      label: "Due Date",
      type: "date",
      faIcon: "fa-sharp fa-solid fa-alarm-clock",
    },
    {
      id: "approved",
      name: "payables.approved",
      label: "Approved",
      type: "bool",
      faIcon: "fa-solid fa-thumbs-up",
      getFaIcon: (v) =>
        v ? "fa-solid fa-thumbs-up" : "fa-solid fa-thumbs-down",
    },
    {
      id: "locked",
      name: "payables.locked",
      label: "Locked",
      type: "bool",
      faIcon: "fa-solid fa-lock",
      getFaIcon: (v) => (v ? "fa-solid fa-lock" : "fa-solid fa-lock-open"),
    },
    {
      id: "lockable",
      name: "payables.lockable",
      label: "Lockable",
      type: "bool",
      faIcon: "fa-solid fa-key",
    },
    {
      id: "priorityLevelID",
      name: "payables.priorityLevelID",
      label: "Priority",
      type: "string",
      faIcon: "fa-solid fa-flag",
      renderInput: (value, onChange) => (
        <PriorityLevelAutocomplete
          priorityLevelID={value as unknown as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "vendorID",
      name: "payables.vendorID",
      label: "Vendor",
      type: "string",
      faIcon: "fa-solid fa-shop",
      renderInput: (value, onChange) => (
        <VendorAutocomplete
          vendorID={value as unknown as number}
          onChange={(e) => onChange(e?.id)}
          autoFocus
          variant="inputBase"
          size="small"
        />
      ),
    },
    {
      id: "exportID",
      name: "payables.exportID",
      label: "Export #",
      type: "int",
      faIcon: "fa-solid fa-file-export",
    },
  ];

  return (
    <NerdAdvancedSearch
      definitions={definition}
      searchMetadata={searchMetadata}
      open={searchOpen}
      onNewSearchPreset={() => dispatch(setFormOpen(true, "searchPreset"))}
      setOpen={(o) => dispatch(setPayableSearchOpen(o))}
      onSearch={(q) => {
        if (q) {
          if (my) {
            dispatch(getMyPayablesByFilter(q));
          } else {
            dispatch(getPayablesByFilter(q));
          }
        } else {
          if (my) {
            dispatch(getMyPayables());
          } else {
            dispatch(getPayables());
          }
        }
      }}
    />
  );
}

function TransactionTypeSelect({
  value,
  onChange,
}: {
  value: number;
  onChange: (i: number | null) => void;
}) {
  return (
    <Select
      onChange={(e, v) => onChange(v)}
      value={value}
      placeholder="Transaction type"
    >
      {transactionTypes.map((tt) => (
        <Option key={tt.id} value={tt.id}>
          {tt.name}
        </Option>
      ))}
    </Select>
  );
}
