import { Box, CssBaseline, Grid, useMediaQuery } from "@mui/material";
import { initNetworkAction } from "@nerdjs/nerd-network";
import {
  clearNotification,
  NerdNotifier,
  NerdAppBar,
  NerdFileUploadWrapper,
  NerdConfirmProvider,
  NerdVersionChecker,
} from "@nerdjs/nerd-ui";
import { ReactElement, useEffect } from "react";
import { CircularProgress, useColorScheme, useTheme } from "@mui/joy";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { appBarConfig } from "../config/appBarConfig";
import { useAppDispatch, useAppSelector } from "../hooks";
import { MainMenu } from "../mainMenu/mainMenu";
import {
  useGetCurrentUserQuery,
  useSignOutMutation,
} from "@nerdjs/account-kit";
import { RouterConfig } from "../config/routerConfig";
import { Shortcut } from "../../hooks/shortcut/shortcut";
import Openner from "../openner/openner";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const { isSuccess } = useSignOutMutation()[1];
  const { mode } = useColorScheme();

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  const { isFetching } = useGetCurrentUserQuery();

  useEffect(() => {
    //If signout success, refresh window to reset redux state
    if (isSuccess) {
      window.location.replace(RouterConfig.root);
    }
  }, [isSuccess]);

  return (
    <NotifierWrapper>
      <NerdFileUploadWrapper>
        <Box
          sx={{
            backgroundImage: `url("${AppConfig.app.rootBackground}") `,
            display: "flex",
            flex: 1,
            flexDirection: md ? "column" : "row",
          }}
        >
          <CssBaseline />
          {isFetching ? (
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <NerdAppBar {...appBarConfig(mode, md)} />
              <MainMenu />
              <Shortcut />
              <Openner />
              <NerdVersionChecker
                versionCheckerEndpoint={AppConfig.api.versionCheckerEndpoint}
              />
              <NerdConfirmProvider>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    marginTop: "48px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <AppRouter />
                </Box>
              </NerdConfirmProvider>
            </>
          )}
        </Box>
      </NerdFileUploadWrapper>
    </NotifierWrapper>
  );
}

const NotifierWrapper = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
