import { User, User_Entity } from "@nerdjs/account-kit";

/**
 * ApExport_Entity type guard.
 *
 * @param {any} apExportJson ApExport object from API
 * @returns {boolean} Return true if type is ApExport_Entity
 */
function validator(apExportJson: unknown): apExportJson is ApExport_Entity {
  if (typeof apExportJson === "object" && apExportJson != null) {
    ApExport_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(apExportJson).includes(element))
        console.log(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class ApExport_Entity {
  static requiredFields = ["id"];

  constructor(exportJson: unknown) {
    if (validator(exportJson)) {
      this.id = exportJson.id;
      this.name = exportJson.name;
      this.companyID = exportJson.companyID;
      this.createdByUUID = exportJson.createdByUUID;
      this.createdBy = exportJson.createdBy;
      this.createdAt = exportJson.createdAt;
      this.updatedAt = exportJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${ApExport_Entity.name}`
      );
  }

  id: number;
  name: string;
  companyID: number;
  createdBy?: User_Entity;
  createdByUUID: string;
  createdAt: string;
  updatedAt: string;
}
