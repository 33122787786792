/**
 * SearchPreset_Entity type guard.
 *
 * @param {any} searchPresetJson SearchPreset object from API
 * @returns {boolean} Return true if type is SearchPreset_Entity
 */
function validator(
  searchPresetJson: unknown
): searchPresetJson is SearchPreset_Entity {
  if (typeof searchPresetJson === "object" && searchPresetJson != null) {
    SearchPreset_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(searchPresetJson).includes(element))
        console.log(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class SearchPreset_Entity {
  static requiredFields = ["id"];

  constructor(searchPresetJson: unknown) {
    if (validator(searchPresetJson)) {
      this.id = searchPresetJson.id;
      this.color = searchPresetJson.color;
      this.filter = searchPresetJson.filter;
      this.global = searchPresetJson.global;
      this.keyword = searchPresetJson.keyword;
      this.name = searchPresetJson.name;
      this.order = searchPresetJson.order;
      this.userUUID = searchPresetJson.userUUID;
      this.createdAt = searchPresetJson.createdAt;
      this.updatedAt = searchPresetJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${SearchPreset_Entity.name}`
      );
  }

  id: number;
  color: string;
  filter: string;
  global: boolean;
  keyword: string;
  name: string;
  order: number;
  userUUID: string;
  createdAt: string;
  updatedAt: string;
}
