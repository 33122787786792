import { User } from "@nerdjs/account-kit";
import { getEmptyJournalEntry } from "../../helpers/accountingHelpers";
import { File } from "../file";
import { JournalEntryItem } from "../journalEntryItem";
import JournalEntry_Entity from "./journalEntry";
export default class JournalEntry extends JournalEntry_Entity {
  journalEntryItems: Array<JournalEntryItem>;
  files: Array<File>;
  createdBy?: User;

  constructor(journalEntryJson: JournalEntry_Entity) {
    super(journalEntryJson);
    if (journalEntryJson.createdBy)
      this.createdBy = new User(journalEntryJson.createdBy);
    if (journalEntryJson.files) {
      this.files = File.fromList(journalEntryJson.files);
    } else {
      {
        this.files = [];
      }
    }
    if (journalEntryJson.journalEntryItems) {
      this.journalEntryItems = JournalEntryItem.fromList(
        journalEntryJson.journalEntryItems
      );
    } else {
      this.journalEntryItems = [];
    }
  }

  static fromList(journalEntrysJSON: unknown): Array<JournalEntry> {
    const journalEntrys: JournalEntry[] = [];
    if (journalEntrysJSON)
      Array.isArray(journalEntrysJSON) &&
        journalEntrysJSON.forEach((journalEntryJSON) => {
          journalEntrys.push(new JournalEntry(journalEntryJSON));
        });
    return journalEntrys;
  }

  body(): Partial<JournalEntry_Entity> {
    const body: Partial<JournalEntry_Entity> = {};
    if (this.date) body.date = this.date;
    if (this.companyID) body.companyID = this.companyID;
    if (this.referenceNumber) body.referenceNumber = this.referenceNumber;
    if (this.memo) body.memo = this.memo;
    if (this.createdByUUID) body.createdByUUID = this.createdByUUID;
    if (this.glTransactionID) body.glTransactionID = this.glTransactionID;
    if (this.locked) body.locked = this.locked;
    if (this.exportID) body.exportID = this.exportID;

    return body;
  }

  duplicate(): JournalEntry_Entity {
    const ret = getEmptyJournalEntry();
    ret.companyName = this.companyName;
    ret.companyID = this.companyID;
    ret.referenceNumber = this.referenceNumber;
    ret.memo = this.memo;
    ret.createdByUUID = this.createdByUUID;
    if (this.createdBy) ret.createdBy = { ...this.createdBy };
    ret.glTransactionID = this.glTransactionID;
    ret.journalEntryItems = [...this.journalEntryItems].sort(
      (a, b) => (a?.position ?? 0) - (b?.position ?? 0)
    );

    for (const key in ret.journalEntryItems) {
      if (Object.prototype.hasOwnProperty.call(ret.journalEntryItems, key)) {
        ret.journalEntryItems[key] = {
          ...ret.journalEntryItems[key],
          position: 10000 + Number(key) * 10000,
        };
        delete ret.journalEntryItems[key].journalEntryID;
        ret.journalEntryItems[key].toCreate = true;
      }
    }

    return ret;
  }

  reverse(): JournalEntry_Entity {
    const ret = this.duplicate();

    for (const key in ret.journalEntryItems) {
      if (Object.prototype.hasOwnProperty.call(ret.journalEntryItems, key)) {
        const credit = ret.journalEntryItems[key].credit;
        const debit = ret.journalEntryItems[key].debit;
        ret.journalEntryItems[key] = {
          ...ret.journalEntryItems[key],
          position: 10000 + Number(key) * 10000,
        };
        ret.journalEntryItems[key].credit = debit;
        ret.journalEntryItems[key].debit = credit;
      }
    }

    return ret;
  }

  canClose() {
    if (this.toCreate) return false;
    if (this.toUpdate) return false;

    if (this.journalEntryItemsToCreate().length) return false;
    if (this.journalEntryItemsToDelete().length) return false;
    if (this.journalEntryItemsToUpdate().length) return false;

    return true;
  }

  filesToCreate() {
    return this.files.filter((f) => f.toCreate && !f.toDelete);
  }

  filesToDelete() {
    return this.files.filter((f) => f.toDelete && !f.toCreate);
  }

  journalEntryItemsToCreate() {
    return this.journalEntryItems.filter((li) => li.toCreate && !li.toDelete);
  }

  journalEntryItemsToDelete() {
    return this.journalEntryItems.filter((li) => li.toDelete && !li.toCreate);
  }

  journalEntryItemsToUpdate() {
    return this.journalEntryItems.filter(
      (li) => li.toUpdate && !li.toDelete && !li.toCreate
    );
  }

  credits(): number {
    let credits = 0;
    this.journalEntryItems?.forEach((li) => (credits += li.credit ?? 0));
    return Math.round(credits * 100) / 100;
  }

  debits(): number {
    let debits = 0;
    this.journalEntryItems?.forEach((li) => (debits += li.debit ?? 0));
    return Math.round(debits * 100) / 100;
  }

  creditsMatchDebits(): boolean {
    const credits = this.credits();
    const debits = this.debits();
    return credits === debits;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
