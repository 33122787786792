import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { SearchPreset } from "../../entities/searchPreset";

export const setSelectedSearchPresetID = createAction(
  "setSelectedSearchPresetID",
  function prepare(searchPresetID?: number) {
    return {
      payload: {
        searchPresetID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getSearchPresets = createAction(
  "unity/searchPreset/getSearchPresets",
  function prepare() {
    const request = new GetAction(`/unity/searchPresets`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const searchPresets = SearchPreset.fromList(json);
      dispatch(getSearchPresetsSuccess(searchPresets));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getSearchPresetsSuccess = createAction(
  "unity/searchPreset/getSearchPresetSuccess",
  function prepare(searchPresets: SearchPreset[]) {
    return {
      payload: {
        searchPresets,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getSearchPresetsByFilter = createAction(
  "unity/searchPreset/getSearchPresetsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/unity/searchPresets`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const searchPresets = SearchPreset.fromList(json);
      dispatch(getSearchPresetsByFilterSuccess(searchPresets));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getSearchPresetsByFilterSuccess = createAction(
  "unity/searchPreset/getSearchPresetsByFilterSuccess",
  function prepare(searchPresets: SearchPreset[]) {
    return {
      payload: {
        searchPresets,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getSearchPresetById = createAction(
  "unity/searchPreset/getById",
  function prepare(id: number) {
    const request = new GetAction(`/unity/searchPresets/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const searchPreset = new SearchPreset(json);
      dispatch(getSearchPresetByIdSuccess(searchPreset));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getSearchPresetByIdSuccess = createAction(
  "unity/searchPreset/getSearchPresetByIdSuccess",
  function prepare(searchPreset?: SearchPreset) {
    return {
      payload: {
        searchPreset,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteSearchPreset = createAction(
  "unity/removeSearchPresetById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/unity/searchPresets/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteSearchPresetSuccess = createAction(
  "unity/searchPresets/deleteSearchPresetSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateSearchPreset = createAction(
  "unity/searchPresets/updateSearchPreset",
  function prepare(id: number, body: Partial<SearchPreset>) {
    const request = new PutAction(`/unity/searchPresets/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateSearchPresetSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateSearchPresetSuccess = createAction(
  "unity/searchPresets/updateSearchPresetSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createSearchPreset = createAction(
  "unity/searchPresets/createSearchPreset",
  function prepare(body: Partial<SearchPreset>) {
    const request = new PostAction(`/unity/searchPresets`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const searchPreset = new SearchPreset(json);
      dispatch(createSearchPresetSuccess(searchPreset));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createSearchPresetSuccess = createAction(
  "unity/searchPresets/createSearchPresetSuccess",
  function prepare(searchPreset: SearchPreset) {
    return {
      payload: {
        searchPreset,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getSearchPresetsSearchMetadata = createAction(
  "unity/getSearchPresetsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/unity/searchPresets/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getSearchPresetsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getSearchPresetsSearchMetadataSuccess = createAction(
  "unity/getSearchPresetsSearchMetadataSuccess",
  function prepare(searchPresetsSearchMetadata: unknown) {
    return {
      payload: {
        searchPresetsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
