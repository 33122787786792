export const font = "futura-pt-condensed, sans-serif;";

export const transactionTypes = [
  {
    id: 0,
    name: "Bill",
    faClass: "fa-thin fa-file-invoice",
  },
  {
    id: 1,
    name: "Credit",
    faClass: "fa-thin fa-credit-card",
  },
  {
    id: 2,
    name: "Check",
    faClass: "fa-thin fa-money-check-dollar-pen",
  },
  {
    id: 3,
    name: "Journal Entry",
    faClass: "fa-thin fa-newspaper",
  },
];

export const kindOfExportablePayables = [
  {
    id: 0,
    name: "Mine, only",
  },
  {
    id: 1,
    name: "See all",
  },
];
