import { User } from "@nerdjs/account-kit";
import { createReducer } from "@reduxjs/toolkit";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteUserSuccess,
  getUserByIdSuccess,
  getUsersSearchMetadataSuccess,
  getUsersSuccess,
  setSelectedUserID,
} from "./userActions";
import { UserState } from "./userState";

function initState(): UserState {
  return {
    users: {},
    searchMetadata: undefined,
    selectedUserID: undefined,
  };
}

export const userReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedUserID, (state, action) => {
    state.selectedUserID = action.payload.userID;
    return state;
  });
  builder.addCase(getUsersSuccess, (state, action) => {
    const users = action.payload.users;
    state.users = arrayToMap<User>(users);
    return state;
  });
  builder.addCase(getUsersSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.usersSearchMetadata;
    return state;
  });
  builder.addCase(deleteUserSuccess, (state, action) => {
    const userID = action.payload.id;
    if (state.users[userID]) {
      delete state.users[userID];
    }
    return state;
  });
  builder.addCase(getUserByIdSuccess, (state, action) => {
    const user = action.payload.user;
    if (user) {
      const users = { ...state.users };
      users[user.id] = user;
      state.users = users;
    }
    return state;
  });
});
