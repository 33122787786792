import { Button, Chip, ChipDelete, IconButton } from "@mui/joy";
import { Dialog, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { importVendors } from "../../redux/vendor/vendorActions";
import { CompanyAutocomplete } from "../company/companyAutocomplete";

/**
 *
 * @returns {ReactElement} PayableExport page
 */
export function VendorsImport() {
  const dispatch = useDispatch();
  const open = useAppSelector(formOpenSelector("importVendors"));

  const [companyID, setCompanyID] = useState<number | undefined>();
  const [file, setFile] = useState<File | undefined>();

  const close = () => {
    dispatch(setFormOpen(false, "importVendors"));
  };

  const importNow = () => {
    dispatch(importVendors(companyID!, file!));
    close();
  };

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          position: "relative",
          justifyContent: "center",
          alignItems: "stretch",
          flexDirection: "column",
        }}
      >
        <Tooltip title="Cancel Import">
          <IconButton
            color="neutral"
            size="lg"
            onClick={close}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Tooltip>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: 600,
            margin: "auto",
          }}
        >
          <i style={{ fontSize: 32 }} className="fa-regular fa-file-export"></i>
          <Box>
            <Typography variant="h4">Before you import Vendors...</Typography>
            <Typography>
              Please select the company you wish to import vendors:
            </Typography>
          </Box>
          <CompanyAutocomplete
            companyID={companyID}
            size="small"
            onChange={(e) => (e ? setCompanyID(e.id) : setCompanyID(undefined))}
          />
          <Box>
            <Typography>Then select the file:</Typography>
            <Typography variant="caption">
              Or drag & drop the file in this window.
            </Typography>
          </Box>
          <NerdFileUpload
            dropZoneLabel="File upload example"
            uploadFile={(e: File) => {
              setFile(e);
            }}
            hideFilesList
          />
          {file ? (
            <Box>
              <Chip
                color="neutral"
                variant="soft"
                endDecorator={
                  <ChipDelete
                    variant="plain"
                    onClick={() => setFile(undefined)}
                  />
                }
              >
                {file.name}
              </Chip>
            </Box>
          ) : (
            []
          )}

          <Button disabled={!companyID || !file} onClick={importNow}>
            Import Vendors
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
