import { withNetworkCompletionDispatch } from "@nerdjs/nerd-network";
import { NavigateFunction } from "react-router-dom";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { RouterConfig } from "../atoms/config/routerConfig";
import { Vendor } from "../entities/vendor";
import { setFormOpen } from "./appStatus/appStatusActions";
import { AppState, store } from "./store";
import {
  createVendor,
  createVendorSuccess,
  getVendorById,
  setSelectedVendorID,
  setStagedVendor,
  setStagedVendorLoading,
  stagedVendorCreateRequest,
  stagedVendorUpdateRequest,
  updateVendor,
  uploadVendorFile,
} from "./vendor/vendorActions";
import {
  createVendorLineItem,
  deleteVendorLineItem,
  updateVendorLineItem,
} from "./vendorLineItem/vendorLineItemActions";
let uuids: string[] = [];
let navigate: NavigateFunction;
let callback: () => void;
let creating = false;
let interval: NodeJS.Timeout;
let close: boolean;

export const stagedVendorMiddleware: Middleware =
  (_api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const state: AppState = store.getState();
    const ncs = state.networkState.networkCompletionStack;
    const stagedVendor = state.vendorState.stagedVendor;

    if (action.type == setFormOpen) {
      const formID = action.payload.formID;
      const open = action.payload.open;
      if (formID === "vendor" && !open) {
        store.dispatch(setStagedVendorLoading(false));
      }
    }

    const closeModal = () => {
      store.dispatch(setSelectedVendorID());
      store.dispatch(setStagedVendor());
      store.dispatch(setFormOpen(false, "vendor"));
      if (navigate)
        navigate({
          pathname: RouterConfig.vendors,
          search: window.location.search,
        });
    };

    const isCompleted = () => {
      if (uuids.length) {
        let completed = true;
        uuids.forEach((uuid) => {
          if (ncs.some((e) => e[uuid])) {
            completed = false;
          }
        });
        if (completed && !creating) {
          uuids = [];
          clearInterval(interval);
          if (callback) {
            callback();
          } else {
            store.dispatch(setStagedVendorLoading(false));
          }
          if (close) {
            close = false;
            closeModal();
            if (stagedVendor) store.dispatch(getVendorById(stagedVendor.id));
          } else if (stagedVendor && !callback) {
            store.dispatch(getVendorById(stagedVendor.id));
          }
        }
      }
    };

    if (action.type == "ApiErrorAction") {
      uuids = [];
      if (interval) clearInterval(interval);
      store.dispatch(setStagedVendorLoading(false));
      return next(action);
    }

    if (action.type == stagedVendorCreateRequest && stagedVendor) {
      navigate = action.payload.navigate;
      close = action.payload.close;
      creating = true;
      interval = setInterval(isCompleted, 1000);
      store.dispatch(setStagedVendorLoading(true));
      const stagedVendorClass = new Vendor(stagedVendor);
      uuids.push(
        withNetworkCompletionDispatch(
          store.dispatch,
          createVendor(stagedVendorClass.body())
        )
      );
    }

    if (action.type == stagedVendorUpdateRequest && stagedVendor) {
      navigate = action.payload.navigate;
      close = action.payload.close;
      callback = action.payload.callback;
      interval = setInterval(isCompleted, 1000);
      store.dispatch(setStagedVendorLoading(true));
      const body = action.payload.body;
      const stagedVendorClass = new Vendor(stagedVendor);
      const filesToUpload = stagedVendorClass.filesToUpload();

      filesToUpload?.forEach((file) => {
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            uploadVendorFile(stagedVendorClass.id, file.file!)
          )
        );
      });

      if (stagedVendorClass.toUpdate || body)
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateVendor(stagedVendorClass.id, {
              ...stagedVendorClass.body(),
              ...body,
            })
          )
        );

      const liToCreate = stagedVendorClass.lineItemsToCreate();
      const liToDelete = stagedVendorClass.lineItemsToDelete();
      const liToUpdate = stagedVendorClass.lineItemsToUpdate();

      if (stagedVendorClass.toUpdate) {
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateVendor(stagedVendor.id, stagedVendorClass.body())
          )
        );
      }

      liToCreate?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            createVendorLineItem(stagedVendor?.id, li.body())
          )
        )
      );

      liToUpdate?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateVendorLineItem(li?.id, li.body())
          )
        )
      );

      liToDelete?.forEach((li) => {
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            deleteVendorLineItem(li.id)
          )
        );
      });

      if (uuids.length === 0) closeModal();
    }

    if (action.type == createVendorSuccess && stagedVendor) {
      // we need to create the line items & upload all the files;
      const vendor = action.payload.vendor;
      const stagedVendorClass = new Vendor(stagedVendor);
      const lineItemsToCreate = stagedVendorClass.lineItemsToCreate();
      lineItemsToCreate?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            createVendorLineItem(vendor.id, {
              ...li,
              vendorID: vendor.id,
            })
          )
        )
      );
      creating = false;
    }

    return next(action);
  };
