import { withNetworkCompletionDispatch } from "@nerdjs/nerd-network";
import { NavigateFunction } from "react-router-dom";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { RouterConfig } from "../atoms/config/routerConfig";
import { JournalEntry } from "../entities/journalEntry";
import { setFormOpen } from "./appStatus/appStatusActions";
import {
  createJournalEntry,
  createJournalEntrySuccess,
  deleteJournalEntry,
  deleteJournalEntryfile,
  getJournalEntriesByFilter,
  getJournalEntryById,
  lockJournalEntrySuccess,
  setSelectedJournalEntryID,
  setStagedJournalEntry,
  setStagedJournalEntryLoading,
  stagedJournalEntryCreateRequest,
  stagedJournalEntryUpdateRequest,
  updateJournalEntry,
  uploadJournalEntryfile,
} from "./journalEntry/journalEntryActions";
import { AppState, store } from "./store";
import {
  createJournalEntryItem,
  deleteJournalEntryItem,
  updateJournalEntryItem,
} from "./journalEntryItem/journalEntryItemActions";
let uuids: string[] = [];
let navigate: NavigateFunction;
let callback: () => void;
let creating = false;
let interval: NodeJS.Timeout;
let close: boolean;

export const stagedJournalEntryMiddleware: Middleware =
  (_api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const state: AppState = store.getState();
    const ncs = state.networkState.networkCompletionStack;
    const stagedJournalEntry = state.journalEntryState.stagedJournalEntry;

    if (action.type == setFormOpen) {
      const formID = action.payload.formID;
      const open = action.payload.open;
      if (formID === "journalEntry" && !open) {
        store.dispatch(setStagedJournalEntryLoading(false));
      }
    }

    const closeModal = () => {
      store.dispatch(setSelectedJournalEntryID());
      store.dispatch(setStagedJournalEntry());
      store.dispatch(setFormOpen(false, "journalEntry"));
      if (navigate)
        navigate({
          pathname: RouterConfig.journalEntries,
          search: window.location.search,
        });
    };

    if (action.type == deleteJournalEntry) {
      closeModal();
      setTimeout(() => {
        const search = new URLSearchParams(window.location.search);
        store.dispatch(
          getJournalEntriesByFilter({
            filters: search.get("filters")
              ? JSON.stringify(JSON.parse(search.get("filters") ?? "[]"))
              : "[]",
            limit: search.get("limit")
              ? JSON.parse(search.get("limit") ?? "")
              : 25,
            offset: search.get("offset")
              ? JSON.parse(search.get("offset") ?? "")
              : 0,
          })
        );
      }, 500);
    }

    const isCompleted = () => {
      if (uuids.length) {
        let completed = true;
        uuids.forEach((uuid) => {
          if (ncs.some((e) => e[uuid])) {
            completed = false;
          }
        });
        if (completed && !creating) {
          uuids = [];
          clearInterval(interval);
          const search = new URLSearchParams(window.location.search);
          store.dispatch(
            getJournalEntriesByFilter({
              filters: search.get("filters")
                ? JSON.stringify(JSON.parse(search.get("filters") ?? "[]"))
                : "[]",
              limit: search.get("limit")
                ? JSON.parse(search.get("limit") ?? "")
                : 25,
              offset: search.get("offset")
                ? JSON.parse(search.get("offset") ?? "")
                : 0,
            })
          );
          if (callback) {
            callback();
          } else {
            store.dispatch(setStagedJournalEntryLoading(false));
          }
          if (close) {
            close = false;
            closeModal();
          } else if (stagedJournalEntry && !callback) {
            store.dispatch(getJournalEntryById(stagedJournalEntry.id));
          }
        }
      }
    };

    if (action.type == lockJournalEntrySuccess && stagedJournalEntry) {
      store.dispatch(getJournalEntryById(stagedJournalEntry.id));
      store.dispatch(setStagedJournalEntryLoading(false));
    }

    if (action.type == "ApiErrorAction") {
      uuids = [];
      if (interval) clearInterval(interval);
      store.dispatch(setStagedJournalEntryLoading(false));
      return next(action);
    }

    if (action.type == stagedJournalEntryCreateRequest && stagedJournalEntry) {
      navigate = action.payload.navigate;
      close = action.payload.close;
      creating = true;
      interval = setInterval(isCompleted, 1000);
      store.dispatch(setStagedJournalEntryLoading(true));
      const stagedJournalEntryClass = new JournalEntry(stagedJournalEntry);
      uuids.push(
        withNetworkCompletionDispatch(
          store.dispatch,
          createJournalEntry(stagedJournalEntryClass.body())
        )
      );
    }

    if (action.type == stagedJournalEntryUpdateRequest && stagedJournalEntry) {
      navigate = action.payload.navigate;
      close = action.payload.close;
      callback = action.payload.callback;
      interval = setInterval(isCompleted, 1000);
      store.dispatch(setStagedJournalEntryLoading(true));
      const body = action.payload.body;
      const stagedJournalEntryClass = new JournalEntry(stagedJournalEntry);

      if (stagedJournalEntryClass.toUpdate || body)
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateJournalEntry(stagedJournalEntryClass.id, {
              ...stagedJournalEntryClass.body(),
              ...body,
            })
          )
        );

      const liToCreate = stagedJournalEntryClass.journalEntryItemsToCreate();
      const liToDelete = stagedJournalEntryClass.journalEntryItemsToDelete();
      const liToUpdate = stagedJournalEntryClass.journalEntryItemsToUpdate();

      if (stagedJournalEntryClass.toUpdate) {
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateJournalEntry(
              stagedJournalEntry.id,
              stagedJournalEntryClass.body()
            )
          )
        );
      }

      liToCreate?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            createJournalEntryItem(stagedJournalEntry?.id, li.body())
          )
        )
      );

      liToUpdate?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateJournalEntryItem(li?.id, li.body())
          )
        )
      );

      liToDelete?.forEach((li) => {
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            deleteJournalEntryItem(li.id)
          )
        );
      });

      const filesToCreate = stagedJournalEntryClass.filesToCreate();
      const filesToDelete = stagedJournalEntryClass.filesToDelete();

      filesToCreate?.forEach((f) => {
        if (f.file)
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              uploadJournalEntryfile(stagedJournalEntry?.id, f.file)
            )
          );
      });

      filesToDelete?.forEach((f) => {
        if (f.id)
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              deleteJournalEntryfile(stagedJournalEntry?.id, f.id)
            )
          );
      });

      if (uuids.length === 0) closeModal();
    }

    if (action.type == createJournalEntrySuccess && stagedJournalEntry) {
      // we need to create the line items & upload all the files;
      const journalEntry = action.payload.journalEntry;
      const stagedJournalEntryClass = new JournalEntry(stagedJournalEntry);
      const lineItemsToCreate =
        stagedJournalEntryClass.journalEntryItemsToCreate();
      lineItemsToCreate?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            createJournalEntryItem(journalEntry.id, {
              ...li,
              journalEntryID: journalEntry.id,
            })
          )
        )
      );
      const filesToCreate = stagedJournalEntryClass.filesToCreate();
      filesToCreate?.forEach((f) => {
        if (f.file)
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              uploadJournalEntryfile(journalEntry.id, f.file)
            )
          );
      });
      creating = false;
    }

    return next(action);
  };
