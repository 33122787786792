import { useGetCurrentUserQuery } from "@nerdjs/account-kit";

export const user = "user";
export const accounting = "accounting";
export const accountingManager = "accounting_manager";

export function useMyAccess(): "user" | "accounting" | "accounting_manager" {
  const { data: me } = useGetCurrentUserQuery();
  const userServices = me?.services;
  const apDashboard = (userServices ?? {})["ap-dashboard"];

  if (apDashboard?.indexOf("superadmin") >= 0) return accountingManager;
  if (apDashboard?.indexOf("accounting_manager") >= 0) return accountingManager;
  if (apDashboard?.indexOf("accounting") >= 0) return accounting;
  if (apDashboard?.indexOf("user") >= 0) return user;

  return user;
}
