import { Alert, Button, Chip, Divider, IconButton, Typography } from "@mui/joy";
import { Box, Grid, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "allotment/dist/style.css";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import { useApExport } from "../../redux/apExport/apExportHooks";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { companySelector } from "../../redux/company/companySelector";
import {
  deleteJournalEntry,
  lockJournalEntry,
  stagedJournalEntryUpdateRequest,
  updateStagedJournalEntry,
} from "../../redux/journalEntry/journalEntryActions";
import { stagedJournalEntrySelector } from "../../redux/journalEntry/journalEntrySelector";
import { useAskQuestion } from "../askQuestion/askQuestion";
import { CompanyAutocomplete } from "../company/companyAutocomplete";
import { DangerZone } from "../payable/payableForm";
import { JournalEntryLineItems } from "./journalEntryLineItems";

export function JournalEntryForm({ mobile = false }: { mobile: boolean }) {
  const stagedJournalEntry = useAppSelector(stagedJournalEntrySelector);
  const company = useAppSelector(
    companySelector(stagedJournalEntry?.companyID)
  );
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { apExport } = useApExport(stagedJournalEntry?.exportID);
  const askQuestion = useAskQuestion();

  if (!stagedJournalEntry) return <Box />;

  const locked = stagedJournalEntry.locked ?? false;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "relative",
        }}
      >
        {!expanded ? (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography level="h4">
                  Journal Entry {stagedJournalEntry.referenceNumber}
                </Typography>
                {stagedJournalEntry?.id ? (
                  <Typography level="body1">
                    #{stagedJournalEntry.id}, Created by{" "}
                    {stagedJournalEntry?.createdBy?.fullname()}, on{" "}
                    {moment(stagedJournalEntry.createdAt).format("MM/DD/YYYY")}
                  </Typography>
                ) : (
                  []
                )}
                {apExport ? (
                  <Typography
                    level="body2"
                    startDecorator={"Exported on"}
                    endDecorator={`#${apExport.id}`}
                  >
                    <Chip size="sm" variant="soft" color="primary">
                      {apExport.name}
                    </Chip>
                  </Typography>
                ) : (
                  []
                )}
              </Box>
            </Box>
            {stagedJournalEntry.id && !stagedJournalEntry.locked ? (
              <Alert
                startDecorator={<i className="fa-duotone fa-lock-keyhole"></i>}
                endDecorator={
                  <Button
                    color="warning"
                    variant="solid"
                    onClick={() => {
                      askQuestion(
                        `You are about to lock the journalEntry (#${
                          stagedJournalEntry?.referenceNumber ?? ""
                        })`,
                        ["Cancel", "Yes"]
                      ).then((i) => {
                        if (i == 1) {
                          dispatch(
                            stagedJournalEntryUpdateRequest(
                              navigate,
                              false,
                              {},
                              () => {
                                dispatch(
                                  lockJournalEntry(stagedJournalEntry.id, {
                                    locked: true,
                                  })
                                );
                              }
                            )
                          );
                        }
                      });
                    }}
                  >
                    Lock Now
                  </Button>
                }
                variant="solid"
                color="warning"
              >
                This Journal Entry is ready to be locked
              </Alert>
            ) : (
              []
            )}
            {stagedJournalEntry.locked ? (
              <Alert
                startDecorator={<i className="fa-duotone fa-lock-keyhole"></i>}
                variant="solid"
                color="neutral"
              >
                <Box>
                  This journalEntry has been locked.
                  <Typography
                    level="body3"
                    style={{ opacity: 0.7, color: "inherit" }}
                  >
                    locked on {moment(stagedJournalEntry.lockedDate).fromNow()}
                  </Typography>
                </Box>
              </Alert>
            ) : (
              []
            )}
            <Divider sx={{ height: 4 }} />
            <Box>
              <Typography
                level="h6"
                startDecorator={<i className="fa-regular fa-circle-info"></i>}
              >
                References
              </Typography>
              <Typography level="body2">
                Provide important information about the journalEntry.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={mobile ? 12 : 6}>
                <TextField
                  disabled={locked}
                  label="Reference Number"
                  size="small"
                  fullWidth
                  defaultValue={stagedJournalEntry.referenceNumber}
                  onChange={(e) =>
                    dispatch(
                      updateStagedJournalEntry({
                        referenceNumber: e.target.value,
                      })
                    )
                  }
                  error={stagedJournalEntry.referenceNumber.length > 20}
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <CompanyAutocomplete
                  companyID={stagedJournalEntry.companyID}
                  disabled={locked}
                  size={"small"}
                  onChange={(c) =>
                    dispatch(
                      updateStagedJournalEntry({
                        companyID: c ? c.id : undefined,
                      })
                    )
                  }
                />
              </Grid>
              <Grid item xs={mobile ? 12 : 6}>
                <DatePicker
                  label="Date"
                  value={
                    stagedJournalEntry.date
                      ? moment(stagedJournalEntry.date).utc()
                      : null
                  }
                  disabled={locked}
                  onChange={(d) => {
                    dispatch(
                      updateStagedJournalEntry({
                        date: d?.format("YYYY-MM-DD"),
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        company
                          ? `Lock Date:
                            ${moment(company?.glLockDate?.date).format(
                              "MM/DD/YYYY"
                            )}`
                          : ""
                      }
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Divider sx={{ height: 4 }} />
          </>
        ) : (
          []
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography
              level="h6"
              startDecorator={<i className="fa-solid fa-list-ul"></i>}
            >
              Line Items
            </Typography>
            <Typography level="body2">
              Enter the Journal Entry’s content. CTRL+SPACE to add new line
              item.
            </Typography>
          </Box>
          <Tooltip title={expanded ? "Minimize" : "Expand"}>
            <IconButton
              onClick={() => setExpanded((s) => !s)}
              color="neutral"
              size="sm"
              variant="outlined"
            >
              <i
                className={
                  expanded
                    ? "fa-solid fa-minimize"
                    : "fa-sharp fa-solid fa-up-right-and-down-left-from-center"
                }
              ></i>
            </IconButton>
          </Tooltip>
        </Box>
        <JournalEntryLineItems />
        {stagedJournalEntry?.id ? (
          <DangerZone
            resource="journal entry"
            password={stagedJournalEntry.referenceNumber}
            onDelete={() => {
              dispatch(deleteJournalEntry(stagedJournalEntry.id));
              dispatch(setFormOpen(false, "journalEntry"));
            }}
          />
        ) : (
          []
        )}
      </Box>
    </LocalizationProvider>
  );
}
