import { User, User_Entity } from "@nerdjs/account-kit";
import { File, File_Entity } from "../file";
import { LineItem } from "../lineItem";
import { VendorLineItem_Entity } from "../vendorLineItem";

/**
 * Vendor_Entity type guard.
 *
 * @param {any} vendorJson Vendor object from API
 * @returns {boolean} Return true if type is Vendor_Entity
 */
function validator(vendorJson: unknown): vendorJson is Vendor_Entity {
  if (typeof vendorJson === "object" && vendorJson != null) {
    Vendor_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(vendorJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Vendor_Entity {
  static requiredFields = [
    "id",
    "name",
    "companyID",
    "companyName",
    "nameListType",
    "defaultLineItems",
    "defaultAssignedToUUID",
    "defaultDualAssignedToUUID",
    "refNum",
    "timestamp",
    "printAs",
    "addr1",
    "addr2",
    "addr3",
    "addr4",
    "addr5",
    "cont1",
    "phone1",
    "phone2",
    "faxNum",
    "email",
    "note",
    "taxID",
    "limit",
    "terms",
    "qbCompanyName",
    "firstName",
    "lastName",
    "contact",
    "websitePortal",
    "websiteUsername",
    "websitePassword",
    "tenNintyNine",
    "hidden",
    "createdAt",
    "updatedAt",
    "files",
  ];

  constructor(vendorJson: unknown) {
    if (validator(vendorJson)) {
      this.id = vendorJson.id;
      this.name = vendorJson.name;
      this.companyID = vendorJson.companyID;
      this.companyName = vendorJson.companyName;
      this.nameListType = vendorJson.nameListType;
      this.defaultLineItems = vendorJson.defaultLineItems;
      this.defaultAssignedToUUID = vendorJson.defaultAssignedToUUID;
      this.defaultDualAssignedToUUID = vendorJson.defaultDualAssignedToUUID;
      this.defaultAssignedTo = vendorJson.defaultAssignedTo;
      this.defaultDualAssignedTo = vendorJson.defaultDualAssignedTo;
      this.refNum = vendorJson.refNum;
      this.timestamp = vendorJson.timestamp;
      this.printAs = vendorJson.printAs;
      this.addr1 = vendorJson.addr1;
      this.addr2 = vendorJson.addr2;
      this.addr3 = vendorJson.addr3;
      this.addr4 = vendorJson.addr4;
      this.addr5 = vendorJson.addr5;
      this.cont1 = vendorJson.cont1;
      this.phone1 = vendorJson.phone1;
      this.phone2 = vendorJson.phone2;
      this.faxNum = vendorJson.faxNum;
      this.email = vendorJson.email;
      this.note = vendorJson.note;
      this.taxID = vendorJson.taxID;
      this.limit = vendorJson.limit;
      this.terms = vendorJson.terms;
      this.qbCompanyName = vendorJson.qbCompanyName;
      this.firstName = vendorJson.firstName;
      this.lastName = vendorJson.lastName;
      this.contact = vendorJson.contact;
      this.websitePortal = vendorJson.websitePortal;
      this.websiteUsername = vendorJson.websiteUsername;
      this.websitePassword = vendorJson.websitePassword;
      this.tenNintyNine = vendorJson.tenNintyNine;
      this.hidden = vendorJson.hidden;
      this.createdAt = vendorJson.createdAt;
      this.updatedAt = vendorJson.updatedAt;
      this.toDelete = vendorJson.toDelete;
      this.toCreate = vendorJson.toCreate;
      this.toUpdate = vendorJson.toUpdate;
      this.files = vendorJson.files;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Vendor_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  name: string;
  companyID: number;
  companyName: string;
  nameListType: number;
  defaultLineItems: Array<VendorLineItem_Entity>;
  defaultAssignedToUUID: string;
  defaultDualAssignedToUUID: string;
  defaultAssignedTo?: User_Entity;
  defaultDualAssignedTo?: User_Entity;
  refNum: number;
  timestamp: number;
  printAs: string;
  addr1: string;
  addr2: string;
  addr3: string;
  addr4: string;
  addr5: string;
  cont1: string;
  phone1: string;
  phone2: string;
  faxNum: string;
  email: string;
  note: string;
  taxID: string;
  limit: string;
  terms: {
    Name: string;
    Days: number;
  };
  qbCompanyName: string;
  firstName: string;
  lastName: string;
  contact: string;
  websitePortal: string;
  websiteUsername: string;
  websitePassword: string;
  tenNintyNine: boolean;
  hidden: boolean;
  createdAt: string;
  updatedAt: string;
  toUpdate?: boolean;
  toCreate?: boolean;
  toDelete?: boolean;
  files?: File_Entity[];
}
