import { Filters, SearchMetadata } from "@nerdjs/nerd-core";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { File_Entity } from "../../entities/file";
import { Vendor, Vendor_Entity } from "../../entities/vendor";

export const setStagedVendor = createAction(
  "setStagedVendor",
  function prepare(stagedVendor?: Vendor_Entity) {
    return {
      payload: {
        stagedVendor,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

// POST new Payable files
export const importVendors = createAction(
  "ap-dashboard/importVendors",
  function prepare(companyID: number, file: File) {
    const data = new FormData();
    data.append("file", file);
    data.append("companyID", `${companyID}`);

    const request = new PostAction(`/ap-dashboard/vendors/import`, data);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(importVendorsSuccess());
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const importVendorsSuccess = createAction(
  "ap-dashboard/importVendorsSuccess",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVendorLoading = createAction(
  "setVendorLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVendorSearchOpen = createAction(
  "setVendorSearchOpen",
  function prepare(searchOpen: boolean) {
    return {
      payload: {
        searchOpen,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVendorFilters = createAction(
  "setVendorFilters",
  function prepare(filters?: Filters) {
    return {
      payload: {
        filters,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedVendor = createAction(
  "updateStagedVendor",
  function prepare(stagedVendor?: Partial<Vendor_Entity>) {
    return {
      payload: {
        stagedVendor,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedVendorID = createAction(
  "setSelectedVendorID",
  function prepare(vendorID?: number) {
    return {
      payload: {
        vendorID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendors = createAction(
  "ap-dashboard/vendor/getVendors",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/vendors`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendors = Vendor.fromList(json);
      dispatch(getVendorsSuccess(vendors));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorsSuccess = createAction(
  "ap-dashboard/vendor/getVendorSuccess",
  function prepare(vendors: Vendor[]) {
    return {
      payload: {
        vendors,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorsByFilter = createAction(
  "ap-dashboard/vendor/getVendorsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/vendors`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendors = Vendor.fromList(json);
      dispatch(getVendorsByFilterSuccess(vendors));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorsByFilterSuccess = createAction(
  "ap-dashboard/vendor/getVendorsByFilterSuccess",
  function prepare(vendors: Vendor[]) {
    return {
      payload: {
        vendors,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorById = createAction(
  "ap-dashboard/vendor/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/vendors/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: Vendor_Entity) => {
      dispatch(getVendorByIdSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorByIdSuccess = createAction(
  "ap-dashboard/vendor/getVendorByIdSuccess",
  function prepare(vendor?: Vendor_Entity) {
    return {
      payload: {
        vendor,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteVendor = createAction(
  "ap-dashboard/removeVendorById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/vendors/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteVendorSuccess = createAction(
  "ap-dashboard/vendors/deleteVendorSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateVendor = createAction(
  "ap-dashboard/vendors/updateVendor",
  function prepare(id: number, body: Partial<Vendor_Entity>) {
    const request = new PutAction(`/ap-dashboard/vendors/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateVendorSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateVendorSuccess = createAction(
  "ap-dashboard/vendors/updateVendorSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createVendor = createAction(
  "ap-dashboard/vendors/createVendor",
  function prepare(body: Partial<Vendor_Entity>) {
    const request = new PostAction(`/ap-dashboard/vendors`, body);

    request.decodeResponse = (dispatch: Dispatch, json: Vendor_Entity) => {
      const vendor = new Vendor(json);
      dispatch(createVendorSuccess(vendor));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createVendorSuccess = createAction(
  "ap-dashboard/vendors/createVendorSuccess",
  function prepare(vendor: Vendor) {
    return {
      payload: {
        vendor,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorsSearchMetadata = createAction(
  "ap-dashboard/getVendorsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/vendors/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: SearchMetadata) => {
      dispatch(getVendorsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorsSearchMetadataSuccess = createAction(
  "ap-dashboard/getVendorsSearchMetadataSuccess",
  function prepare(vendorsSearchMetadata: SearchMetadata) {
    return {
      payload: {
        vendorsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setStagedVendorLoading = createAction(
  "setStagedVendorLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const stagedVendorUpdateRequest = createAction(
  "stagedVendorUpdateRequest",
  function prepare(
    navigate: NavigateFunction,
    close?: boolean,
    body?: Partial<Vendor_Entity>,
    callback?: () => void
  ) {
    return {
      payload: {
        navigate,
        close,
        body,
        callback,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const stagedVendorCreateRequest = createAction(
  "stagedVendorCreateRequest",
  function prepare(navigate: NavigateFunction, close?: boolean) {
    return {
      payload: {
        navigate,
        close,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorFilesById = createAction(
  "ap-dashboard/vendor/files/getById",
  function prepare(vendorID: number) {
    const request = new GetAction(`/ap-dashboard/vendors/${vendorID}/files`);

    request.decodeResponse = (dispatch: Dispatch, json: File_Entity[]) => {
      dispatch(getVendorFilesByIdSuccess(vendorID, json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorFilesByIdSuccess = createAction(
  "ap-dashboard/vendor/files/getVendorFilesByIdSuccess",
  function prepare(vendorID: number, files?: File_Entity[]) {
    return {
      payload: {
        files,
        id: nanoid(),
        createdAt: new Date().toISOString(),
        vendorID,
      },
    };
  }
);

export const downloadVendorFilesById = createAction(
  "ap-dashboard/vendor/downloadFiles/getById",
  function prepare(vendorID: number, fileID: number) {
    const request = new GetAction(
      `/ap-dashboard/vendors/${vendorID}/download/${fileID}`
    );

    request.decodeResponse = (dispatch: Dispatch, json: File_Entity) => {
      dispatch(downloadVendorFilesByIdSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const downloadVendorFilesByIdSuccess = createAction(
  "ap-dashboard/vendor/downloadFiles/downloadVendorFilesByIdSuccess",
  function prepare(file?: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const uploadVendorFile = createAction(
  "ap-dashboard/vendor/uploadVendorFile",
  function prepare(vendorID: number, file: File) {
    const data = new FormData();
    data.append("file", file);

    const request = new PostAction(
      `/ap-dashboard/vendors/${vendorID}/upload`,
      data
    );

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendorFile = json as File;
      dispatch(uploadVendorFileSuccess(vendorFile));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const uploadVendorFileSuccess = createAction(
  "ap-dashboard/vendor/uploadVendorFileSuccess",
  function prepare(vendorFileUpload: File) {
    return {
      payload: {
        vendorFileUpload,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
