import { Filters, SearchMetadata } from "@nerdjs/nerd-core";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import {
  VendorLineItem,
  VendorLineItem_Entity,
} from "../../entities/vendorLineItem";

export const setStagedVendorLineItem = createAction(
  "setStagedVendorLineItem",
  function prepare(stagedVendorLineItem?: VendorLineItem_Entity) {
    return {
      payload: {
        stagedVendorLineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVendorLineItemLoading = createAction(
  "setVendorLineItemLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVendorLineItemSearchOpen = createAction(
  "setVendorLineItemSearchOpen",
  function prepare(searchOpen: boolean) {
    return {
      payload: {
        searchOpen,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVendorLineItemFilters = createAction(
  "setVendorLineItemFilters",
  function prepare(filters?: Filters) {
    return {
      payload: {
        filters,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedVendorLineItem = createAction(
  "updateStagedVendorLineItem",
  function prepare(stagedVendorLineItem?: Partial<VendorLineItem_Entity>) {
    return {
      payload: {
        stagedVendorLineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedVendorLineItemID = createAction(
  "setSelectedVendorLineItemID",
  function prepare(vendorLineItemID?: number) {
    return {
      payload: {
        vendorLineItemID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorLineItems = createAction(
  "ap-dashboard/vendorLineItem/getVendorLineItems",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/vendorLineItems`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendorLineItems = VendorLineItem.fromList(json);
      dispatch(getVendorLineItemsSuccess(vendorLineItems));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorLineItemsSuccess = createAction(
  "ap-dashboard/vendorLineItem/getVendorLineItemSuccess",
  function prepare(vendorLineItems: VendorLineItem[]) {
    return {
      payload: {
        vendorLineItems,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorLineItemsByFilter = createAction(
  "ap-dashboard/vendorLineItem/getVendorLineItemsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/vendorLineItems`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendorLineItems = VendorLineItem.fromList(json);
      dispatch(getVendorLineItemsByFilterSuccess(vendorLineItems));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorLineItemsByFilterSuccess = createAction(
  "ap-dashboard/vendorLineItem/getVendorLineItemsByFilterSuccess",
  function prepare(vendorLineItems: VendorLineItem[]) {
    return {
      payload: {
        vendorLineItems,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorLineItemById = createAction(
  "ap-dashboard/vendorLineItem/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/vendorLineItems/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendorLineItem = new VendorLineItem(json);
      dispatch(getVendorLineItemByIdSuccess(vendorLineItem));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorLineItemByIdSuccess = createAction(
  "ap-dashboard/vendorLineItem/getVendorLineItemByIdSuccess",
  function prepare(vendorLineItem?: VendorLineItem) {
    return {
      payload: {
        vendorLineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteVendorLineItem = createAction(
  "ap-dashboard/removeVendorLineItemById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/vendorLineItems/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteVendorLineItemSuccess = createAction(
  "ap-dashboard/vendorLineItems/deleteVendorLineItemSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateVendorLineItem = createAction(
  "ap-dashboard/vendorLineItems/updateVendorLineItem",
  function prepare(id: number, body: Partial<VendorLineItem>) {
    const request = new PutAction(`/ap-dashboard/vendorLineItems/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateVendorLineItemSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateVendorLineItemSuccess = createAction(
  "ap-dashboard/vendorLineItems/updateVendorLineItemSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createVendorLineItem = createAction(
  "ap-dashboard/vendorLineItems/createVendorLineItem",
  function prepare(vendorID, body: Partial<VendorLineItem>) {
    const request = new PostAction(`/ap-dashboard/vendorLineItems`, {
      ...body,
      vendorID,
    });

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const vendorLineItem = new VendorLineItem(json);
      dispatch(createVendorLineItemSuccess(vendorLineItem));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createVendorLineItemSuccess = createAction(
  "ap-dashboard/vendorLineItems/createVendorLineItemSuccess",
  function prepare(vendorLineItem: VendorLineItem) {
    return {
      payload: {
        vendorLineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getVendorLineItemsSearchMetadata = createAction(
  "ap-dashboard/getVendorLineItemsSearchMetadata",
  function prepare() {
    const request = new GetAction(
      `/ap-dashboard/vendorLineItems/searchMetadata`
    );

    request.decodeResponse = (dispatch: Dispatch, json: SearchMetadata) => {
      dispatch(getVendorLineItemsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getVendorLineItemsSearchMetadataSuccess = createAction(
  "ap-dashboard/getVendorLineItemsSearchMetadataSuccess",
  function prepare(vendorLineItemsSearchMetadata: SearchMetadata) {
    return {
      payload: {
        vendorLineItemsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
