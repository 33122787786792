import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { ItemClass } from "../../entities/itemClass";
import { getItemClasses } from "../../redux/itemClass/itemClassActions";
import {
  itemClassesSelector,
  itemClassSelector,
} from "../../redux/itemClass/itemClassSelector";

/**
 * @param {itemClassAutocompleteProps} itemClassAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} ItemClass Autocomplete
 */
export function ItemClassAutocomplete({
  itemClassID,
  onChange,
  variant,
  filter,
  size,
  helperText,
  disabled,
}: itemClassAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const itemClasses = useAppSelector(itemClassesSelector);
  const itemClass = useAppSelector(itemClassSelector(itemClassID));
  const dispatch = useDispatch();

  useEffect(() => {
    if (itemClasses.length === 0) {
      dispatch(getItemClasses());
    }
  }, []);

  useEffect(() => {
    if (itemClass) {
      setInputValue(itemClass.name);
    }
  }, [itemClass]);

  return (
    <Autocomplete
      value={itemClass ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      sx={{ flex: 1 }}
      getOptionLabel={(option) => `${option.name}`}
      options={filter ? itemClasses.filter(filter) : itemClasses}
      renderInput={(params) => (
        <TextField
          variant={variant}
          {...params}
          fullWidth
          autoFocus
          label="ItemClass"
          helperText={helperText}
        />
      )}
    />
  );
}

type itemClassAutocompleteProps = {
  itemClassID?: number;
  filter?: (s: ItemClass) => boolean;
  onChange: (itemClass: ItemClass | null) => void;
  variant?: "standard" | "filled" | "outlined" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  disabled?: boolean;
};
