import { createReducer } from "@reduxjs/toolkit";
import { JournalEntryItem } from "../../entities/journalEntryItem";
import { arrayToMap } from "../../helpers/reducerHelpers";

import {
  deleteJournalEntryItemSuccess,
  getJournalEntryItemByIdSuccess,
  getJournalEntryItemsSearchMetadataSuccess,
  getJournalEntryItemsSuccess,
  setSelectedJournalEntryItemID,
} from "./journalEntryItemActions";
import { JournalEntryItemState } from "./journalEntryItemState";

function initState(): JournalEntryItemState {
  return {
    journalEntryItems: {},
    searchMetadata: undefined,
    selectedJournalEntryItemID: undefined,
  };
}

export const journalEntryItemReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedJournalEntryItemID, (state, action) => {
    state.selectedJournalEntryItemID = action.payload.journalEntryItemID;
    return state;
  });
  builder.addCase(getJournalEntryItemsSuccess, (state, action) => {
    const journalEntryItems = action.payload.journalEntryItems;
    state.journalEntryItems = arrayToMap<JournalEntryItem>(journalEntryItems);
    return state;
  });
  builder.addCase(
    getJournalEntryItemsSearchMetadataSuccess,
    (state, action) => {
      state.searchMetadata = action.payload.journalEntryItemsSearchMetadata;
      return state;
    }
  );
  builder.addCase(deleteJournalEntryItemSuccess, (state, action) => {
    const journalEntryItemID = action.payload.id;
    if (state.journalEntryItems[journalEntryItemID]) {
      delete state.journalEntryItems[journalEntryItemID];
    }
    return state;
  });
  builder.addCase(getJournalEntryItemByIdSuccess, (state, action) => {
    const journalEntryItem = action.payload.journalEntryItem;
    if (journalEntryItem) {
      const journalEntryItems = { ...state.journalEntryItems };
      journalEntryItems[journalEntryItem.id] = journalEntryItem;
      state.journalEntryItems = journalEntryItems;
    }
    return state;
  });
});
