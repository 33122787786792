import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GlAccount } from "../../entities/glAccount";
import { useAppSelector } from "../../atoms/hooks";
import { getGlAccounts } from "../../redux/glAccount/glAccountActions";
import {
  glAccountsSelector,
  glAccountSelector,
} from "../../redux/glAccount/glAccountSelector";

/**
 * @param {glAccountAutocompleteProps} glAccountAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} GlAccount Autocomplete
 */
export function GlAccountAutocomplete({
  glAccountID,
  onChange,
  variant,
  filter,
  size,
  helperText,
  disabled,
  error,
}: glAccountAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const glAccounts = useAppSelector(glAccountsSelector);
  const glAccount = useAppSelector(glAccountSelector(glAccountID));
  const dispatch = useDispatch();

  useEffect(() => {
    if (glAccounts.length === 0) {
      dispatch(getGlAccounts());
    }
  }, []);

  useEffect(() => {
    if (glAccount) {
      setInputValue(glAccount.name);
    }
  }, [glAccount]);

  return (
    <Autocomplete
      value={glAccount ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      getOptionLabel={(option) =>
        option?.formatName ? option?.formatName() : ""
      }
      options={filter ? glAccounts.filter(filter) : glAccounts}
      sx={{ flex: 1 }}
      renderInput={(params) => (
        <TextField
          variant={variant}
          {...params}
          fullWidth
          label="GlAccount"
          error={error}
          helperText={helperText}
          autoFocus
        />
      )}
    />
  );
}

type glAccountAutocompleteProps = {
  glAccountID?: number;
  onChange: (glAccount: GlAccount | null) => void;
  variant?: "standard" | "filled" | "outlined" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  filter?: (s: GlAccount) => boolean;
  disabled?: boolean;
  error?: boolean;
};
