import { AppConfig } from "../../environement";
import File_Entity from "./file";
export default class File extends File_Entity {
  static fromList(filesJSON: unknown): Array<File> {
    const files: File[] = [];
    if (filesJSON)
      Array.isArray(filesJSON) &&
        filesJSON.forEach((fileJSON) => {
          files.push(new File(fileJSON));
        });
    return files;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  previewURL(): string {
    if (this.blob) return this.blob;
    let modelType = this.modelType;
    if (modelType === "payable") modelType = "payables";
    if (modelType === "lineItem") modelType = "lineItems";
    if (modelType === "journalEntry") modelType = "journalEntries";
    if (modelType === "vendor") modelType = "vendors";
    return `${AppConfig.api.endpoint}${AppConfig.api.routePrefix}/ap-dashboard/${modelType}/${this.modelID}/download/${this.id}?inline=1`;
  }
}
