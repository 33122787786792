import { Divider, IconButton, Typography } from "@mui/joy";
import { Box, Drawer } from "@mui/material";
import hotkeys from "hotkeys-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import { RouterConfig } from "../../atoms/config/routerConfig";

/**
 *
 * @returns {ReactElement} Shortcut page
 */
export function Shortcut() {
  const [char, setChar] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = useSelector(formOpenSelector("shortcut"));

  useEffect(() => {
    handleShortcut();
  }, [char]);

  useEffect(() => {
    hotkeys.filter = function (event) {
      const target = event.target as HTMLElement; // Here's the type assertion
      const tagName = target.tagName;
      // Don't trigger hotkeys event when:
      return !(
        target.isContentEditable ||
        tagName === "INPUT" ||
        tagName === "SELECT" ||
        tagName === "TEXTAREA"
      );
    };

    hotkeys("*", function (event: any) {
      setChar((e) => `${e}${event.key}`);
      setTimeout(() => setChar(""), 1000);
    });

    return () => {
      hotkeys.unbind("*");
    };
  }, []);

  function handleShortcut() {
    switch (char) {
      case "cp":
        navigate(`${RouterConfig.payables}/new`);
        break;
      case "cj":
        navigate(`${RouterConfig.journalEntries}/new`);
        break;
      case "gp":
        navigate(`${RouterConfig.payables}`);
        break;
      case "gj":
        navigate(`${RouterConfig.journalEntries}`);
        break;
      case "gv":
        navigate(`${RouterConfig.vendors}`);
        break;
      case "gc":
        navigate(`${RouterConfig.companies}`);
        break;
      case "gg":
        navigate(`${RouterConfig.glAccounts}`);
        break;
      case "gi":
        navigate(`${RouterConfig.itemClasses}`);
        break;
      case "ge":
        navigate(`${RouterConfig.exports}`);
        break;
      default:
        break;
    }
  }
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => dispatch(setFormOpen(false, "shortcut"))}
    >
      <Box
        sx={{
          p: 2,
          width: 380,
          display: "flex",
          gap: 1,
          alignItems: "stretch",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {char}
          <Typography level="h6">Keyboard Shortcuts</Typography>
          <IconButton
            onClick={() => dispatch(setFormOpen(false, "shortcut"))}
            variant="plain"
            color="neutral"
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <Typography>
            <b>Navigation</b>
          </Typography>
          <Short a="g" b="p" action="Payables" />
          <Short a="g" b="j" action="Journal Entries" />
          <Short a="g" b="v" action="Vendors" />
          <Short a="g" b="c" action="Companies" />
          <Short a="g" b="g" action="GL Accounts" />
          <Short a="g" b="i" action="Item Classes" />
          <Short a="g" b="e" action="Exports" />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <Typography>
            <b>Create</b>
          </Typography>
          <Short a="c" b="p" action="Payable" />
          <Short a="c" b="j" action="Journal Entry" />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <Typography>
            <b>Actions</b>
          </Typography>
          <Short a="a" b="e" action="Export" />
        </Box> */}
      </Box>
    </Drawer>
  );
}

function Short({ a, b, action }: { a: string; b: string; action: string }) {
  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "baseline" }}>
      <Letter>{a}</Letter> <Typography level="body2">then</Typography>{" "}
      <Letter>{b}</Letter>: {action}
    </Box>
  );
}

function Letter({ children }: { children: string }) {
  return (
    <Box
      sx={{
        background: "#ECEEF1",
        color: "#424551",
        borderRadius: "4px",
        padding: 0.5,
        minWidth: 30,
        textAlign: "center",
      }}
    >
      {children}
    </Box>
  );
}
