import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { PriorityLevel } from "../../entities/priorityLevel";

export const setSelectedPriorityLevelID = createAction(
  "setSelectedPriorityLevelID",
  function prepare(priorityLevelID?: number) {
    return {
      payload: {
        priorityLevelID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPriorityLevels = createAction(
  "ap-dashboard/priorityLevel/getPriorityLevels",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/priorityLevels`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const priorityLevels = PriorityLevel.fromList(json);
      dispatch(getPriorityLevelsSuccess(priorityLevels));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPriorityLevelsSuccess = createAction(
  "ap-dashboard/priorityLevel/getPriorityLevelSuccess",
  function prepare(priorityLevels: PriorityLevel[]) {
    return {
      payload: {
        priorityLevels,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPriorityLevelsByFilter = createAction(
  "ap-dashboard/priorityLevel/getPriorityLevelsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/priorityLevels`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const priorityLevels = PriorityLevel.fromList(json);
      dispatch(getPriorityLevelsByFilterSuccess(priorityLevels));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPriorityLevelsByFilterSuccess = createAction(
  "ap-dashboard/priorityLevel/getPriorityLevelsByFilterSuccess",
  function prepare(priorityLevels: PriorityLevel[]) {
    return {
      payload: {
        priorityLevels,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPriorityLevelById = createAction(
  "ap-dashboard/priorityLevel/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/priorityLevels/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const priorityLevel = new PriorityLevel(json);
      dispatch(getPriorityLevelByIdSuccess(priorityLevel));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPriorityLevelByIdSuccess = createAction(
  "ap-dashboard/priorityLevel/getPriorityLevelByIdSuccess",
  function prepare(priorityLevel?: PriorityLevel) {
    return {
      payload: {
        priorityLevel,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deletePriorityLevel = createAction(
  "ap-dashboard/removePriorityLevelById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/priorityLevels/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deletePriorityLevelSuccess = createAction(
  "ap-dashboard/priorityLevels/deletePriorityLevelSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updatePriorityLevel = createAction(
  "ap-dashboard/priorityLevels/updatePriorityLevel",
  function prepare(id: number, body: Partial<PriorityLevel>) {
    const request = new PutAction(`/ap-dashboard/priorityLevels/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updatePriorityLevelSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updatePriorityLevelSuccess = createAction(
  "ap-dashboard/priorityLevels/updatePriorityLevelSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createPriorityLevel = createAction(
  "ap-dashboard/priorityLevels/createPriorityLevel",
  function prepare(body: Partial<PriorityLevel>) {
    const request = new PostAction(`/ap-dashboard/priorityLevels`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const priorityLevel = new PriorityLevel(json);
      dispatch(createPriorityLevelSuccess(priorityLevel));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createPriorityLevelSuccess = createAction(
  "ap-dashboard/priorityLevels/createPriorityLevelSuccess",
  function prepare(priorityLevel: PriorityLevel) {
    return {
      payload: {
        priorityLevel,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getPriorityLevelsSearchMetadata = createAction(
  "ap-dashboard/getPriorityLevelsSearchMetadata",
  function prepare() {
    const request = new GetAction(
      `/ap-dashboard/priorityLevels/searchMetadata`
    );

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getPriorityLevelsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getPriorityLevelsSearchMetadataSuccess = createAction(
  "ap-dashboard/getPriorityLevelsSearchMetadataSuccess",
  function prepare(priorityLevelsSearchMetadata: unknown) {
    return {
      payload: {
        priorityLevelsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
