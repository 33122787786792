import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectUsers = (state: AppState) => state.userState.users;
const selectSelectedUserID = (state: AppState) =>
  state.userState.selectedUserID;

export const usersSelector = createSelector([selectUsers], (users) =>
  mapToArray(users).sort((a, b) => a.lastName.localeCompare(b.lastName))
);

export const selectedUserSelector = createSelector(
  [selectUsers, selectSelectedUserID],
  (users, selectedUserID) => {
    if (selectedUserID) return users[selectedUserID];
    return undefined;
  }
);

export const userSelector = (id?: number) => {
  return createSelector([selectUsers], (users) => {
    if (id) return users[id];
    return undefined;
  });
};

export const userUUIDSelector = (uuid?: string) => {
  return createSelector([selectUsers], (users) => {
    if (uuid) return mapToArray(users).find((u) => u.uuid === uuid);
    return undefined;
  });
};
