import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { JournalEntryExport } from "../../hooks/journalEntry/journalEntryExport";
import { JournalEntry } from "../../hooks/journalEntry/journalEntryTemplate";
import { PayableExport } from "../../hooks/payable/payableExport";
import { Payable } from "../../hooks/payable/payableTemplate";
import SearchPresetForm from "../../hooks/searchPreset/searchPresetsForm";
import { VendorForm } from "../../hooks/vendor/vendorForm";
import { VendorsImport } from "../../hooks/vendor/vendorsImport";
import { setSelectedJournalEntryID } from "../../redux/journalEntry/journalEntryActions";
import { setSelectedPayableID } from "../../redux/payable/payableActions";
import { RouterConfig } from "../config/routerConfig";
import { useAppSelector } from "../hooks";
import SlidingDialog from "../slidingDialog/slidingDialog";

export default function Openner() {
  const formOpen = useAppSelector((s) => s.appStatusState.formOpen);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <div>
      <SlidingDialog
        open={formOpen["payable"]}
        onClose={() => {
          dispatch(setSelectedPayableID());
          navigate({ pathname: RouterConfig.payables, search });
        }}
      >
        <Payable />
      </SlidingDialog>
      <SlidingDialog
        open={formOpen["journalEntry"]}
        onClose={() => {
          dispatch(setSelectedJournalEntryID());
          navigate({ pathname: RouterConfig.journalEntries, search });
        }}
      >
        <JournalEntry />
      </SlidingDialog>
      {formOpen["searchPreset"] && <SearchPresetForm />}
      {formOpen["vendor"] && <VendorForm />}
      {formOpen["export"] && <PayableExport />}
      {formOpen["journalEntryExport"] && <JournalEntryExport />}
      {formOpen["importVendors"] && <VendorsImport />}
    </div>
  );
}
