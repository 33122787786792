/**
 * JournalEntryItem_Entity type guard.
 *
 * @param {any} journalEntryItemJson JournalEntryItem object from API
 * @returns {boolean} Return true if type is JournalEntryItem_Entity
 */
function validator(
  journalEntryItemJson: unknown
): journalEntryItemJson is JournalEntryItem_Entity {
  if (
    typeof journalEntryItemJson === "object" &&
    journalEntryItemJson != null
  ) {
    JournalEntryItem_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(journalEntryItemJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class JournalEntryItem_Entity {
  static requiredFields = [
    "id",
    "journalEntryID",
    "glAccountID",
    "glAccountName",
    "classID",
    "className",
    "vendorID",
    "debit",
    "credit",
    "memo",
    "internalMemo",
    "glEntryID",
    "files",
    "createdAt",
    "updatedAt",
    "position",
  ];

  constructor(journalEntryItemJson: unknown) {
    if (validator(journalEntryItemJson)) {
      this.id = journalEntryItemJson.id;
      this.journalEntryID = journalEntryItemJson.journalEntryID;
      this.glAccountID = journalEntryItemJson.glAccountID;
      this.glAccountName = journalEntryItemJson.glAccountName;
      this.classID = journalEntryItemJson.classID;
      this.className = journalEntryItemJson.className;
      this.vendorID = journalEntryItemJson.vendorID;
      this.debit = journalEntryItemJson.debit;
      this.credit = journalEntryItemJson.credit;
      this.memo = journalEntryItemJson.memo;
      this.internalMemo = journalEntryItemJson.internalMemo;
      this.glEntryID = journalEntryItemJson.glEntryID;
      this.files = journalEntryItemJson.files;
      this.createdAt = journalEntryItemJson.createdAt;
      this.updatedAt = journalEntryItemJson.updatedAt;
      this.toUpdate = journalEntryItemJson.toUpdate;
      this.toCreate = journalEntryItemJson.toCreate;
      this.toDelete = journalEntryItemJson.toDelete;
      this.position = journalEntryItemJson.position;
    } else {
      throw new Error(
        "Failed to create new instance of ${ JournalEntryItem_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  journalEntryID?: number;
  glAccountID?: number;
  glAccountName?: string;
  classID?: number;
  className?: string;
  vendorID?: number;
  debit?: number;
  credit?: number;
  memo?: string;
  internalMemo?: string;
  glEntryID?: number;
  files?: Array<File>;
  createdAt?: string;
  updatedAt?: string;
  toUpdate?: boolean;
  toCreate?: boolean;
  toDelete?: boolean;
  position?: number;
}
