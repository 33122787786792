/**
 * File_Entity type guard.
 *
 * @param {any} fileJson File object from API
 * @returns {boolean} Return true if type is File_Entity
 */
function validator(fileJson: unknown): fileJson is File_Entity {
  if (typeof fileJson === "object" && fileJson != null) {
    File_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(fileJson).includes(element))
        console.log(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class File_Entity {
  static requiredFields = ["id"];

  constructor(fileJson: unknown) {
    if (validator(fileJson)) {
      this.id = fileJson.id;
      this.description = fileJson.description;
      this.modelID = fileJson.modelID;
      this.modelType = fileJson.modelType;
      this.name = fileJson.name;
      this.serviceName = fileJson.serviceName;
      this.createdAt = fileJson.createdAt;
      this.updatedAt = fileJson.updatedAt;
      this.blob = fileJson.blob;
      this.toCreate = fileJson.toCreate;
      this.toDelete = fileJson.toDelete;
      this.file = fileJson.file;
    } else
      throw new Error(`Failed to create new instance of ${File_Entity.name}`);
  }

  id?: number;
  description?: string;
  modelID?: number;
  modelType?: string;
  name: string;
  serviceName?: string;
  createdAt?: string;
  updatedAt?: string;
  blob?: string;
  toCreate?: boolean;
  toDelete?: boolean;
  file?: File;
}
