import JournalEntryItem_Entity from "./journalEntryItem";
export default class JournalEntryItem extends JournalEntryItem_Entity {
  static fromList(journalEntryItemsJSON: unknown): Array<JournalEntryItem> {
    const journalEntryItems: JournalEntryItem[] = [];
    if (journalEntryItemsJSON)
      Array.isArray(journalEntryItemsJSON) &&
        journalEntryItemsJSON.forEach((journalEntryItemJSON) => {
          journalEntryItems.push(new JournalEntryItem(journalEntryItemJSON));
        });
    return journalEntryItems;
  }

  body() {
    return {
      id: this.id,
      journalEntryID: this.journalEntryID,
      classID: this.classID,
      vendorID: this.vendorID,
      glAccountID: this.glAccountID,
      debit: this.debit,
      credit: this.credit,
      memo: this.memo,
      internalMemo: this.internalMemo,
      glEntryID: this.glEntryID,
      position: this.position,
    };
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
