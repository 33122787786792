import { Button, IconButton, Tab, TabList, Tabs } from "@mui/joy";
import { Box, ListItem, Popover, Tooltip, Typography } from "@mui/material";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchPreset } from "../entities/searchPreset";
import { useQuery } from "../nerdPaginationFooter/utils";
import { NerdSearchPresetsProps } from "./types";

export function NerdSearchPresets({
  searchPresets,
  onSearch,
  onEdit,
}: NerdSearchPresetsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const query = useQuery();
  const navigate = useNavigate();
  const { data: me } = useGetCurrentUserQuery();
  const [tab, setTab] = useState(0);

  const handlePresetClick = (sp: SearchPreset) => {
    setAnchorEl(null);
    query.set("filters", sp.filter);
    query.set("offset", "0");
    navigate({ search: query.toString() });
    if (onSearch)
      onSearch({
        filters: query.get("filters"),
        offset: 0,
        limit: query.get("limit") ?? 25,
      });
  };

  const clear = () => {
    query.delete("filters");
    navigate({ search: query.toString() });
    if (onSearch)
      onSearch({
        offset: 0,
        limit: query.get("limit") ?? 25,
      });
  };

  const filters = query.get("filters");
  const selected = searchPresets.find((sp) => sp.filter === filters);

  if (!me) return <Box />;

  return (
    <>
      <Button
        variant={selected ? "solid" : "outlined"}
        size="sm"
        endDecorator={
          selected ? (
            <Tooltip title="Clear Preset">
              <Box
                sx={{ p: 0.5 }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  clear();
                }}
              >
                <i
                  style={{ fontSize: 14, color: "white" }}
                  className="fa-solid fa-xmark"
                ></i>
              </Box>
            </Tooltip>
          ) : (
            <i
              style={{ fontSize: 14 }}
              className="fa-solid fa-chevron-down"
            ></i>
          )
        }
        sx={{
          background: selected?.color,
          "&:hover": { background: selected?.color },
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {selected?.name ?? "Presets"}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ p: 1 }}>
          <Tabs
            size="sm"
            variant="soft"
            value={tab}
            onChange={(e, v) => setTab(v as number)}
          >
            <TabList>
              <Tab>Global Presets</Tab>
              <Tab>My Presets</Tab>
            </TabList>
          </Tabs>
          {searchPresets
            .filter((p) => {
              return tab === 0 ? p.global : p.isMine(me);
            })
            .map((sp) => (
              <ListItem
                button
                key={sp.id}
                onClick={() => handlePresetClick(sp)}
              >
                <Box sx={{ display: "flex", gap: 1, flex: 1 }}>
                  <Box sx={{ background: sp.color, height: 20, width: 20 }} />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography>{sp.name}</Typography>
                  </Box>
                  <Box>
                    {onEdit != undefined && sp.isMine(me) ? (
                      <Tooltip title="Edit preset">
                        <IconButton
                          size="sm"
                          variant="plain"
                          color="neutral"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onEdit(sp);
                          }}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      []
                    )}
                  </Box>
                </Box>
              </ListItem>
            ))}
        </Box>
      </Popover>
    </>
  );
}
