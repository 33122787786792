import { Badge, Sheet, Typography } from "@mui/joy";
import { Box, Button, Divider, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NerdCurrencyField } from "@nerdjs/nerd-ui";
import { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { LineItem } from "../../entities/lineItem";
import {
  currencyFormatter,
  getWidthFromLocalStorage,
} from "../../helpers/accountingHelpers";
import { newIndexAfterReorder } from "../../helpers/reorderHelpers";
import { getGlAccounts } from "../../redux/glAccount/glAccountActions";
import {
  glAccountsMapSelector,
  glAccountsSelector,
} from "../../redux/glAccount/glAccountSelector";
import { getItemClasses } from "../../redux/itemClass/itemClassActions";
import {
  itemClassesMapSelector,
  itemClassesSelector,
} from "../../redux/itemClass/itemClassSelector";
import { setSelectedLineItemID } from "../../redux/lineItem/lineItemActions";
import {
  deleteStagedPayableLineItem,
  newStagedPayableLineItem,
  updateStagedPayableLineItem,
} from "../../redux/payable/payableActions";
import {
  stagedPayableLineItemsSelector,
  stagedPayableSelector,
} from "../../redux/payable/payableSelector";
import { GlAccountAutocomplete } from "../glAccount/glAccountAutocomplete";
import { ItemClassAutocomplete } from "../itemClass/itemClassAutocomplete";
import { BatchLineItems } from "../lineItem/batchLineItems";
import { LineItemFiles } from "../lineItem/lineItemFiles";
import { useMyAccess, user } from "../myAccess/myAccess";
/**
 *
 * @returns {ReactElement} PayableLineItems component
 */
export function PayableLineItems() {
  const apiRef = useGridApiRef();
  const stagedPayable = useAppSelector(stagedPayableSelector);
  const lineItems = useAppSelector(stagedPayableLineItemsSelector)
    .filter((li) => !li.toDelete)
    .sort((a, b) => (a?.position ?? 10000) - (b?.position ?? 10000));
  const glAccountsMap = useAppSelector(glAccountsMapSelector);
  const glAccounts = useAppSelector(glAccountsSelector);
  const itemClassesMap = useAppSelector(itemClassesMapSelector);
  const itemClasses = useAppSelector(itemClassesSelector);
  const dispatch = useDispatch();
  const locked = stagedPayable?.locked ?? false;
  const myAccess = useMyAccess();

  useEffect(() => {
    if (itemClasses.length === 0) {
      dispatch(getItemClasses());
    }
    if (glAccounts.length === 0) {
      dispatch(getGlAccounts());
    }
  }, []);

  const lineItemUpdate = async (lineItem: LineItem) => {
    return new Promise<LineItem>((resolve) => {
      lineItem.amount = Number(lineItem.amount ?? 0);
      dispatch(updateStagedPayableLineItem(lineItem));
      setTimeout(() => {
        resolve(lineItem);
      }, 50);
    });
  };

  const lineItemPositionChanged = (params: GridRowOrderChangeParams) => {
    const lineItem = lineItems[params.oldIndex];
    const up = params.oldIndex < params.targetIndex;
    lineItem.position = newIndexAfterReorder(lineItems, params.targetIndex, up);

    dispatch(updateStagedPayableLineItem(lineItem));
  };

  const columns: GridColumns<LineItem> = [
    {
      field: "leftActions",
      headerName: "",
      align: "left",
      type: "actions",
      width: getWidthFromLocalStorage("payable", "leftActions", 40),
      getActions: (p) => [
        <Badge
          invisible={p.row.files.filter((p) => !p.toDelete).length === 0}
          key="Delete"
          size="sm"
          badgeInset="3px 3px 0px 0"
        >
          <GridActionsCellItem
            onClick={() => dispatch(setSelectedLineItemID(p.row.id))}
            icon={<i className="fa-regular fa-paperclip"></i>}
            label={"Upload File"}
            color="inherit"
          />
        </Badge>,
      ],
    },
    {
      field: "glAccountID",
      headerName: "GL Account",
      editable: !locked && myAccess !== user,
      renderEditCell: (p) => <GLAccountInputCell {...p} />,
      valueFormatter: (p) => glAccountsMap[p.value]?.formatName() ?? "",
      renderCell: (p) => (
        <Tooltip title={glAccountsMap[p.value]?.formatName()}>
          <Typography level="body2">
            {glAccountsMap[p.value]?.formatName()}
          </Typography>
        </Tooltip>
      ),
      width: getWidthFromLocalStorage("payable", "glAccountID", 350),
    },
    {
      field: "classID",
      headerName: "Item Class",
      renderEditCell: (p) => <ItemClassInputCell {...p} />,
      valueFormatter: (p) => itemClassesMap[p.value]?.name ?? "",
      renderCell: (p) => (
        <Tooltip title={itemClassesMap[p.value]?.name}>
          <Typography level="body2">{itemClassesMap[p.value]?.name}</Typography>
        </Tooltip>
      ),
      editable: !locked && myAccess !== user,
      width: getWidthFromLocalStorage("payable", "classID", 300),
    },
    {
      field: "memo",
      headerName: "Memo",
      width: getWidthFromLocalStorage("payable", "memo", 300),
      editable: !locked,
    },
    {
      field: "internalMemo",
      headerName: "Internal Memo",
      width: getWidthFromLocalStorage("payable", "internalMemo", 300),
      editable: !locked,
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "right",
      type: "number",
      width: getWidthFromLocalStorage("payable", "amount", 100),
      editable: !locked && myAccess !== user,
      renderEditCell(params: GridRenderCellParams<number>) {
        return (
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <NerdCurrencyField
              signed
              textFieldProps={{
                autoFocus: true,
                size: "small",
                variant: "standard",
                InputProps: {
                  disableUnderline: true,
                },
              }}
              multiplier={1}
              value={params.value}
              onChange={(e) => {
                apiRef.current.setEditCellValue({
                  ...params,
                  value: Number(e),
                });
              }}
            />
          </Box>
        );
      },
      valueFormatter: (p) => `${currencyFormatter.format(p.value ?? 0)}`,
    },
  ];

  if (myAccess !== user) {
    columns.push({
      field: "actions",
      headerName: "",
      align: "right",
      type: "actions",
      width: getWidthFromLocalStorage("payable", "actions", 40),
      getActions: (p) => [
        <GridActionsCellItem
          key="Delete"
          disabled={locked}
          onClick={() => dispatch(deleteStagedPayableLineItem(p.row))}
          icon={<i className="fa-solid fa-xmark"></i>}
          label={"Delete"}
          color="inherit"
        />,
      ],
    });
  }

  return (
    <>
      <DataGridPro
        autoHeight
        onCellClick={(e) => {
          if (apiRef.current.getCellMode(e.id, e.field) === "view")
            apiRef.current?.startCellEditMode({ id: e.id, field: e.field });
        }}
        onColumnWidthChange={(p) =>
          localStorage.setItem(`payable_${p.colDef.field}_width`, `${p.width}`)
        }
        processRowUpdate={lineItemUpdate}
        rows={lineItems}
        rowReordering={!stagedPayable?.locked && myAccess !== user}
        columns={columns}
        onRowOrderChange={lineItemPositionChanged}
        experimentalFeatures={{ newEditingApi: true }}
        apiRef={apiRef}
        disableSelectionOnClick
        density="compact"
        getCellClassName={(p) => (!p.isEditable ? "read-only" : "")}
        components={{
          Toolbar: CustomToolbar,
          Footer: CustomFooter,
        }}
        componentsProps={{ toolbar: {}, footer: {}, cell: { tabIndex: 1 } }}
        initialState={{
          pinnedColumns: {
            left: ["id"],
            right: ["amount", "actions"],
          },
        }}
      />
      <LineItemFiles />
    </>
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const stagedPayable = useAppSelector(stagedPayableSelector);
  const dispatch = useDispatch();
  const myAccess = useMyAccess();

  const locked = stagedPayable?.locked ?? false;

  const handleNewPayableLineItem = () => {
    dispatch(newStagedPayableLineItem());
  };

  useHotkeys(`ctrl+space`, handleNewPayableLineItem);

  return (
    <Box className="header" sx={{ display: "flex", p: 1 }}>
      <Tooltip title="ctrl + space">
        <Button
          disabled={locked || myAccess === user}
          size="small"
          onClick={handleNewPayableLineItem}
        >
          + New Line Item
        </Button>
      </Tooltip>
      <BatchLineItems disabled={locked} />
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  const stagedPayable = useAppSelector(stagedPayableSelector);

  return (
    <Box>
      <Divider />
      <Sheet
        variant="soft"
        color={stagedPayable?.balanceMatchesAmount() ? "success" : "danger"}
        className="footer"
        sx={{ display: "flex", p: 1, justifyContent: "space-between" }}
      >
        <Typography
          level="body2"
          color={stagedPayable?.balanceMatchesAmount() ? "success" : "danger"}
        >
          {!(stagedPayable?.balanceMatchesAmount() ?? false)
            ? `Balance doesn't match the amount:`
            : "Balance matches the amount."}
          {!stagedPayable?.balanceMatchesAmount() ? (
            <>
              <br />
              <b>Amount: </b>
              {currencyFormatter.format(stagedPayable?.amount ?? 0)}
              <br />
              <b>Remaining:</b>{" "}
              {currencyFormatter.format(
                (stagedPayable?.amount ?? 0) - (stagedPayable?.balance() ?? 0)
              )}
            </>
          ) : (
            []
          )}
        </Typography>
        <Typography
          level="body2"
          color={stagedPayable?.balanceMatchesAmount() ? "success" : "danger"}
        >
          <b>Total:</b>
          {stagedPayable && currencyFormatter.format(stagedPayable?.balance())}
        </Typography>
      </Sheet>
    </Box>
  );
}

/**
 * ItemClassInputCell.
 *
 * @param {GridRenderCellParams<number>} props grid params
 * @returns {ReactElement} The autocomplete
 */
export function ItemClassInputCell(props: GridRenderCellParams<number>) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();
  const stagedPayable = useAppSelector(stagedPayableSelector);

  return (
    <ItemClassAutocomplete
      itemClassID={value}
      size="small"
      filter={(e) => e.companyID === stagedPayable?.companyID}
      onChange={(e) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: e?.id,
        });
        if (e) apiRef.current.stopCellEditMode({ id, field });
      }}
    />
  );
}

/**
 * GLAccountInputCell.
 *
 * @param {GridRenderCellParams<number>} props grid params
 * @returns {ReactElement} The autocomplete
 */
export function GLAccountInputCell(props: GridRenderCellParams<number>) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();
  const stagedPayable = useAppSelector(stagedPayableSelector);

  return (
    <GlAccountAutocomplete
      glAccountID={value}
      size="small"
      filter={(e) => e.companyID === stagedPayable?.companyID}
      onChange={(e) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: e?.id,
        });
        if (e) apiRef.current.stopCellEditMode({ id, field });
      }}
    />
  );
}
