import { createReducer } from "@reduxjs/toolkit";
import { ApExport } from "../../entities/apExport";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteApExportSuccess,
  getApExportByIdSuccess,
  getApExportsSearchMetadataSuccess,
  getApExportsSuccess,
  setSelectedApExportID,
} from "./apExportActions";
import { ApExportState } from "./apExportState";

function initState(): ApExportState {
  return {
    apExports: {},
    searchMetadata: undefined,
    selectedApExportID: undefined,
  };
}

export const apExportReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedApExportID, (state, action) => {
    state.selectedApExportID = action.payload.apExportID;
    return state;
  });
  builder.addCase(getApExportsSuccess, (state, action) => {
    const apExports = action.payload.apExports;
    state.apExports = arrayToMap<ApExport>(apExports);
    return state;
  });
  builder.addCase(getApExportsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.apExportsSearchMetadata;
    return state;
  });
  builder.addCase(deleteApExportSuccess, (state, action) => {
    const apExportID = action.payload.id;
    if (state.apExports[apExportID]) {
      delete state.apExports[apExportID];
    }
    return state;
  });
  builder.addCase(getApExportByIdSuccess, (state, action) => {
    const apExport = action.payload.apExport;
    if (apExport) {
      const apExports = { ...state.apExports };
      apExports[apExport.id] = apExport;
      state.apExports = apExports;
    }
    return state;
  });
});
