import { Paper } from "@mui/material";
import { ReportsDataGrid } from "../hooks/report/reportDataGrid";

/**
 *
 * @returns {ReactElement} Reports page
 */
export function Reports() {
  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <ReportsDataGrid />
    </Paper>
  );
}
