import { User, User_Entity } from "@nerdjs/account-kit";
import moment from "moment";
import { getEmptyPayable } from "../../helpers/accountingHelpers";
import { Company } from "../company";
import { File } from "../file";
import { LineItem } from "../lineItem";
import { PriorityLevel } from "../priorityLevel";
import Payable_Entity from "./payable";
export default class Payable extends Payable_Entity {
  priorityLevel?: PriorityLevel;
  lineItems: Array<LineItem>;
  files: Array<File>;
  createdBy?: User;
  dualAssignedTo?: User;
  assignedTo?: User;

  constructor(payableJson: Payable_Entity) {
    super(payableJson);
    if (payableJson.priorityLevel)
      this.priorityLevel = new PriorityLevel(payableJson.priorityLevel);
    if (payableJson.lineItems) {
      this.lineItems = LineItem.fromList(payableJson.lineItems);
    } else {
      this.lineItems = [];
    }
    if (payableJson.files) {
      this.files = File.fromList(payableJson.files);
    } else {
      {
        this.files = [];
      }
    }
    if (payableJson.createdBy) this.createdBy = new User(payableJson.createdBy);
    if (payableJson.dualAssignedTo)
      this.dualAssignedTo = new User(payableJson.dualAssignedTo);
    if (payableJson.assignedTo)
      this.assignedTo = new User(payableJson.assignedTo);
  }

  static fromList(payablesJSON: unknown): Array<Payable> {
    const payables: Payable[] = [];
    if (payablesJSON)
      Array.isArray(payablesJSON) &&
        payablesJSON.forEach((payableJSON) => {
          payables.push(new Payable(payableJSON));
        });
    return payables;
  }

  isValid(): boolean {
    if (!this.amount) return false;
    if (!this.companyID) return false;
    if (!this.referenceNumber) return false;
    if (!this.vendorID) return false;
    return true;
  }

  errorOnDate(company: Company | undefined): boolean {
    if (!company) return false;
    return (
      (!this.locked &&
        moment(this.date).isBefore(moment(company?.glLockDate?.date))) ||
      moment(this.date).format("MM/DD/YYYY") ===
        moment(company?.glLockDate?.date).format("MM/DD/YYYY")
    );
  }

  dueDateDaysDifference() {
    if (this.date && this.dueDate) {
      const a = moment(this.date);
      const b = moment(this.dueDate);
      const diff = b.diff(a, "day");
      return `${diff >= 0 ? "+" : ""} ${diff} day${diff > 1 ? "s" : ""}`;
    }

    return "";
  }

  charLeft() {
    let limit = 20;
    if (this.transactionType === 3 || this.transactionType == 1) {
      limit = 11;
    }

    if (this.referenceNumber.length <= limit) {
      return `${limit - this.referenceNumber.length} char left`;
    }

    return "Too long!";
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  balance(): number {
    let balance = 0;
    this.lineItems
      ?.filter((li) => !li.toDelete)
      .forEach((li) => (balance += li.amount ?? 0));
    return Math.round(balance * 100) / 100;
  }

  balanceMatchesAmount(): boolean {
    const balance = this.balance();
    return balance === this.amount;
  }

  canApprove(user?: User_Entity): boolean {
    if (!user) return false;
    return (
      this.id != undefined &&
      this.assignedToUUID === user.uuid &&
      !this.approved
    );
  }

  canDualApprove(user?: User_Entity): boolean {
    if (!user) return false;
    return (
      this.id != undefined &&
      this.dualAssignedToUUID === user.uuid &&
      !this.dualApproved
    );
  }

  duplicate(): Payable_Entity {
    const ret = getEmptyPayable();
    ret.lineItems = [...this.lineItems].sort(
      (a, b) => (a?.position ?? 0) - (b?.position ?? 0)
    );
    ret.companyID = this.companyID;
    ret.companyName = this.companyName;
    ret.transactionType = this.transactionType;
    ret.priorityLevelID = this.priorityLevelID;
    ret.priorityLevel = this.priorityLevel;
    ret.vendorID = this.vendorID;
    ret.vendorName = this.vendorName;
    ret.glAccountID = this.glAccountID;
    if (this.assignedTo) ret.assignedTo = { ...this.assignedTo };
    ret.assignedToUUID = this.assignedToUUID;
    ret.dualAssignedTo = this.dualAssignedTo;
    if (this.dualAssignedTo) ret.dualAssignedTo = { ...this.dualAssignedTo };
    ret.dualAssignedToUUID = this.dualAssignedToUUID;
    ret.amount = this.amount;
    ret.glEntryID = this.glEntryID;
    ret.glTransactionID = this.glTransactionID;
    ret.memo = this.memo;

    for (const key in ret.lineItems) {
      if (Object.prototype.hasOwnProperty.call(ret.lineItems, key)) {
        ret.lineItems[key] = { ...ret.lineItems[key] };
        ret.lineItems[key] = {
          ...ret.lineItems[key],
          position: 10000 + Number(key) * 10000,
        };
        delete ret.lineItems[key].payableID;
        delete ret.lineItems[key].files;
        ret.lineItems[key].toCreate = true;
      }
    }

    return ret;
  }

  body(): Partial<Payable_Entity> {
    const body: Partial<Payable_Entity> = {
      assignedToUUID: this.assignedToUUID ?? null,
      dualAssignedToUUID: this.dualAssignedToUUID ?? null,
      dueDate: this.dueDate,
      companyID: this.companyID,
      vendorID: this.vendorID,
      amount: this.amount,
      date: this.date,
      memo: this.memo,
      referenceNumber: this.referenceNumber,
      priorityLevelID: this.priorityLevelID,
      transactionType: this.transactionType,
      glAccountID: this.glAccountID,
    };

    return body;
  }

  filesToCreate() {
    return this.files.filter((f) => f.toCreate && !f.toDelete);
  }

  filesToDelete() {
    return this.files.filter((f) => f.toDelete && !f.toCreate);
  }

  lineItemFilesToCreate() {
    const ret: File[] = [];
    this.lineItems.forEach((li) => ret.push(...li.filesToCreate()));
    return ret;
  }

  lineItemFilesToDelete() {
    const ret: File[] = [];
    this.lineItems.forEach((li) => ret.push(...li.filesToDelete()));
    return ret;
  }

  lineItemsToCreate() {
    return this.lineItems.filter((li) => li.toCreate && !li.toDelete);
  }

  lineItemsToDelete() {
    return this.lineItems.filter((li) => li.toDelete && !li.toCreate);
  }

  lineItemsToUpdate() {
    return this.lineItems.filter(
      (li) => li.toUpdate && !li.toDelete && !li.toCreate
    );
  }

  canClose() {
    if (this.toCreate) return false;
    if (this.toUpdate) return false;

    if (this.lineItemsToCreate().length) return false;
    if (this.lineItemsToDelete().length) return false;
    if (this.lineItemsToUpdate().length) return false;

    return true;
  }
}
