import { Button, IconButton, Tab, TabList, Tabs } from "@mui/joy";
import { Dialog, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { kindOfExportablePayables, transactionTypes } from "../../constants";
import { createApExport } from "../../redux/apExport/apExportActions";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import {
  getPayablesReadyToExport,
  getPayablesReadyToExportSuccess,
} from "../../redux/payable/payableActions";
import { payablesReadyToExportSelector } from "../../redux/payable/payableSelector";
import { CompanyAutocomplete } from "../company/companyAutocomplete";
import { PayablesDataGrid } from "./payableDataGrid";

/**
 *
 * @returns {ReactElement} PayableExport page
 */
export function PayableExport() {
  const dispatch = useDispatch();
  const open = useAppSelector(formOpenSelector("export"));

  const [companyID, setCompanyID] = useState<number | undefined>();
  const [transactionType, setTransactionType] = useState<number | undefined>(0);
  const [mine, setMine] = useState<number | undefined>(0);
  const [selectedPayables, setSelectedPayables] = useState<number[]>([]);
  const payablesReadyToExport = useAppSelector(payablesReadyToExportSelector);

  useEffect(() => {
    return () => {
      dispatch(getPayablesReadyToExportSuccess());
    };
  }, []);

  const close = () => {
    dispatch(setFormOpen(false, "export"));
  };

  const getExportablePayables = () => {
    dispatch(
      getPayablesReadyToExport(companyID!, transactionType!, mine === 0)
    );
  };

  const exportNow = () => {
    if (selectedPayables.length) {
      const body = {
        companyID,
        transactionType,
        payableIDs: selectedPayables,
      };
      dispatch(createApExport(body));
      dispatch(setFormOpen(false, "export"));
    }
  };

  const beforeYouExport = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
        margin: "auto",
      }}
    >
      <i style={{ fontSize: 32 }} className="fa-regular fa-file-export"></i>
      <Box>
        <Typography variant="h4">Before you export Payables...</Typography>
        <Typography>
          Please select the company you wish to export payables from:
        </Typography>
      </Box>
      <CompanyAutocomplete
        companyID={companyID}
        size="small"
        onChange={(e) => (e ? setCompanyID(e.id) : setCompanyID(undefined))}
      />
      <Typography>What is the kind of export?</Typography>
      <Box sx={{ display: "block" }}>
        <Tabs
          aria-label="Basic tabs"
          size="sm"
          value={transactionType}
          onChange={(e, v) => setTransactionType(v as number)}
          sx={{ borderRadius: "lg" }}
        >
          <TabList>
            {transactionTypes.map((t) => (
              <Tab key={t.id} value={t.id}>
                {t.name}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>
      <Typography> What payables do you want to export?</Typography>
      <Box sx={{ display: "block" }}>
        <Tabs
          aria-label="Basic tabs"
          size="sm"
          value={mine}
          onChange={(e, v) => setMine(v as number)}
          sx={{ borderRadius: "lg" }}
        >
          <TabList>
            {kindOfExportablePayables.map((t) => (
              <Tab key={t.id} value={t.id}>
                {t.name}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Box>
      <Button
        disabled={
          !companyID || transactionType === undefined || mine === undefined
        }
        onClick={getExportablePayables}
      >
        Go to Selection
      </Button>
    </Box>
  );

  const selection = (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        p: 2,
        flex: 1,
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h4">Select the payables to export:</Typography>
      <PayablesDataGrid
        select
        payables={payablesReadyToExport}
        noToolbar
        noFooter
        onSelect={(p) => setSelectedPayables(p as number[])}
      />
      <Button onClick={exportNow} disabled={selectedPayables.length === 0}>
        Export Now
      </Button>
    </Box>
  );

  return (
    <Dialog open={open} onClose={close} fullScreen>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          position: "relative",
          justifyContent: "center",
          alignItems: "stretch",
          flexDirection: "column",
        }}
      >
        <Tooltip title="Cancel Export">
          <IconButton
            color="neutral"
            size="lg"
            onClick={close}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <i className="fa-solid fa-xmark"></i>
          </IconButton>
        </Tooltip>
        {payablesReadyToExport === undefined ? beforeYouExport : selection}
      </Box>
    </Dialog>
  );
}
