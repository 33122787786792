import {
  createEntityAdapter,
  EntityState,
  createSelector,
  configureStore,
} from "@reduxjs/toolkit";
import { ApExport_Entity } from "../../entities/apExport";
import { apDashboardApi } from "../api/apDashboardApi";

const apExportsAdapter = createEntityAdapter<ApExport_Entity>();
const initialState = apExportsAdapter.getInitialState();

export const apExportEndpoints = apDashboardApi.injectEndpoints({
  endpoints: (build) => ({
    getApExports: build.query<EntityState<ApExport_Entity>, void>({
      query: () => `exports`,
      transformResponse: (responseData: ApExport_Entity[]) => {
        return apExportsAdapter.setAll(initialState, responseData);
      },
      providesTags: ["apExports"],
    }),
    getApExport: build.query<ApExport_Entity, number>({
      query: (id) => `exports/${id}`,
      providesTags: (result, error, id) => [{ type: "apExports", id }],
    }),
    createApExport: build.mutation<ApExport_Entity, Partial<ApExport_Entity>>({
      query: (body) => ({
        method: "POST",
        body,
        url: `apExports`,
      }),
      invalidatesTags: ["apExports"],
    }),
    updateApExport: build.mutation<
      void,
      { id: number; body: Partial<ApExport_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `exports/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "apExports", id },
        "apExports",
      ],
    }),
    deleteApExport: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `exports/${id}`,
      }),
      invalidatesTags: ["apExports"],
    }),
  }),
});

export const {
  useGetApExportQuery,
  useGetApExportsQuery,
  useCreateApExportMutation,
  useDeleteApExportMutation,
  useUpdateApExportMutation,
} = apExportEndpoints;

export default apExportEndpoints;

export const selectApExportsResult =
  apExportEndpoints.endpoints.getApExports.select();

const selectApExportsData = createSelector(
  selectApExportsResult,
  (apExportsResult) => apExportsResult.data
);

const store = configureStore({
  reducer: {
    [apDashboardApi.reducerPath]: apDashboardApi.reducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const { selectAll: selectAllApExports, selectById: selectApExportById } =
  apExportsAdapter.getSelectors<RootState>(
    (state) => selectApExportsData(state) ?? initialState
  );
