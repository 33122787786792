import { createSelector } from "@reduxjs/toolkit";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectSearchPresets = (state: AppState) =>
  state.searchPresetState.searchPresets;
const selectSelectedSearchPresetID = (state: AppState) =>
  state.searchPresetState.selectedSearchPresetID;

export const searchPresetsSelector = createSelector(
  [selectSearchPresets],
  (searchPresets) => mapToArray(searchPresets)
);

export const searchPresetsForKeywordSelector = (keyword?: string) => {
  return createSelector([selectSearchPresets], (searchPresets) => {
    if (keyword)
      return mapToArray(searchPresets).filter((s) => s.keyword === keyword);
    return [];
  });
};

export const selectedSearchPresetSelector = createSelector(
  [selectSearchPresets, selectSelectedSearchPresetID],
  (searchPresets, selectedSearchPresetID) => {
    if (selectedSearchPresetID) return searchPresets[selectedSearchPresetID];
    return undefined;
  }
);

export const searchPresetSelector = (id?: number) => {
  return createSelector([selectSearchPresets], (searchPresets) => {
    if (id) return searchPresets[id];
    return undefined;
  });
};
