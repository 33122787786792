import { Filters, SearchMetadata } from "@nerdjs/nerd-core";
import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { File_Entity } from "../../entities/file";
import { JournalEntry_Entity } from "../../entities/journalEntry";
import { JournalEntryItem_Entity } from "../../entities/journalEntryItem";

export const stagedJournalEntryUpdateRequest = createAction(
  "stagedJournalEntryUpdateRequest",
  function prepare(
    navigate: NavigateFunction,
    close?: boolean,
    body?: Partial<JournalEntry_Entity>,
    callback?: () => void
  ) {
    return {
      payload: {
        navigate,
        close,
        body,
        callback,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const stagedJournalEntryCreateRequest = createAction(
  "stagedJournalEntryCreateRequest",
  function prepare(navigate: NavigateFunction, close?: boolean) {
    return {
      payload: {
        navigate,
        close,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setStagedJournalEntry = createAction(
  "setStagedJournalEntry",
  function prepare(stagedJournalEntry?: JournalEntry_Entity) {
    return {
      payload: {
        stagedJournalEntry,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setJournalEntryLoading = createAction(
  "setJournalEntryLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setStagedJournalEntryLoading = createAction(
  "setStagedJournalEntryLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setJournalEntrySearchOpen = createAction(
  "setJournalEntrySearchOpen",
  function prepare(searchOpen: boolean) {
    return {
      payload: {
        searchOpen,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setJournalEntryFilters = createAction(
  "setJournalEntryFilters",
  function prepare(filters?: Filters) {
    return {
      payload: {
        filters,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedJournalEntryLineItem = createAction(
  "updateStagedJournalEntryLineItem",
  function prepare(lineItem?: JournalEntryItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const reorderStagedJournalEntryLineItems = createAction(
  "reorderStop",
  function prepare(oldIndex: number, targetIndex: number) {
    return {
      payload: {
        oldIndex,
        targetIndex,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteStagedJournalEntryLineItem = createAction(
  "deleteStagedJournalEntryLineItem",
  function prepare(lineItem?: JournalEntryItem_Entity) {
    return {
      payload: {
        lineItem,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteStagedJournalEntryFile = createAction(
  "deleteStagedJournalEntryFile",
  function prepare(file: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const newStagedJournalEntryFile = createAction(
  "newStagedJournalEntryFile",
  function prepare(file: File_Entity) {
    return {
      payload: {
        file,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const newStagedJournalEntryLineItem = createAction(
  "newStagedJournalEntryLineItem",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateStagedJournalEntry = createAction(
  "updateStagedJournalEntry",
  function prepare(stagedJournalEntry?: Partial<JournalEntry_Entity>) {
    return {
      payload: {
        stagedJournalEntry,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSelectedJournalEntryID = createAction(
  "setSelectedJournalEntryID",
  function prepare(journalEntryID?: number) {
    return {
      payload: {
        journalEntryID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntries = createAction(
  "ap-dashboard/journalEntry/getJournalEntries",
  function prepare() {
    const request = new GetAction(
      `/ap-dashboard/journalEntries?limit=25&sorter=[{"name":"journalEntries.id","asc":false}]`
    );

    request.decodeResponse = (
      dispatch: Dispatch,
      json: JournalEntry_Entity[]
    ) => {
      dispatch(getJournalEntriesSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntriesSuccess = createAction(
  "ap-dashboard/journalEntry/getJournalEntrySuccess",
  function prepare(journalEntries: JournalEntry_Entity[]) {
    return {
      payload: {
        journalEntries,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntriesByFilter = createAction(
  "ap-dashboard/journalEntry/getJournalEntriesByFilter",
  function prepare(query: { [key: string]: unknown }) {
    if (!query.limit) query.limit = 25;
    if (!query.offset) query.offset = 0;
    if (!query.sorter)
      query.sorter = JSON.stringify([
        { name: "journalEntries.id", asc: false },
      ]);
    const request = new GetAction(`/ap-dashboard/journalEntries`, query);

    request.decodeResponse = (
      dispatch: Dispatch,
      json: JournalEntry_Entity[]
    ) => {
      dispatch(getJournalEntriesSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntriesByFilterSuccess = createAction(
  "ap-dashboard/journalEntry/getJournalEntriesByFilterSuccess",
  function prepare(journalEntries: JournalEntry_Entity[]) {
    return {
      payload: {
        journalEntries,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntryById = createAction(
  "ap-dashboard/journalEntry/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/journalEntries/${id}`);

    request.decodeResponse = (
      dispatch: Dispatch,
      json: JournalEntry_Entity
    ) => {
      dispatch(getJournalEntryByIdSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntryByIdSuccess = createAction(
  "ap-dashboard/journalEntry/getJournalEntryByIdSuccess",
  function prepare(journalEntry?: JournalEntry_Entity) {
    return {
      payload: {
        journalEntry,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteJournalEntry = createAction(
  "ap-dashboard/removeJournalEntryById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/journalEntries/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteJournalEntrySuccess = createAction(
  "ap-dashboard/journalEntries/deleteJournalEntrySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateJournalEntry = createAction(
  "ap-dashboard/journalEntries/updateJournalEntry",
  function prepare(id: number, body: Partial<JournalEntry_Entity>) {
    const request = new PutAction(`/ap-dashboard/journalEntries/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateJournalEntrySuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const lockJournalEntry = createAction(
  "ap-dashboard/journalEntries/lockJournalEntry",
  function prepare(
    id: number,
    body: {
      locked: boolean;
    }
  ) {
    const request = new PostAction(
      `/ap-dashboard/journalEntries/${id}/lock`,
      body
    );

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(lockJournalEntrySuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const lockJournalEntrySuccess = createAction(
  "ap-dashboard/journalEntries/lockJournalEntrySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateJournalEntrySuccess = createAction(
  "ap-dashboard/journalEntries/updateJournalEntrySuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createJournalEntry = createAction(
  "ap-dashboard/journalEntries/createJournalEntry",
  function prepare(body: Partial<JournalEntry_Entity>) {
    const request = new PostAction(`/ap-dashboard/journalEntries`, body);

    request.decodeResponse = (
      dispatch: Dispatch,
      json: JournalEntry_Entity
    ) => {
      dispatch(createJournalEntrySuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

// POST new JournalEntry files
export const uploadJournalEntryfile = createAction(
  "ap-dashboard/journalEntries/uploadJournalEntryfile",
  function prepare(id: number, file: File) {
    const data = new FormData();
    data.append("file", file);

    const request = new PostAction(
      `/ap-dashboard/journalEntries/${id}/upload`,
      data
    );

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(uploadJournalEntryFilesSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const uploadJournalEntryFilesSuccess = createAction(
  "ap-dashboard/journalEntries/uploadJournalEntryFilesSuccess",
  function prepare(isFileUploadOk: boolean) {
    return {
      payload: {
        isFileUploadOk,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

// DELETE new JournalEntry files
export const deleteJournalEntryfile = createAction(
  "ap-dashboard/journalEntries/deleteJournalEntryfile",
  function prepare(journalEntryID: number, fileID: number) {
    const request = new DeleteAction(
      `/ap-dashboard/journalEntries/${journalEntryID}/files/${fileID}`
    );

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(deleteJournalEntryFilesSuccess());
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteJournalEntryFilesSuccess = createAction(
  "ap-dashboard/journalEntries/deleteJournalEntryFilesSuccess",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createJournalEntrySuccess = createAction(
  "ap-dashboard/journalEntries/createJournalEntrySuccess",
  function prepare(journalEntry: JournalEntry_Entity) {
    return {
      payload: {
        journalEntry,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntriesSearchMetadata = createAction(
  "ap-dashboard/getJournalEntriesSearchMetadata",
  function prepare() {
    const request = new GetAction(
      `/ap-dashboard/journalEntries/searchMetadata`
    );

    request.decodeResponse = (dispatch: Dispatch, json: SearchMetadata) => {
      dispatch(getJournalEntriesSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntriesSearchMetadataSuccess = createAction(
  "ap-dashboard/getJournalEntriesSearchMetadataSuccess",
  function prepare(journalEntriesSearchMetadata: SearchMetadata) {
    return {
      payload: {
        journalEntriesSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getJournalEntriesReadyToExport = createAction(
  "ap-dashboard/journalEntry/getJournalEntriesByFilter",
  function prepare(companyID: number) {
    const filters = [
      { name: "journalEntries.companyID", comparison: "eq", value: companyID },
      { name: "journalEntries.locked", comparison: "eq", value: true },
      { name: "journalEntries.exportID", comparison: "eq", value: 0 },
    ];
    const query = { filters: JSON.stringify(filters) };
    const request = new GetAction(`/ap-dashboard/journalEntries`, query);

    request.decodeResponse = (
      dispatch: Dispatch,
      json: JournalEntry_Entity[]
    ) => {
      dispatch(getJournalEntriesReadyToExportSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getJournalEntriesReadyToExportSuccess = createAction(
  "ap-dashboard/journalEntry/getJournalEntriesReadyToExportSuccess",
  function prepare(journalEntries?: JournalEntry_Entity[]) {
    return {
      payload: {
        journalEntries,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
