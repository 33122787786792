import { User, User_Entity } from "@nerdjs/account-kit";
import { File_Entity } from "../file";
import { JournalEntryItem_Entity } from "../journalEntryItem";

/**
 * JournalEntry_Entity type guard.
 *
 * @param {any} journalEntryJson JournalEntry object from API
 * @returns {boolean} Return true if type is JournalEntry_Entity
 */
function validator(
  journalEntryJson: unknown
): journalEntryJson is JournalEntry_Entity {
  if (typeof journalEntryJson === "object" && journalEntryJson != null) {
    JournalEntry_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(journalEntryJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class JournalEntry_Entity {
  static requiredFields = [
    "id",
    "companyID",
    "companyName",
    "referenceNumber",
    "memo",
    "createdByUUID",
    "glTransactionID",
    "locked",
    "deleted",
    "exportID",
    "files",
    "journalEntryItems",
    "createdAt",
    "updatedAt",
  ];

  constructor(journalEntryJson: unknown) {
    if (validator(journalEntryJson)) {
      this.id = journalEntryJson.id;
      this.companyID = journalEntryJson.companyID;
      this.companyName = journalEntryJson.companyName;
      this.date = journalEntryJson.date;
      this.referenceNumber = journalEntryJson.referenceNumber;
      this.memo = journalEntryJson.memo;
      this.createdByUUID = journalEntryJson.createdByUUID;
      this.createdBy = journalEntryJson.createdBy;
      this.glTransactionID = journalEntryJson.glTransactionID;
      this.locked = journalEntryJson.locked;
      this.deleted = journalEntryJson.deleted;
      this.exportID = journalEntryJson.exportID;
      this.lockedDate = journalEntryJson.lockedDate;
      this.files = journalEntryJson.files;
      this.journalEntryItems = journalEntryJson.journalEntryItems;
      this.createdAt = journalEntryJson.createdAt;
      this.updatedAt = journalEntryJson.updatedAt;
      this.toUpdate = journalEntryJson.toUpdate;
      this.toCreate = journalEntryJson.toCreate;
      this.toDelete = journalEntryJson.toDelete;
    } else {
      throw new Error(
        "Failed to create new instance of ${ JournalEntry_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  companyID?: number;
  companyName: string;
  date?: string;
  referenceNumber: string;
  memo: string;
  createdByUUID?: string;
  createdBy?: User_Entity;
  glTransactionID?: number;
  locked: boolean;
  deleted: boolean;
  exportID?: number;
  lockedDate?: string;
  files: Array<File_Entity>;
  journalEntryItems: JournalEntryItem_Entity[];
  createdAt?: string;
  updatedAt?: string;
  toUpdate?: boolean;
  toCreate?: boolean;
  toDelete?: boolean;
}
