import {
  accountKitApi,
  accountKitReducer,
  AccountKitState,
} from "@nerdjs/account-kit";
import {
  networkMiddleware,
  networkReducer,
  NetworkStateExpanded,
} from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { apExportReducer } from "./apExport/apExportReducer";
import { ApExportState } from "./apExport/apExportState";
import { apDashboardApi } from "./api/apDashboardApi";
import { appStatusReducer } from "./appStatus/appStatusReducer";
import { AppStatusState } from "./appStatus/appStatusState";
import { companyReducer } from "./company/companyReducer";
import { CompanyState } from "./company/companyState";
import { glAccountReducer } from "./glAccount/glAccountReducer";
import { GlAccountState } from "./glAccount/glAccountState";
import { itemClassReducer } from "./itemClass/itemClassReducer";
import { ItemClassState } from "./itemClass/itemClassState";
import { journalEntryReducer } from "./journalEntry/journalEntryReducer";
import { JournalEntryState } from "./journalEntry/journalEntryState";
import { journalEntryItemReducer } from "./journalEntryItem/journalEntryItemReducer";
import { JournalEntryItemState } from "./journalEntryItem/journalEntryItemState";
import { lineItemReducer } from "./lineItem/lineItemReducer";
import { LineItemState } from "./lineItem/lineItemState";
import { mainMiddleware } from "./mainMiddleware";
import { payableReducer } from "./payable/payableReducer";
import { PayableState } from "./payable/payableState";
import { priorityLevelReducer } from "./priorityLevel/priorityLevelReducer";
import { PriorityLevelState } from "./priorityLevel/priorityLevelState";
import { searchPresetReducer } from "./searchPreset/searchPresetReducer";
import { SearchPresetState } from "./searchPreset/searchPresetState";
import { stagedJournalEntryMiddleware } from "./stagedJournalEntryMiddleware";
import { stagedPayableMiddleware } from "./stagedPayableMiddleware";
import { stagedVendorMiddleware } from "./stagedVendorMiddleware";
import { userReducer } from "./user/userReducer";
import { UserState } from "./user/userState";
import { vendorReducer } from "./vendor/vendorReducer";
import { VendorState } from "./vendor/vendorState";
import { vendorLineItemReducer } from "./vendorLineItem/vendorLineItemReducer";
import { VendorLineItemState } from "./vendorLineItem/vendorLineItemState";
import thunkMiddleware from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

export interface AppState {
  accountKitState: AccountKitState;
  networkState: NetworkStateExpanded;

  companyState: CompanyState;
  glAccountState: GlAccountState;
  itemClassState: ItemClassState;
  journalEntryState: JournalEntryState;
  journalEntryItemState: JournalEntryItemState;
  lineItemState: LineItemState;
  payableState: PayableState;
  vendorState: VendorState;
  priorityLevelState: PriorityLevelState;
  apExportState: ApExportState;
  userState: UserState;
  searchPresetState: SearchPresetState;
  appStatusState: AppStatusState;
  vendorLineItemState: VendorLineItemState;
}

const appReducer = combineReducers({
  accountKitState: accountKitReducer,
  notification: notificationReducer,
  networkState: networkReducer,

  [apDashboardApi.reducerPath]: apDashboardApi.reducer,
  companyState: companyReducer,
  glAccountState: glAccountReducer,
  itemClassState: itemClassReducer,
  journalEntryState: journalEntryReducer,
  journalEntryItemState: journalEntryItemReducer,
  lineItemState: lineItemReducer,
  payableState: payableReducer,
  vendorState: vendorReducer,
  priorityLevelState: priorityLevelReducer,
  apExportState: apExportReducer,
  userState: userReducer,
  searchPresetState: searchPresetReducer,
  appStatusState: appStatusReducer,
  vendorLineItemState: vendorLineItemReducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
});

export const store = configureStore({
  reducer: appReducer,
  middleware: [
    thunkMiddleware,
    apDashboardApi.middleware,
    accountKitApi.middleware,
    stagedPayableMiddleware,
    stagedJournalEntryMiddleware,
    stagedVendorMiddleware,
    mainMiddleware,
    networkMiddleware,
  ],
});

export type AppDispatch = typeof store.dispatch;
