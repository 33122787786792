/**
 * ItemClass_Entity type guard.
 *
 * @param {any} itemClassJson ItemClass object from API
 * @returns {boolean} Return true if type is ItemClass_Entity
 */
function validator(itemClassJson: unknown): itemClassJson is ItemClass_Entity {
  if (typeof itemClassJson === "object" && itemClassJson != null) {
    ItemClass_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(itemClassJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class ItemClass_Entity {
  static requiredFields = [
    "id",
    "companyID",
    "companyName",
    "name",
    "superClass",
    "subClass",
    "createdAt",
    "updatedAt",
    "refNum",
    "timestamp",
    "hidden",
  ];

  constructor(itemClassJson: unknown) {
    if (validator(itemClassJson)) {
      this.id = itemClassJson.id;
      this.companyID = itemClassJson.companyID;
      this.companyName = itemClassJson.companyName;
      this.name = itemClassJson.name;
      this.superClass = itemClassJson.superClass;
      this.subClass = itemClassJson.subClass;
      this.createdAt = itemClassJson.createdAt;
      this.updatedAt = itemClassJson.updatedAt;
      this.refNum = itemClassJson.refNum;
      this.timestamp = itemClassJson.timestamp;
      this.hidden = itemClassJson.hidden;
    } else {
      throw new Error(
        "Failed to create new instance of ${ ItemClass_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  companyID: number;
  companyName: string;
  name: string;
  superClass: string;
  subClass: string;
  createdAt: string;
  updatedAt: string;
  refNum: number;
  timestamp: number;
  hidden: boolean;
}
