import { createReducer } from "@reduxjs/toolkit";
import { File_Entity } from "../../entities/file";
import { JournalEntry_Entity } from "../../entities/journalEntry";
import {
  JournalEntryItem,
  JournalEntryItem_Entity,
} from "../../entities/journalEntryItem";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteJournalEntrySuccess,
  deleteStagedJournalEntryFile,
  deleteStagedJournalEntryLineItem,
  getJournalEntryByIdSuccess,
  getJournalEntriesSearchMetadataSuccess,
  getJournalEntriesSuccess,
  newStagedJournalEntryFile,
  newStagedJournalEntryLineItem,
  reorderStagedJournalEntryLineItems,
  setJournalEntryFilters,
  setJournalEntryLoading,
  setJournalEntrySearchOpen,
  setSelectedJournalEntryID,
  setStagedJournalEntry,
  setStagedJournalEntryLoading,
  updateStagedJournalEntry,
  updateStagedJournalEntryLineItem,
  getJournalEntriesReadyToExportSuccess,
} from "./journalEntryActions";
import { JournalEntryState } from "./journalEntryState";

function initState(): JournalEntryState {
  return {
    journalEntries: {},
    journalEntriesReadyToExport: {},
    searchOpen: false,
    searchMetadata: undefined,
    loading: false,
    stagedJournalEntryLoading: false,
    stagedJournalEntry: undefined,
    selectedJournalEntryID: undefined,
  };
}

export const journalEntryReducer = createReducer(initState(), (builder) => {
  builder.addCase(setStagedJournalEntry, (state, action) => {
    if (action.payload.stagedJournalEntry) {
      state.stagedJournalEntry = { ...action.payload.stagedJournalEntry };
    } else {
      state.stagedJournalEntry = undefined;
    }
    return state;
  });
  builder.addCase(getJournalEntriesReadyToExportSuccess, (state, action) => {
    const journalEntries = action.payload.journalEntries;
    if (journalEntries) {
      state.journalEntriesReadyToExport =
        arrayToMap<JournalEntry_Entity>(journalEntries);
    } else {
      state.journalEntriesReadyToExport = undefined;
    }
    return state;
  });
  builder.addCase(updateStagedJournalEntryLineItem, (state, action) => {
    if (action.payload.lineItem) {
      const lineItem: JournalEntryItem_Entity = { ...action.payload.lineItem };
      if (!state.stagedJournalEntry) return state;
      const index = state.stagedJournalEntry?.journalEntryItems.findIndex(
        (l) => l.id === lineItem.id
      );
      if (index >= 0) {
        state.stagedJournalEntry.journalEntryItems[index] = {
          ...state.stagedJournalEntry.journalEntryItems[index],
          ...lineItem,
          toUpdate: true,
        };
      }
    }
    return state;
  });
  builder.addCase(deleteStagedJournalEntryLineItem, (state, action) => {
    if (action.payload.lineItem) {
      const lineItem: JournalEntryItem_Entity = { ...action.payload.lineItem };
      if (!state.stagedJournalEntry) return state;
      const index = state.stagedJournalEntry?.journalEntryItems.findIndex(
        (l) => l.id === lineItem.id
      );
      if (index >= 0) {
        state.stagedJournalEntry.journalEntryItems[index].toDelete = true;
      }
    }
    return state;
  });
  builder.addCase(deleteStagedJournalEntryFile, (state, action) => {
    if (action.payload.file) {
      const file: File_Entity = { ...action.payload.file };
      if (!state.stagedJournalEntry) return state;
      const index = state.stagedJournalEntry?.files.findIndex(
        (l) => l.id === file.id
      );
      if (index >= 0) {
        state.stagedJournalEntry.files[index].toDelete = true;
      }
    }
    return state;
  });
  builder.addCase(setJournalEntryLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setStagedJournalEntryLoading, (state, action) => {
    state.stagedJournalEntryLoading = action.payload.loading;
    return state;
  });
  builder.addCase(setJournalEntryFilters, (state, action) => {
    state.filters = action.payload.filters;
    return state;
  });
  builder.addCase(setJournalEntrySearchOpen, (state, action) => {
    state.searchOpen = action.payload.searchOpen;
    return state;
  });
  builder.addCase(newStagedJournalEntryLineItem, (state) => {
    if (!state.stagedJournalEntry) return state;
    const lastLineItem = state.stagedJournalEntry.journalEntryItems?.length
      ? state.stagedJournalEntry.journalEntryItems[
          state.stagedJournalEntry.journalEntryItems.length - 1
        ]
      : undefined;
    const newLi: JournalEntryItem_Entity = {
      id: new Date().getTime(),
      classID: lastLineItem?.classID,
      glAccountID: lastLineItem?.glAccountID,
      position:
        (state.stagedJournalEntry.journalEntryItems?.length ?? 0) * 10000 +
        10000,
      toCreate: true,
    };
    if (state.stagedJournalEntry.journalEntryItems) {
      state.stagedJournalEntry.journalEntryItems.push(newLi);
    } else {
      state.stagedJournalEntry.journalEntryItems = [newLi];
    }
    return state;
  });
  builder.addCase(newStagedJournalEntryFile, (state, action) => {
    if (!state.stagedJournalEntry) return state;
    if (state.stagedJournalEntry.files) {
      state.stagedJournalEntry.files.push(action.payload.file);
    } else {
      state.stagedJournalEntry.files = [action.payload.file];
    }
    return state;
  });
  builder.addCase(reorderStagedJournalEntryLineItems, (state, action) => {
    const { oldIndex, targetIndex } = action.payload;
    if (!state.stagedJournalEntry) return state;
    const rowsClone = [...state.stagedJournalEntry.journalEntryItems];
    const row = rowsClone.splice(oldIndex, 1)[0];
    rowsClone.splice(targetIndex, 0, row);

    state.stagedJournalEntry.journalEntryItems = rowsClone;

    return state;
  });
  builder.addCase(updateStagedJournalEntry, (state, action) => {
    if (action.payload.stagedJournalEntry) {
      const update: Partial<JournalEntry_Entity> =
        action.payload.stagedJournalEntry;
      if (!state.stagedJournalEntry) return state;
      state.stagedJournalEntry = {
        ...state.stagedJournalEntry,
        ...update,
        toUpdate: true,
      };
    }
    return state;
  });
  builder.addCase(setSelectedJournalEntryID, (state, action) => {
    state.selectedJournalEntryID = action.payload.journalEntryID;
    return state;
  });
  builder.addCase(getJournalEntriesSuccess, (state, action) => {
    const journalEntries = action.payload.journalEntries;
    state.journalEntries = arrayToMap<JournalEntry_Entity>(journalEntries);
    return state;
  });
  builder.addCase(getJournalEntriesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.journalEntriesSearchMetadata;
    return state;
  });
  builder.addCase(deleteJournalEntrySuccess, (state, action) => {
    const journalEntryID = action.payload.id;
    if (state.journalEntries[journalEntryID]) {
      delete state.journalEntries[journalEntryID];
    }
    return state;
  });
  builder.addCase(getJournalEntryByIdSuccess, (state, action) => {
    const journalEntry = action.payload.journalEntry;
    if (journalEntry) {
      const journalEntries = { ...state.journalEntries };
      journalEntries[journalEntry.id] = journalEntry;
      state.journalEntries = journalEntries;
    }
    return state;
  });
});
