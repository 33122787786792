import { createSelector } from "@reduxjs/toolkit";
import { ApExport } from "../../entities/apExport";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectApExports = (state: AppState) => state.apExportState.apExports;
const selectSelectedApExportID = (state: AppState) =>
  state.apExportState.selectedApExportID;

export const apExportsSelector = createSelector(
  [selectApExports],
  (apExports) => {
    return ApExport.fromList(mapToArray(apExports)).sort((a, b) => b.id - a.id);
  }
);

export const selectedApExportSelector = createSelector(
  [selectApExports, selectSelectedApExportID],
  (apExports, selectedApExportID) => {
    if (selectedApExportID) return apExports[selectedApExportID];
    return undefined;
  }
);

export const apExportSelector = (id: number) => {
  return createSelector([selectApExports], (apExports) => apExports[id]);
};
