import { Box } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { ApExport } from "../../entities/apExport";
import { apExportsSelector } from "../../redux/apExport/apExportSelector";

/**
 *
 * @returns {ReactElement} ExportsDataGrid component
 */
export function ExportsDataGrid() {
  const apiRef = useGridApiRef();
  const exports = useAppSelector(apExportsSelector);
  const columns: GridColumns<ApExport> = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      valueGetter: (p) => p.row.createdBy?.fullname(),
    },
    {
      field: "Date",
      headerName: "Date",
      width: 100,
      valueGetter: (p) => moment(p.row.createdAt).format("MM/DD/YYYY"),
    },
  ];

  return (
    <DataGridPro
      rows={exports}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
      apiRef={apiRef}
      density="compact"
      getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
      components={{
        Toolbar: CustomToolbar,
        Footer: CustomFooter,
      }}
      componentsProps={{ toolbar: {}, footer: {} }}
      initialState={{
        pinnedColumns: {
          left: ["id"],
          right: ["actions"],
        },
      }}
    />
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewExport = () => {
    console.log("Do someting!", dispatch);
  };

  useHotkeys(`ctrl+enter`, handleNewExport);

  return (
    <Box className="header" sx={{ display: "flex" }}>
      <GridToolbarFilterButton />
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  return <Box className="footer" sx={{ display: "flex" }}></Box>;
}
