import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../atoms/hooks";
import { getEmptyJournalEntry } from "../helpers/accountingHelpers";
import { JournalEntriesDataGrid } from "../hooks/journalEntry/journalEntryDataGrid";
import { setFormOpen } from "../redux/appStatus/appStatusActions";
import {
  setSelectedJournalEntryID,
  setStagedJournalEntry,
} from "../redux/journalEntry/journalEntryActions";
import {
  selectedJournalEntryIDSelector,
  stagedJournalEntrySelector,
} from "../redux/journalEntry/journalEntrySelector";

/**
 *
 * @returns {ReactElement} JournalEntries page
 */
export function JournalEntries() {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedJournalEntryID = useAppSelector(selectedJournalEntryIDSelector);
  const stagedJournalEntry = useAppSelector(stagedJournalEntrySelector);

  useEffect(() => {
    if (params.journalEntryID) {
      if (params.journalEntryID === "new" && !stagedJournalEntry) {
        dispatch(setStagedJournalEntry(getEmptyJournalEntry()));
        dispatch(setFormOpen(true, "journalEntry"));
      }
      if (params.journalEntryID !== "new") {
        const journalEntryID = parseInt(params.journalEntryID);
        if (
          !isNaN(journalEntryID) &&
          journalEntryID != selectedJournalEntryID
        ) {
          dispatch(setSelectedJournalEntryID(journalEntryID));
          dispatch(setFormOpen(true, "journalEntry"));
        }
      }
    }
  }, [params]);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <JournalEntriesDataGrid />
    </Paper>
  );
}
