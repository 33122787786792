import {
  Button,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Typography,
} from "@mui/joy";
import ListDivider from "@mui/joy/ListDivider";
import { Box, Tooltip } from "@mui/material";
import { MouseEventHandler, useEffect, useState } from "react";
import { useAppSelector } from "../../atoms/hooks";
import { File as FileClass, File_Entity } from "../../entities/file";
import { NerdFileUpload } from "@nerdjs/nerd-ui";
import {
  stagedJournalEntryFilesSelector,
  stagedJournalEntrySelector,
} from "../../redux/journalEntry/journalEntrySelector";
import { useDispatch } from "react-redux";
import {
  deleteStagedJournalEntryFile,
  newStagedJournalEntryFile,
} from "../../redux/journalEntry/journalEntryActions";

export default function JournalEntryFiles() {
  const files = (useAppSelector(stagedJournalEntryFilesSelector) ??
    []) as FileClass[];
  const stagedJournalEntry = useAppSelector(stagedJournalEntrySelector);
  const [file, setFile] = useState<FileClass>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (files.length) {
      setFile(files[0]);
    }
  }, [files]);

  const upload = (
    <Box sx={{ flexShrink: 0 }}>
      <NerdFileUpload
        dropZoneLabel="File upload example"
        uploadFile={(e: File) => {
          const f: File_Entity = {
            id: new Date().getTime(),
            name: e.name,
            blob: URL.createObjectURL(e),
            file: e,
            toCreate: true,
          };
          dispatch(newStagedJournalEntryFile(f));
        }}
        hideFilesList
        dense
      />
    </Box>
  );

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {files.length ? (
        <Box sx={{ p: 1, gap: 1, display: "flex", overflowX: "scroll" }}>
          {upload}
          {files.map((f) => (
            <JournalEntryFile
              file={f}
              key={f.id}
              selected={f.id === file?.id}
              onSelect={(_file) => setFile(_file)}
            />
          ))}
        </Box>
      ) : (
        []
      )}
      <Box sx={{ flexGrow: 1, background: "#535659" }}>
        {file ? (
          <iframe
            src={file?.previewURL()}
            width="100%"
            height="100%"
            style={{ border: "none", flex: 1 }}
          />
        ) : (
          <Sheet
            variant="soft"
            color="primary"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography level="display2">
                <i className="fa-solid fa-paperclip"></i>
              </Typography>
              <Typography level="h5">
                <b>No Files</b>
              </Typography>
              <Typography level="body1">
                This journalEntry does not have any attachments.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography level="body2">
                  Drag & drop files in this window to upload.
                </Typography>

                <Box>{upload}</Box>
              </Box>
            </Box>
          </Sheet>
        )}
      </Box>
    </Box>
  );
}

function JournalEntryFile({
  file,
  selected,
  onSelect,
}: {
  file: FileClass;
  selected?: boolean;
  onSelect: (f?: FileClass) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick: MouseEventHandler<HTMLAnchorElement> = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(deleteStagedJournalEntryFile(file));
    if (selected) onSelect();
    handleClose();
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <Tooltip title={file.name}>
        <Button
          color={selected ? "primary" : "neutral"}
          variant={"soft"}
          size="sm"
          sx={{ minWidth: 100, maxWidth: 200 }}
          onClick={() => onSelect(file)}
          endDecorator={
            <IconButton
              size="sm"
              variant="outlined"
              onClick={handleClick}
              color={selected ? "primary" : "neutral"}
            >
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </IconButton>
          }
        >
          <Typography noWrap level="body2">
            {file.toDelete ? "[d]" : ""}
            {file.name}
          </Typography>
        </Button>
      </Tooltip>
      <Menu
        id="positioned-demo-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="positioned-demo-button"
        placement="bottom-end"
        size="sm"
      >
        <MenuItem
          onClick={() => {
            window.open(file.previewURL(), "_blank");
            handleClose();
          }}
        >
          <ListItemDecorator>
            <i className="fa-regular fa-arrow-up-right-from-square"></i>
          </ListItemDecorator>{" "}
          Open in new tab
        </MenuItem>
        <ListDivider />
        <MenuItem onClick={handleDelete} variant="soft" color="danger">
          <ListItemDecorator sx={{ color: "inherit" }}>
            <i className="fa-sharp fa-solid fa-trash"></i>
          </ListItemDecorator>{" "}
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
}
