import { NerdNavigationBar } from "@nerdjs/nerd-ui";
import { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterConfig } from "../config/routerConfig";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { useDispatch } from "react-redux";
import { useMyAccess } from "../../hooks/myAccess/myAccess";

/**
 *
 * @component
 * @example
 * <MainDrawer/>
 * @returns {ReactElement | null} MainDrawer component
 */
export function MainMenu(): ReactElement | null {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myAccess = useMyAccess();

  let leftItems = [
    {
      title: "My Payables",
      action: () => {
        navigate(RouterConfig.myPayables);
      },
      startDecorator: <i className="fa-duotone fa-receipt"></i>,
      selected: pathnames[0] === RouterConfig.myPayables.slice(1),
    },
    {
      title: "All Payables",
      action: () => {
        navigate(RouterConfig.payables);
      },
      startDecorator: <i className="fa-duotone fa-list"></i>,
      selected: pathnames[0] === RouterConfig.payables.slice(1),
    },
    {
      title: "Journal Entries",
      action: () => {
        navigate(RouterConfig.journalEntries);
      },
      startDecorator: <i className="fa-duotone fa-newspaper"></i>,
      selected: pathnames[0] === RouterConfig.journalEntries.slice(1),
    },
    {
      title: "Vendors",
      action: () => {
        navigate(RouterConfig.vendors);
      },
      startDecorator: <i className="fa-duotone fa-shop"></i>,
      selected: pathnames[0] === RouterConfig.vendors.slice(1),
    },
    {
      title: "Companies",
      action: () => {
        navigate(RouterConfig.companies);
      },
      startDecorator: <i className="fa-duotone fa-building"></i>,
      selected: pathnames[0] === RouterConfig.companies.slice(1),
    },
    {
      title: "GL Accounts",
      action: () => {
        navigate(RouterConfig.glAccounts);
      },
      startDecorator: <i className="fa-duotone fa-building-columns"></i>,
      selected: pathnames[0] === RouterConfig.glAccounts.slice(1),
    },
    {
      title: "Item Classes",
      action: () => {
        navigate(RouterConfig.itemClasses);
      },
      startDecorator: <i className="fa-duotone fa-tags"></i>,
      selected: pathnames[0] === RouterConfig.itemClasses.slice(1),
    },
    {
      title: "Exports",
      action: () => {
        navigate(RouterConfig.exports);
      },
      startDecorator: <i className="fa-duotone fa-share-from-square"></i>,
      selected: pathnames[0] === RouterConfig.exports.slice(1),
    },
  ];

  let rightItems = [
    {
      title: "Help",
      children: [
        {
          title: "Helpdesk",
          action: () => {
            window.open("https://docs.whitearrow.com/", "_blank");
          },
          startDecorator: <i className="fa-solid fa-bell-concierge"></i>,
          selected: false,
        },
        {
          title: "Keyboard Shortcuts",
          action: () => {
            dispatch(setFormOpen(true, "shortcut"));
          },
          startDecorator: <i className="fa-solid fa-keyboard"></i>,
          selected: false,
        },
      ],
      startDecorator: <i className="fa-solid fa-question"></i>,
      selected: false,
    },
  ];

  if (myAccess === "user") {
    leftItems = [
      {
        title: "My Payables",
        action: () => {
          navigate(RouterConfig.myPayables);
        },
        startDecorator: <i className="fa-duotone fa-receipt"></i>,
        selected: pathnames[0] === RouterConfig.myPayables.slice(1),
      },
    ];

    rightItems = [];
  }

  return (
    <NerdNavigationBar
      navigationBarIndex={1}
      chipVariant="plain"
      selectedChipVariant="solid"
      rightItems={rightItems}
      leftItems={leftItems}
    />
  );
}
