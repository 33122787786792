import { createReducer } from "@reduxjs/toolkit";
import { Company } from "../../entities/company";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteCompanySuccess,
  getCompanyByIdSuccess,
  getCompaniesSearchMetadataSuccess,
  getCompaniesSuccess,
  setCompanyFilters,
  setCompanyLoading,
  setCompanySearchOpen,
  setSelectedCompanyID,
  setStagedCompany,
  updateStagedCompany,
} from "./companyActions";
import { CompanyState } from "./companyState";

function initState(): CompanyState {
  return {
    companies: {},
    searchOpen: false,
    searchMetadata: undefined,
    loading: false,
    stagedCompany: undefined,
    selectedCompanyID: undefined,
  };
}

export const companyReducer = createReducer(initState(), (builder) => {
  builder.addCase(setCompanyLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setStagedCompany, (state, action) => {
    state.stagedCompany = action.payload.stagedCompany;
    return state;
  });
  builder.addCase(setCompanyFilters, (state, action) => {
    state.filters = action.payload.filters;
    return state;
  });
  builder.addCase(setCompanySearchOpen, (state, action) => {
    state.searchOpen = action.payload.searchOpen;
    return state;
  });
  builder.addCase(updateStagedCompany, (state, action) => {
    if (!state.stagedCompany) return state;
    state.stagedCompany = {
      ...state.stagedCompany,
      ...action.payload.stagedCompany,
    };
    return state;
  });
  builder.addCase(setSelectedCompanyID, (state, action) => {
    state.selectedCompanyID = action.payload.companyID;
    return state;
  });
  builder.addCase(getCompaniesSuccess, (state, action) => {
    const companies = action.payload.companies;
    state.companies = arrayToMap<Company>(companies);
    return state;
  });
  builder.addCase(getCompaniesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.companiesSearchMetadata;
    return state;
  });
  builder.addCase(deleteCompanySuccess, (state, action) => {
    const companyID = action.payload.id;
    if (state.companies[companyID]) {
      delete state.companies[companyID];
    }
    return state;
  });
  builder.addCase(getCompanyByIdSuccess, (state, action) => {
    const company = action.payload.company;
    if (company) {
      const companies = { ...state.companies };
      companies[company.id] = company;
      state.companies = companies;
    }
    return state;
  });
});
