import { Box, BoxProps, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RouterConfig } from "./atoms/config/routerConfig";
import { useAppSelector } from "./atoms/hooks";
import { AllPayables } from "./routes/allPayables";
import { Companies } from "./routes/companies";
import { Exports } from "./routes/exports";
import { GlAccounts } from "./routes/glAccounts";
import { ItemClasses } from "./routes/itemClasses";
import { JournalEntries } from "./routes/journalEntries";
import { MyPayables } from "./routes/myPayables";
import NotFoundRoute from "./routes/notFoundRoute";
import { Reports } from "./routes/reports";
import { Vendors } from "./routes/vendors";

const BoxWrapper = styled((props: { hideMarginTop?: boolean } & BoxProps) => (
  <Box {...props} />
))(({ theme, hideMarginTop }) => ({
  padding: "10px",
  marginTop:
    useMediaQuery(theme.breakpoints.down("sm")) || hideMarginTop
      ? "inherit"
      : "48px",
  background:
    theme.palette.mode !== "light"
      ? "rgba(0,0,0,0.9)"
      : "rgba(255,255,255,0.9)",
  backdropFilter: "blur(10px)",
  flexGrow: 1,
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  const currentUser = useAppSelector(
    (state) => state.accountKitState.userState.currentUser
  );

  const [search] = useSearchParams();
  const payableID = search.get("id");
  const navigate = useNavigate();

  if (payableID) {
    navigate(`${RouterConfig.myPayables}/${payableID}`);
  }
  return (
    <Routes>
      <Route
        path={RouterConfig.root}
        element={<Navigate to={`${RouterConfig.myPayables}`} />}
      ></Route>
      <Route
        path={RouterConfig.myPayables}
        element={
          <BoxWrapper>
            <MyPayables />
          </BoxWrapper>
        }
      >
        <Route path={":payableID/*"} />
      </Route>
      <Route
        path={RouterConfig.payables}
        element={
          <BoxWrapper>
            <AllPayables />
          </BoxWrapper>
        }
      >
        <Route path={":payableID/*"} />
      </Route>
      <Route
        path={RouterConfig.journalEntries}
        element={
          <BoxWrapper>
            <JournalEntries />
          </BoxWrapper>
        }
      >
        <Route path={":journalEntryID/*"} />
      </Route>
      <Route
        path={RouterConfig.companies}
        element={
          <BoxWrapper>
            <Companies />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.vendors}
        element={
          <BoxWrapper>
            <Vendors />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.glAccounts}
        element={
          <BoxWrapper>
            <GlAccounts />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.itemClasses}
        element={
          <BoxWrapper>
            <ItemClasses />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.reports}
        element={
          <BoxWrapper>
            <Reports />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.exports}
        element={
          <BoxWrapper>
            <Exports />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={"*"}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <NotFoundRoute />
          </BoxWrapper>
        }
      />
    </Routes>
  );
}
