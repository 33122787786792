import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../atoms/hooks";
import { getEmptyPayable } from "../helpers/accountingHelpers";
import { PayablesDataGrid } from "../hooks/payable/payableDataGrid";
import { setFormOpen } from "../redux/appStatus/appStatusActions";
import {
  setSelectedPayableID,
  setStagedPayable,
} from "../redux/payable/payableActions";
import {
  selectedPayableIDSelector,
  stagedPayableSelector,
} from "../redux/payable/payableSelector";

/**
 *
 * @returns {ReactElement} All Payables pages
 */
export function AllPayables() {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedPayableID = useAppSelector(selectedPayableIDSelector);
  const stagedPayable = useAppSelector(stagedPayableSelector);

  useEffect(() => {
    if (params.payableID) {
      if (params.payableID === "new" && !stagedPayable) {
        dispatch(setStagedPayable(getEmptyPayable()));
        dispatch(setFormOpen(true, "payable"));
      }
      if (params.payableID !== "new") {
        const payableID = parseInt(params.payableID);
        if (!isNaN(payableID) && payableID != selectedPayableID) {
          dispatch(setSelectedPayableID(payableID));
          dispatch(setFormOpen(true, "payable"));
        }
      }
    }
  }, [params]);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <PayablesDataGrid />
    </Paper>
  );
}
