import { createSelector } from "@reduxjs/toolkit";
import { File } from "../../entities/file";
import { JournalEntry } from "../../entities/journalEntry";
import { JournalEntryItem } from "../../entities/journalEntryItem";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectJournalEntries = (state: AppState) =>
  state.journalEntryState.journalEntries;
const selectJournalEntriesReadyToExport = (state: AppState) =>
  state.journalEntryState.journalEntriesReadyToExport;
const selectJournalEntrySearchMetadata = (state: AppState) =>
  state.journalEntryState.searchMetadata;
const selectJournalEntryFilters = (state: AppState) =>
  state.journalEntryState.filters;
const selectJournalEntrySearchOpen = (state: AppState) =>
  state.journalEntryState.searchOpen;
const selectSelectedJournalEntryID = (state: AppState) =>
  state.journalEntryState.selectedJournalEntryID;
const selectStageJournalEntrySelector = (s: AppState) =>
  s.journalEntryState.stagedJournalEntry;
const selectStageJournalEntryFilesSelector = (s: AppState) =>
  s.journalEntryState.stagedJournalEntry?.files;
const selectStageJournalEntryLineItemsSelector = (s: AppState) =>
  s.journalEntryState.stagedJournalEntry?.journalEntryItems;
const selectJournalEntryLoading = (s: AppState) => s.journalEntryState.loading;
const selectStagedJournalEntryLoading = (s: AppState) =>
  s.journalEntryState.stagedJournalEntryLoading;

export const journalEntriesReadyToExportSelector = createSelector(
  [selectJournalEntriesReadyToExport],
  (journalEntries) => {
    if (!journalEntries) return undefined;
    return JournalEntry.fromList(mapToArray(journalEntries)).sort(
      (a, b) => b.id - a.id
    );
  }
);

export const stagedJournalEntryLoadingSelector = createSelector(
  [selectStagedJournalEntryLoading],
  (s) => s
);

export const journalEntryLoadingSelector = createSelector(
  [selectJournalEntryLoading],
  (s) => s
);

export const journalEntriesSelector = createSelector(
  [selectJournalEntries],
  (journalEntries) =>
    JournalEntry.fromList(mapToArray(journalEntries)).sort(
      (a, b) => b.id - a.id
    )
);

export const selectedJournalEntrySelector = createSelector(
  [selectJournalEntries, selectSelectedJournalEntryID],
  (journalEntries, selectedJournalEntryID) => {
    if (selectedJournalEntryID)
      return new JournalEntry(journalEntries[selectedJournalEntryID]);
    return undefined;
  }
);

export const stagedJournalEntrySelector = createSelector(
  [selectStageJournalEntrySelector],
  (s) => (s ? new JournalEntry(s) : s)
);

export const stagedJournalEntryFilesSelector = createSelector(
  [selectStageJournalEntryFilesSelector],
  (s) => {
    const ret: File[] = [];
    s?.forEach((e) => ret.push(new File(e)));
    return ret.filter((f) => !f.toDelete);
  }
);

export const stagedJournalEntryLineItemsSelector = createSelector(
  [selectStageJournalEntryLineItemsSelector],
  (s) => {
    const ret: JournalEntryItem[] = [];
    s?.forEach((e) => ret.push(new JournalEntryItem(e)));
    return ret;
  }
);

export const selectedJournalEntryIDSelector = createSelector(
  [selectSelectedJournalEntryID],
  (s) => s
);

export const journalEntrySearchOpenSelector = createSelector(
  [selectJournalEntrySearchOpen],
  (s) => s
);

export const journalEntrySearchMetadataSelector = createSelector(
  [selectJournalEntrySearchMetadata],
  (s) => s
);

export const journalEntryFiltersSelector = createSelector(
  [selectJournalEntryFilters],
  (s) => s
);
