import { jsonLanguage } from "@codemirror/lang-json";
import { Alert, Button, Checkbox } from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import CodeMirror from "@uiw/react-codemirror";
import { useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../atoms/hooks";
import { SearchPreset } from "../../entities/searchPreset";
import { useQuery } from "../../nerdPaginationFooter/utils";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import { formOpenSelector } from "../../redux/appStatus/appStatusSelector";
import {
  createSearchPreset,
  deleteSearchPreset,
  setSelectedSearchPresetID,
  updateSearchPreset,
} from "../../redux/searchPreset/searchPresetActions";
import { searchPresetSelector } from "../../redux/searchPreset/searchPresetSelector";

const defaultSearchPreset = {
  keyword: "payables",
  global: false,
  color: "",
  name: "",
  order: 0,
  filter: "",
};

export default function SearchPresetForm() {
  const open = useAppSelector(formOpenSelector("searchPreset"));
  const presetID = useAppSelector(
    (s) => s.searchPresetState.selectedSearchPresetID
  );
  const searchPreset = useAppSelector(searchPresetSelector(presetID));
  const dispatch = useDispatch();
  const [_seachPreset, setSearchPreset] = useState<Partial<SearchPreset>>(
    searchPreset ?? defaultSearchPreset
  );
  const query = useQuery();

  useEffect(() => {
    return () => {
      dispatch(setSelectedSearchPresetID());
    };
  }, []);

  useEffect(() => {
    if (open && !presetID)
      setSearchPreset((sp) => ({ ...sp, filter: query.get("filters") ?? "" }));
  }, [open]);

  const onClose = () => {
    dispatch(setFormOpen(false, "searchPreset"));
  };

  const onSave = () => {
    if (presetID) {
      dispatch(updateSearchPreset(presetID, _seachPreset));
    } else {
      dispatch(createSearchPreset(_seachPreset));
    }
    onClose();
  };

  const onDelete = () => {
    if (presetID) {
      dispatch(deleteSearchPreset(presetID));
      onClose();
    }
  };

  let filterJSON = _seachPreset.filter;
  let valid = true;
  try {
    filterJSON = JSON.stringify(JSON.parse(_seachPreset.filter ?? ""), null, 2);
  } catch (error) {
    console.log("not valid");
    valid = false;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{searchPreset?.name ?? "New Search Preset"}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: "flex", flexDirection: "column", gap: 1 }}>
          <TextField
            size="small"
            autoFocus
            label="Name"
            fullWidth
            onChange={(e) =>
              setSearchPreset((sp) => ({ ...sp, name: e.target.value }))
            }
            value={_seachPreset.name ?? ""}
          />
          {!valid ? (
            <Alert size="sm" color="danger">
              JSON not valid
            </Alert>
          ) : (
            []
          )}
          <CodeMirror
            value={filterJSON}
            height="200px"
            extensions={[jsonLanguage]}
            onChange={(e) =>
              setSearchPreset((sp) => ({
                ...sp,
                filter: e,
              }))
            }
          />
          <Checkbox
            onClick={() =>
              setSearchPreset((sp) => ({
                ...sp,
                global: !(sp.global ?? false),
              }))
            }
            checked={_seachPreset.global ?? false}
            size="sm"
            label="Share with others"
          />
          <CirclePicker
            color={_seachPreset.color}
            onChangeComplete={(e) =>
              setSearchPreset((sp) => ({
                ...sp,
                color: e.hex,
              }))
            }
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        {presetID ? (
          <Button color="danger" size="sm" variant="soft" onClick={onDelete}>
            Delete
          </Button>
        ) : (
          <Box />
        )}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button color="neutral" size="sm" variant="soft" onClick={onClose}>
            Cancel
          </Button>
          <Button size="sm" variant="solid" onClick={onSave}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
