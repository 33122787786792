import {
  DeleteAction,
  GetAction,
  PostAction,
  PutAction,
} from "@nerdjs/nerd-network";
import { AnyAction, createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { ApExport, ApExport_Entity } from "../../entities/apExport";

export const setSelectedApExportID = createAction(
  "setSelectedApExportID",
  function prepare(apExportID?: number) {
    return {
      payload: {
        apExportID,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getApExports = createAction(
  "ap-dashboard/apExport/getApExports",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/exports`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const apExports = ApExport.fromList(json);
      dispatch(getApExportsSuccess(apExports));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getApExportsSuccess = createAction(
  "ap-dashboard/apExport/getApExportSuccess",
  function prepare(apExports: ApExport[]) {
    return {
      payload: {
        apExports,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getApExportsByFilter = createAction(
  "ap-dashboard/apExport/getApExportsByFilter",
  function prepare(query: { [key: string]: unknown }) {
    const request = new GetAction(`/ap-dashboard/exports`, query);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const apExports = ApExport.fromList(json);
      dispatch(getApExportsByFilterSuccess(apExports));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getApExportsByFilterSuccess = createAction(
  "ap-dashboard/apExport/getApExportsByFilterSuccess",
  function prepare(apExports: ApExport[]) {
    return {
      payload: {
        apExports,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getApExportById = createAction(
  "ap-dashboard/apExport/getById",
  function prepare(id: number) {
    const request = new GetAction(`/ap-dashboard/exports/${id}`);

    request.decodeResponse = (dispatch: Dispatch, json: ApExport_Entity) => {
      dispatch(getApExportByIdSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getApExportByIdSuccess = createAction(
  "ap-dashboard/apExport/getApExportByIdSuccess",
  function prepare(apExport?: ApExport_Entity) {
    return {
      payload: {
        apExport,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const deleteApExport = createAction(
  "ap-dashboard/removeApExportById",
  function prepare(id: number, successAction?: AnyAction) {
    const request = new DeleteAction(`/ap-dashboard/exports/${id}`);

    if (successAction)
      request.decodeResponse = (dispatch: Dispatch) => {
        dispatch(successAction);
      };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const deleteApExportSuccess = createAction(
  "ap-dashboard/apExports/deleteApExportSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const updateApExport = createAction(
  "ap-dashboard/apExports/updateApExport",
  function prepare(id: number, body: Partial<ApExport>) {
    const request = new PutAction(`/ap-dashboard/exports/${id}`, body);

    request.decodeResponse = (dispatch: Dispatch) => {
      dispatch(updateApExportSuccess(true));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const updateApExportSuccess = createAction(
  "ap-dashboard/apExports/updateApExportSuccess",
  function prepare(success: boolean) {
    return {
      payload: {
        success,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const createApExport = createAction(
  "ap-dashboard/apExports/createApExport",
  function prepare(body: Partial<ApExport>) {
    const request = new PostAction(`/ap-dashboard/exports`, body);

    request.decodeResponse = (dispatch: Dispatch, json: ApExport_Entity) => {
      dispatch(createApExportSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createApExportSuccess = createAction(
  "ap-dashboard/apExports/createApExportSuccess",
  function prepare(apExport: ApExport_Entity) {
    return {
      payload: {
        apExport,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const getApExportsSearchMetadata = createAction(
  "ap-dashboard/getApExportsSearchMetadata",
  function prepare() {
    const request = new GetAction(`/ap-dashboard/exports/searchMetadata`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      dispatch(getApExportsSearchMetadataSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getApExportsSearchMetadataSuccess = createAction(
  "ap-dashboard/getApExportsSearchMetadataSuccess",
  function prepare(apExportsSearchMetadata: unknown) {
    return {
      payload: {
        apExportsSearchMetadata,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
