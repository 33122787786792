import { createSelector } from "@reduxjs/toolkit";
import { File } from "../../entities/file";
import { LineItem } from "../../entities/lineItem";
import { Payable } from "../../entities/payable";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectPayables = (state: AppState) => state.payableState.payables;
const selectMyPayables = (state: AppState) => state.payableState.myPayables;
const selectPayablesReadyToExport = (state: AppState) =>
  state.payableState.payablesReadyToExport;
const selectPayableSearchMetadata = (state: AppState) =>
  state.payableState.searchMetadata;
const selectPayableFilters = (state: AppState) => state.payableState.filters;
const selectPayableSearchOpen = (state: AppState) =>
  state.payableState.searchOpen;
const selectSelectedPayableID = (state: AppState) =>
  state.payableState.selectedPayableID;
const selectStagePayableSelector = (s: AppState) =>
  s.payableState.stagedPayable;
const selectStagePayableFilesSelector = (s: AppState) =>
  s.payableState.stagedPayable?.files;
const selectStagePayableLineItemsSelector = (s: AppState) =>
  s.payableState.stagedPayable?.lineItems;
const selectPayableLoading = (s: AppState) => s.payableState.loading;
const selectStagedPayableLoading = (s: AppState) =>
  s.payableState.stagedPayableLoading;
const selectSelectedLineItemID = (state: AppState) =>
  state.payableState.selectedLineItemID;

export const selectedLineItemIDSelector = createSelector(
  [selectSelectedLineItemID],
  (p) => p
);

export const stagedPayableLoadingSelector = createSelector(
  [selectStagedPayableLoading],
  (s) => s
);

export const payableLoadingSelector = createSelector(
  [selectPayableLoading],
  (s) => s
);

export const payablesSelector = createSelector([selectPayables], (payables) =>
  Payable.fromList(mapToArray(payables)).sort((a, b) => b.id - a.id)
);

export const payablesReadyToExportSelector = createSelector(
  [selectPayablesReadyToExport],
  (payables) => {
    if (!payables) return undefined;
    return Payable.fromList(mapToArray(payables)).sort((a, b) => b.id - a.id);
  }
);

export const myPayablesSelector = createSelector(
  [selectMyPayables],
  (payables) =>
    Payable.fromList(mapToArray(payables)).sort((a, b) => b.id - a.id)
);

export const selectedPayableSelector = createSelector(
  [selectPayables, selectSelectedPayableID],
  (payables, selectedPayableID) => {
    if (selectedPayableID) return new Payable(payables[selectedPayableID]);
    return undefined;
  }
);

export const stagedPayableSelector = createSelector(
  [selectStagePayableSelector],
  (s) => (s ? new Payable(s) : s)
);

export const stagedPayableFilesSelector = createSelector(
  [selectStagePayableFilesSelector],
  (s) => {
    const ret: File[] = [];
    s?.forEach((e) => ret.push(new File(e)));
    return ret.filter((f) => !f.toDelete);
  }
);

export const stagedPayableLineItemsSelector = createSelector(
  [selectStagePayableLineItemsSelector],
  (s) => {
    const ret: LineItem[] = [];
    s?.forEach((e) => ret.push(new LineItem(e)));
    return ret;
  }
);

export const selectedPayableIDSelector = createSelector(
  [selectSelectedPayableID],
  (s) => s
);

export const payableSearchOpenSelector = createSelector(
  [selectPayableSearchOpen],
  (s) => s
);

export const payableSearchMetadataSelector = createSelector(
  [selectPayableSearchMetadata],
  (s) => s
);

export const payableFiltersSelector = createSelector(
  [selectPayableFilters],
  (s) => s
);
