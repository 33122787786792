import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ItemClassesDataGrid } from "../hooks/itemClass/itemClassDataGrid";
import { getItemClasses } from "../redux/itemClass/itemClassActions";

/**
 *
 * @returns {ReactElement} Item Classes page
 */
export function ItemClasses() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItemClasses());
  }, []);

  return (
    <Paper sx={{ flex: 1, display: "flex", height: "100%" }}>
      <ItemClassesDataGrid />
    </Paper>
  );
}
