import { withNetworkCompletionDispatch } from "@nerdjs/nerd-network";
import { NavigateFunction } from "react-router-dom";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { RouterConfig } from "../atoms/config/routerConfig";
import { LineItem_Entity } from "../entities/lineItem";
import { Payable } from "../entities/payable";
import { setFormOpen } from "./appStatus/appStatusActions";
import {
  createLineItem,
  deleteLineItem,
  deleteLineItemfile,
  updateLineItem,
  uploadPayableLineItemsFile,
} from "./lineItem/lineItemActions";
import {
  createPayable,
  createPayableSuccess,
  deletePayable,
  deletePayablefile,
  getMyPayablesByFilter,
  getPayableById,
  getPayablesByFilter,
  lockPayableSuccess,
  setSelectedPayableID,
  setStagedPayable,
  setStagedPayableLoading,
  stagedPayableCreateRequest,
  stagedPayableUpdateRequest,
  updatePayable,
  uploadPayablefile,
} from "./payable/payableActions";
import { AppState, store } from "./store";

const getPayables = () =>
  window.location.pathname.toLocaleLowerCase().indexOf("mypayables") !== -1
    ? getMyPayablesByFilter
    : getPayablesByFilter;

let uuids: string[] = [];
let navigate: NavigateFunction;
let callback: () => void;
let creating = false;
let interval: NodeJS.Timeout;
let close: boolean;

export const stagedPayableMiddleware: Middleware =
  (_api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const state: AppState = store.getState();
    const ncs = state.networkState.networkCompletionStack;
    const stagedPayable = state.payableState.stagedPayable;

    if (action.type == setFormOpen) {
      const formID = action.payload.formID;
      const open = action.payload.open;
      if (formID === "payable" && !open) {
        store.dispatch(setStagedPayableLoading(false));
      }
    }

    const closeModal = () => {
      store.dispatch(setSelectedPayableID());
      store.dispatch(setStagedPayable());
      store.dispatch(setFormOpen(false, "payable"));
      if (navigate)
        navigate({
          pathname:
            window.location.pathname.toLowerCase().indexOf("mypayables") !== -1
              ? RouterConfig.myPayables
              : RouterConfig.payables,
          search: window.location.search,
        });
    };

    if (action.type == deletePayable) {
      closeModal();
      setTimeout(() => {
        const search = new URLSearchParams(window.location.search);
        store.dispatch(
          getPayables()({
            filters: search.get("filters")
              ? JSON.stringify(JSON.parse(search.get("filters") ?? "[]"))
              : "[]",
            limit: search.get("limit")
              ? JSON.parse(search.get("limit") ?? "")
              : 25,
            offset: search.get("offset")
              ? JSON.parse(search.get("offset") ?? "")
              : 0,
          })
        );
      }, 500);
    }

    const isCompleted = () => {
      if (uuids.length) {
        let completed = true;
        uuids.forEach((uuid) => {
          if (ncs.some((e) => e[uuid])) {
            completed = false;
          }
        });
        if (completed && !creating) {
          uuids = [];
          clearInterval(interval);
          const search = new URLSearchParams(window.location.search);
          store.dispatch(
            getPayables()({
              filters: search.get("filters")
                ? JSON.stringify(JSON.parse(search.get("filters") ?? "[]"))
                : "[]",
              limit: search.get("limit")
                ? JSON.parse(search.get("limit") ?? "")
                : 25,
              offset: search.get("offset")
                ? JSON.parse(search.get("offset") ?? "")
                : 0,
            })
          );
          if (callback) {
            callback();
          } else {
            store.dispatch(setStagedPayableLoading(false));
          }
          if (close) {
            close = false;
            closeModal();
          } else if (stagedPayable) {
            try {
              store.dispatch(
                getPayableById(parseInt(window.location.pathname.split("/")[2]))
              );
            } catch (error) {
              console.log("Refresh Failed");
            }
          }
        }
      }
    };

    if (action.type == lockPayableSuccess && stagedPayable) {
      store.dispatch(getPayableById(stagedPayable.id));
      store.dispatch(setStagedPayableLoading(false));
    }

    if (action.type == "ApiErrorAction") {
      uuids = [];
      if (interval) clearInterval(interval);
      store.dispatch(setStagedPayableLoading(false));
      return next(action);
    }

    if (action.type == stagedPayableCreateRequest && stagedPayable) {
      navigate = action.payload.navigate;
      close = action.payload.close;
      creating = true;
      interval = setInterval(isCompleted, 1000);
      store.dispatch(setStagedPayableLoading(true));
      const stagedPayableClass = new Payable(stagedPayable);
      uuids.push(
        withNetworkCompletionDispatch(
          store.dispatch,
          createPayable(stagedPayableClass.body())
        )
      );
    }

    if (action.type == stagedPayableUpdateRequest && stagedPayable) {
      navigate = action.payload.navigate;
      close = action.payload.close;
      callback = action.payload.callback;
      interval = setInterval(isCompleted, 1000);
      store.dispatch(setStagedPayableLoading(true));
      const body = action.payload.body;
      const stagedPayableClass = new Payable(stagedPayable);

      if (stagedPayableClass.toUpdate || body) {
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updatePayable(stagedPayableClass.id, {
              ...stagedPayableClass.body(),
              ...body,
            })
          )
        );
      }

      const liToCreate = stagedPayableClass.lineItemsToCreate();
      const liToDelete = stagedPayableClass.lineItemsToDelete();
      const liToUpdate = stagedPayableClass.lineItemsToUpdate();

      liToCreate?.forEach((li) => {
        const callback = (lineItem: LineItem_Entity) => {
          li.filesToCreate().forEach((f) => {
            uuids.push(
              withNetworkCompletionDispatch(
                store.dispatch,
                uploadPayableLineItemsFile(lineItem.id, f.file!)
              )
            );
          });
        };
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            createLineItem(stagedPayable?.id, li.body(), callback)
          )
        );
      });

      liToUpdate?.forEach((li) => {
        li.filesToCreate().forEach((f) => {
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              uploadPayableLineItemsFile(li.id, f.file!)
            )
          );
        });
        li.filesToDelete().forEach((f) => {
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              deleteLineItemfile(li.id, f.id!)
            )
          );
        });
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            updateLineItem(li?.id, li.body())
          )
        );
      });

      liToDelete?.forEach((li) =>
        uuids.push(
          withNetworkCompletionDispatch(store.dispatch, deleteLineItem(li.id))
        )
      );

      const filesToCreate = stagedPayableClass.filesToCreate();
      const filesToDelete = stagedPayableClass.filesToDelete();

      filesToCreate?.forEach((f) => {
        if (f.file)
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              uploadPayablefile(stagedPayable?.id, f.file)
            )
          );
      });

      filesToDelete?.forEach((f) => {
        if (f.id)
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              deletePayablefile(stagedPayable?.id, f.id)
            )
          );
      });

      if (uuids.length === 0) closeModal();
    }

    if (action.type == createPayableSuccess && stagedPayable) {
      // we need to create the line items & upload all the files;
      const payable = action.payload.payable;
      const stagedPayableClass = new Payable(stagedPayable);
      const lineItemsToCreate = stagedPayableClass.lineItemsToCreate();
      lineItemsToCreate?.forEach((li) => {
        const callback = (lineItem: LineItem_Entity) => {
          li.filesToCreate().forEach((f) => {
            uuids.push(
              withNetworkCompletionDispatch(
                store.dispatch,
                uploadPayableLineItemsFile(lineItem.id, f.file!)
              )
            );
          });
        };
        uuids.push(
          withNetworkCompletionDispatch(
            store.dispatch,
            createLineItem(
              payable.id,
              { ...li, payableID: payable.id },
              callback
            )
          )
        );
      });
      const filesToCreate = stagedPayableClass.filesToCreate();
      filesToCreate?.forEach((f) => {
        if (f.file)
          uuids.push(
            withNetworkCompletionDispatch(
              store.dispatch,
              uploadPayablefile(payable.id, f.file)
            )
          );
      });
      creating = false;
    }

    return next(action);
  };
