import { User } from "@nerdjs/account-kit";

/**
 * GlLockDate_Entity type guard.
 *
 * @param {any} glLockDateJson GlLockDate object from API
 * @returns {boolean} Return true if type is GlLockDate_Entity
 */
function validator(
  glLockDateJson: unknown
): glLockDateJson is GlLockDate_Entity {
  if (typeof glLockDateJson === "object" && glLockDateJson != null) {
    GlLockDate_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(glLockDateJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class GlLockDate_Entity {
  static requiredFields = ["id"];

  constructor(glLockDateJson: unknown) {
    if (validator(glLockDateJson)) {
      this.id = glLockDateJson.id;
      this.date = glLockDateJson.date;
      this.companyID = glLockDateJson.companyID;
      this.createdByUUID = glLockDateJson.createdByUUID;
      this.createdBy = new User(glLockDateJson.createdBy);
      this.createdAt = glLockDateJson.createdAt;
      this.updatedAt = glLockDateJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${GlLockDate_Entity.name}`
      );
  }

  id: number;
  date: string;
  companyID: number;
  createdByUUID: string;
  createdBy: User;
  createdAt: Date;
  updatedAt: Date;
}
