import { createReducer } from "@reduxjs/toolkit";
import { ItemClass } from "../../entities/itemClass";
import { arrayToMap } from "../../helpers/reducerHelpers";

import {
  deleteItemClassSuccess,
  getItemClassByIdSuccess,
  getItemClassesSearchMetadataSuccess,
  getItemClassesSuccess,
  setSelectedItemClassID,
} from "./itemClassActions";
import { ItemClassState } from "./itemClassState";

function initState(): ItemClassState {
  return {
    itemClasses: {},
    searchMetadata: undefined,
    selectedItemClassID: undefined,
  };
}

export const itemClassReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedItemClassID, (state, action) => {
    state.selectedItemClassID = action.payload.itemClassID;
    return state;
  });
  builder.addCase(getItemClassesSuccess, (state, action) => {
    const itemClasses = action.payload.itemClasses;
    state.itemClasses = arrayToMap<ItemClass>(itemClasses);
    return state;
  });
  builder.addCase(getItemClassesSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.itemClassesSearchMetadata;
    return state;
  });
  builder.addCase(deleteItemClassSuccess, (state, action) => {
    const itemClassID = action.payload.id;
    if (state.itemClasses[itemClassID]) {
      delete state.itemClasses[itemClassID];
    }
    return state;
  });
  builder.addCase(getItemClassByIdSuccess, (state, action) => {
    const itemClass = action.payload.itemClass;
    if (itemClass) {
      const itemClasses = { ...state.itemClasses };
      itemClasses[itemClass.id] = itemClass;
      state.itemClasses = itemClasses;
    }
    return state;
  });
});
