import { Avatar, Button } from "@mui/joy";
import { Box, Rating, Tooltip, Typography } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridSelectionModel,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import { transactionTypes } from "../../constants";
import { Payable } from "../../entities/payable";
import {
  currencyFormatter,
  getWidthFromLocalStorage,
} from "../../helpers/accountingHelpers";
import { NerdPaginationFooter } from "../../nerdPaginationFooter/nerdPaginationFooter";
import { useQuery } from "../../nerdPaginationFooter/utils";
import { NerdSearchPresets } from "../../nerdSearchPresets/nerdSearchPresets";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  getMyPayables,
  getMyPayablesByFilter,
  getPayables,
  getPayablesByFilter,
  setStagedPayable,
} from "../../redux/payable/payableActions";
import {
  myPayablesSelector,
  payableLoadingSelector,
  payablesSelector,
} from "../../redux/payable/payableSelector";
import { priorityLevelsSelector } from "../../redux/priorityLevel/priorityLevelSelector";
import { setSelectedSearchPresetID } from "../../redux/searchPreset/searchPresetActions";
import { searchPresetsForKeywordSelector } from "../../redux/searchPreset/searchPresetSelector";
import { PayableSearch } from "./payableSearch";
import { getSearchPresets } from "../../redux/searchPreset/searchPresetActions";

/**
 *
 * @returns {ReactElement} PayablesDataGrid component
 */
export function PayablesDataGrid({
  my = false,
  payables,
  noToolbar,
  noFooter,
  select = false,
  onSelect,
}: {
  my?: boolean;
  payables?: Payable[];
  noToolbar?: boolean;
  noFooter?: boolean;
  select?: boolean;
  onSelect?: (a: GridSelectionModel) => void;
}) {
  const apiRef = useGridApiRef();
  let _payables = payables;
  if (my) {
    _payables = useAppSelector(myPayablesSelector);
  } else if (!_payables) {
    _payables = useAppSelector(payablesSelector);
  }

  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const loading = useAppSelector(payableLoadingSelector);
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    const body = {
      filters: query.get("filters"),
      offset: query.get("offset") ?? 0,
      limit: query.get("limit") ?? 25,
    };
    if (my) {
      dispatch(getMyPayablesByFilter(body));
    } else {
      dispatch(getPayablesByFilter(body));
    }
  }, []);

  const columns: GridColumns<Payable> = [
    {
      field: "id",
      headerName: "ID",
      width: getWidthFromLocalStorage("payable", "id", 80),
    },
    {
      field: "transactionType",
      headerName: "Type",
      width: getWidthFromLocalStorage("transactionType", "id", 30),
      renderCell: (p) => (
        <Tooltip
          title={transactionTypes.find((s) => s.id === p.value)?.name ?? ""}
        >
          <Avatar variant="outlined" size="sm" color="primary">
            <i
              className={
                transactionTypes.find((s) => s.id === p.value)?.faClass ?? ""
              }
            ></i>
          </Avatar>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: getWidthFromLocalStorage("payable", "status", 100),
      renderCell: (p) => {
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
            {p.row.locked ? (
              <i className="fa-regular fa-lock"></i>
            ) : (
              <i className="fa-regular fa-lock-open"></i>
            )}
            {p.row.lineItems?.length ? (
              <Tooltip title={`${p.row.lineItems?.length} line item(s)`}>
                <i className="fa-regular fa-list-ul"></i>
              </Tooltip>
            ) : (
              []
            )}
            {p.row.files?.length ? (
              <Tooltip title={`${p.row.files.length} file(s)`}>
                <i className="fa-regular fa-paperclip"></i>
              </Tooltip>
            ) : (
              []
            )}
            {p.row.exportID ? (
              <Tooltip title={`Batch #${p.row.exportID}`}>
                <i className="fa-regular fa-file-export"></i>
              </Tooltip>
            ) : (
              []
            )}
          </Box>
        );
      },
    },
    {
      field: "priorityLevel",
      headerName: "Priority",
      valueGetter: (p) => p.value.name,
      width: getWidthFromLocalStorage("payable", "priorityLevel", 80),
      renderCell: (p) => {
        const priorityLevel = priorityLevels.find(
          (pl) => pl.id === p.row.priorityLevelID
        );
        const tooltip = `${priorityLevel?.followUpDays} Follow up day${
          (priorityLevel?.followUpDays ?? 0) > 1 ? "s" : ""
        }`;
        return (
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Tooltip title={tooltip}>
              <div>
                <Rating
                  icon={
                    <i
                      style={{ fontSize: 14, padding: 2, color: "#2196f3" }}
                      className="fa-solid fa-flag"
                    ></i>
                  }
                  emptyIcon={
                    <i
                      style={{ fontSize: 14, padding: 2 }}
                      className="fa-regular fa-flag"
                    ></i>
                  }
                  size="small"
                  value={priorityLevels.length - p.row.priorityLevelID + 1}
                  readOnly
                  max={priorityLevels.length}
                />
              </div>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "referenceNumber",
      headerName: "Ref",
      width: getWidthFromLocalStorage("payable", "referenceNumber", 150),
    },
    {
      field: "date",
      headerName: "Date",
      width: getWidthFromLocalStorage("payable", "date", 120),
      valueFormatter: (d) => moment(d.value).format("MM/DD/YYYY"),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: getWidthFromLocalStorage("payable", "companyName", 150),
    },
    {
      field: "createdBy",
      headerName: "Created by",
      width: getWidthFromLocalStorage("payable", "createdBy", 150),
      valueGetter: (p) => p.row.createdBy?.fullname(),
      renderCell: (p) => (
        <Box sx={{ lineHeight: 0.8 }}>
          <Typography variant="body2">{p.value}</Typography>
          {p.row.createdAt ? (
            <Tooltip title={moment(p.row.createdAt).format("HH/MM/YYYY")}>
              <Typography variant="caption">
                {moment(p.row.createdAt).fromNow()}
              </Typography>
            </Tooltip>
          ) : (
            []
          )}
        </Box>
      ),
    },
    {
      field: "assignedTo",
      headerName: "Assigned to",
      width: getWidthFromLocalStorage("payable", "assignedTo", 150),
      valueGetter: (p) => (p.value ? p.row.assignedTo?.fullname() : undefined),
      renderCell: (p) => (
        <Box sx={{ lineHeight: 0.8 }}>
          <Typography variant="body2">{p.value ?? "Unassigned"}</Typography>
          {p.row.approved ? (
            <Tooltip title={moment(p.row.approvedDate).format("HH/MM/YYYY")}>
              <Typography
                sx={{ color: "#4caf50" }}
                color="primary"
                variant="caption"
              >
                Approved
              </Typography>
            </Tooltip>
          ) : (
            <Typography sx={{ color: "#f44336" }} variant="caption">
              Not Approved
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: "dualAssignedTo",
      headerName: "Dual Assigned to",
      width: getWidthFromLocalStorage("payable", "dualAssignedTo", 150),
      valueGetter: (p) =>
        p.value ? p.row.dualAssignedTo?.fullname() : undefined,
      renderCell: (p) => (
        <Box sx={{ lineHeight: 0.8 }}>
          <Typography variant="body2">{p.value ?? "Unassigned"}</Typography>
          {p.row.dualApproved ? (
            <Tooltip
              title={moment(p.row.dualApprovedDate).format("HH/MM/YYYY")}
            >
              <Typography
                sx={{ color: "#4caf50" }}
                color="primary"
                variant="caption"
              >
                Dual Approved
              </Typography>
            </Tooltip>
          ) : (
            <Typography sx={{ color: "#f44336" }} variant="caption">
              Not Approved
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: "vendorName",
      headerName: "Vendor",
      width: getWidthFromLocalStorage("payable", "vendorName", 150),
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      renderCell: (p) => {
        const balance = p.row.balance();
        const balanceMatchesAmount = p.row.balanceMatchesAmount();
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!balanceMatchesAmount ? (
              <Tooltip
                title={`Balance (${currencyFormatter.format(
                  balance
                )}) doesn't match the amount`}
              >
                <i
                  style={{ color: "#f44336" }}
                  className="fa-solid fa-triangle-exclamation"
                ></i>
              </Tooltip>
            ) : (
              []
            )}
            <Typography
              variant="body2"
              sx={{ color: !balanceMatchesAmount ? "#f44336" : undefined }}
            >
              {currencyFormatter.format(p.value)}
            </Typography>
          </Box>
        );
      },
      width: getWidthFromLocalStorage("payable", "amount", 110),
    },
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      width: getWidthFromLocalStorage("payable", "actions", 30),
      getActions: (p) => [
        <GridActionsCellItem
          key="dup"
          showInMenu
          icon={<i className="fa-solid fa-copy"></i>}
          label={`Duplicate`}
          onClick={() => {
            dispatch(setStagedPayable(p.row.duplicate()));
            dispatch(setFormOpen(true, "payable"));
          }}
          color="inherit"
        />,
      ],
    },
  ];

  // avoid re-rendering the datagrid when query params change
  const memoizedDatagrid = useMemo(() => {
    return (
      <DataGridPro
        onRowClick={(p) => {
          if (!select)
            navigate({
              pathname: `${p.row.id}`,
              search: window.location.search,
            });
        }}
        loading={!my && loading}
        onSelectionModelChange={onSelect}
        checkboxSelection={select}
        rows={_payables ?? []}
        disableSelectionOnClick={!select}
        columns={columns}
        experimentalFeatures={{ newEditingApi: true }}
        apiRef={apiRef}
        rowHeight={60}
        density="compact"
        getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
        components={{
          Toolbar: noToolbar ? undefined : CustomToolbar,
          Footer: noFooter ? undefined : CustomFooter,
        }}
        componentsProps={{ toolbar: { my }, footer: { my } }}
        initialState={{
          pinnedColumns: {
            left: ["id"],
            right: ["amount", "actions"],
          },
        }}
      />
    );
  }, [_payables, loading]);

  return memoizedDatagrid;
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar({ my }: { my: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchPresets = useAppSelector(
    searchPresetsForKeywordSelector("payables")
  );
  const apiRef = useGridApiContext();

  useEffect(() => {
    dispatch(getSearchPresets());
  }, []);

  const handleNewPayable = () => {
    navigate({
      pathname: "new",
      search: window.location.search,
    });
  };

  useHotkeys(`ctrl+enter`, handleNewPayable);

  return (
    <Box
      className="header"
      sx={{ display: "flex", gap: 1, p: 1, position: "relative" }}
    >
      <PayableSearch my={my} />
      <Tooltip title="ctrl + enter">
        <Button size="sm" variant="soft" onClick={handleNewPayable}>
          + New Payable
        </Button>
      </Tooltip>
      <Box sx={{ flexGrow: 1 }} />
      {!my ? (
        <Button
          endDecorator={<i className="fa-regular fa-file-export"></i>}
          size="sm"
          onClick={() => dispatch(setFormOpen(true, "export"))}
          variant="plain"
        >
          Export
        </Button>
      ) : (
        []
      )}
      <NerdSearchPresets
        searchPresets={searchPresets}
        onSearch={(q) => {
          dispatch(my ? getMyPayablesByFilter(q) : getPayablesByFilter(q));
          apiRef.current?.scrollToIndexes({ rowIndex: 0 });
        }}
        onEdit={(s) => {
          dispatch(setFormOpen(true, "searchPreset"));
          dispatch(setSelectedSearchPresetID(s.id));
        }}
      />
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom Footer
 */
function CustomFooter({ my }: { my: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let selector = payablesSelector;
  if (my) selector = myPayablesSelector;
  const payables = useAppSelector(selector);

  const handleNewPayable = () => {
    navigate({
      pathname: "new",
      search: window.location.search,
    });
  };

  useHotkeys(`ctrl+enter`, handleNewPayable);

  const handleOnSearch = (
    q:
      | {
          [key: string]: unknown;
        }
      | undefined
  ) => {
    if (my) {
      if (q) {
        dispatch(getMyPayables(q));
      } else {
        dispatch(getMyPayables());
      }
    } else {
      if (q) {
        dispatch(getPayablesByFilter(q));
      } else {
        dispatch(getPayables());
      }
    }
  };

  return (
    <NerdPaginationFooter onSearch={handleOnSearch} count={payables?.length} />
  );
}
