import PriorityLevel_Entity from "./priorityLevel";
export default class PriorityLevel extends PriorityLevel_Entity {
  static fromList(priorityLevelsJSON: unknown): Array<PriorityLevel> {
    const priorityLevels: PriorityLevel[] = [];
    if (priorityLevelsJSON)
      Array.isArray(priorityLevelsJSON) &&
        priorityLevelsJSON.forEach((priorityLevelJSON) => {
          priorityLevels.push(new PriorityLevel(priorityLevelJSON));
        });
    return priorityLevels;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
