import { Box, Button, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { ItemClass } from "../../entities/itemClass";
import { useAppSelector } from "../../atoms/hooks";
import { itemClassesSelector } from "../../redux/itemClass/itemClassSelector";

/**
 *
 * @returns {ReactElement} ItemClassesDataGrid component
 */
export function ItemClassesDataGrid() {
  const apiRef = useGridApiRef();
  const itemClasses = useAppSelector(itemClassesSelector);

  const columns: GridColumns<ItemClass> = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "name",
      headerName: "Name",
      width: 320,
    },
    {
      field: "refNum",
      headerName: "Ref Num",
      width: 120,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
    },
  ];

  return (
    <DataGridPro
      rows={itemClasses}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
      apiRef={apiRef}
      density="compact"
      getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
      components={{
        Toolbar: CustomToolbar,
        Footer: CustomFooter,
      }}
      componentsProps={{ toolbar: {}, footer: {} }}
      initialState={{
        pinnedColumns: {
          left: ["id"],
        },
      }}
    />
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewItemClass = () => {
    console.log("Do someting!", dispatch);
  };

  useHotkeys(`ctrl+enter`, handleNewItemClass);

  return (
    <Box className="header" sx={{ display: "flex", p: 1 }}>
      <GridToolbarFilterButton />
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  return <Box className="footer" sx={{ display: "flex" }}></Box>;
}
