import { createReducer } from "@reduxjs/toolkit";
import { VendorLineItem } from "../../entities/vendorLineItem";
import { arrayToMap } from "../../helpers/reducerHelpers";
import {
  deleteVendorLineItemSuccess,
  getVendorLineItemByIdSuccess,
  getVendorLineItemsSearchMetadataSuccess,
  getVendorLineItemsSuccess,
  setVendorLineItemFilters,
  setVendorLineItemLoading,
  setVendorLineItemSearchOpen,
  setSelectedVendorLineItemID,
  setStagedVendorLineItem,
  updateStagedVendorLineItem,
} from "./vendorLineItemActions";
import { VendorLineItemState } from "./vendorLineItemState";

function initState(): VendorLineItemState {
  return {
    vendorLineItems: {},
    searchOpen: false,
    searchMetadata: undefined,
    loading: false,
    stagedVendorLineItem: undefined,
    selectedVendorLineItemID: undefined,
  };
}

export const vendorLineItemReducer = createReducer(initState(), (builder) => {
  builder.addCase(setVendorLineItemLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setStagedVendorLineItem, (state, action) => {
    state.stagedVendorLineItem = action.payload.stagedVendorLineItem;
    return state;
  });
  builder.addCase(setVendorLineItemFilters, (state, action) => {
    state.filters = action.payload.filters;
    return state;
  });
  builder.addCase(setVendorLineItemSearchOpen, (state, action) => {
    state.searchOpen = action.payload.searchOpen;
    return state;
  });
  builder.addCase(updateStagedVendorLineItem, (state, action) => {
    if (!state.stagedVendorLineItem) return state;
    state.stagedVendorLineItem = {
      ...state.stagedVendorLineItem,
      ...action.payload.stagedVendorLineItem,
    };
    return state;
  });
  builder.addCase(setSelectedVendorLineItemID, (state, action) => {
    state.selectedVendorLineItemID = action.payload.vendorLineItemID;
    return state;
  });
  builder.addCase(getVendorLineItemsSuccess, (state, action) => {
    const vendorLineItems = action.payload.vendorLineItems;
    state.vendorLineItems = arrayToMap<VendorLineItem>(vendorLineItems);
    return state;
  });
  builder.addCase(getVendorLineItemsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.vendorLineItemsSearchMetadata;
    return state;
  });
  builder.addCase(deleteVendorLineItemSuccess, (state, action) => {
    const vendorLineItemID = action.payload.id;
    if (state.vendorLineItems[vendorLineItemID]) {
      delete state.vendorLineItems[vendorLineItemID];
    }
    return state;
  });
  builder.addCase(getVendorLineItemByIdSuccess, (state, action) => {
    const vendorLineItem = action.payload.vendorLineItem;
    if (vendorLineItem) {
      const vendorLineItems = { ...state.vendorLineItems };
      vendorLineItems[vendorLineItem.id] = vendorLineItem;
      state.vendorLineItems = vendorLineItems;
    }
    return state;
  });
});
