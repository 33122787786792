import { CircularProgress, Divider, useColorScheme } from "@mui/joy";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { useState } from "react";
import { useAppSelector } from "../../atoms/hooks";
import {
  stagedJournalEntryLoadingSelector,
  stagedJournalEntrySelector,
} from "../../redux/journalEntry/journalEntrySelector";
import JournalEntryFiles from "./journalEntryFiles";
import { JournalEntryForm } from "./journalEntryForm";
import { JournalEntryToolbar } from "./journalEntryToolbar";

/**
 *
 * @returns {ReactElement} JournalEntry component
 */
export function JournalEntry() {
  const [mobile, setMobile] = useState(false);
  const stagedJournalEntry = useAppSelector(stagedJournalEntrySelector);
  const allotmentJournalEntrySize = JSON.parse(
    localStorage.getItem("allotmentJournalEntrySize") ?? '["50%", "50%"]'
  );
  const { mode, setMode } = useColorScheme();
  const isDark = mode === "dark";
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const stagedJournalEntryLoading = useAppSelector(
    stagedJournalEntryLoadingSelector
  );

  const handleAllotmentChange = (e: number[]) => {
    if (e[1] < 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    localStorage.setItem("allotmentJournalEntrySize", JSON.stringify(e));
  };

  if (!stagedJournalEntry)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      {stagedJournalEntryLoading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            backdropFilter: "blur(20px)",
            zIndex: 99,
            display: "flex",
            alignItems: "center",
            background: isDark ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.3)",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography>Please wait, system processing...</Typography>
          </Box>
        </Box>
      ) : (
        []
      )}
      <JournalEntryToolbar />
      <Divider />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          position: "relative",
        }}
      >
        {sm ? (
          <Box
            sx={{
              p: 2,
              height: "calc(100vh - 48px)",
              overflowY: "scroll",
            }}
          >
            <JournalEntryForm mobile={mobile} />
          </Box>
        ) : (
          <Allotment
            vertical={false}
            onChange={handleAllotmentChange}
            defaultSizes={allotmentJournalEntrySize}
          >
            <Allotment.Pane minSize={300}>
              <Box sx={{ height: "calc(100vh - 48px)", position: "relative" }}>
                <JournalEntryFiles />
              </Box>
            </Allotment.Pane>
            <Allotment.Pane minSize={400} className="journalEntryFormSplitView">
              <Box
                sx={{
                  p: 2,
                  height: "calc(100vh - 48px)",
                  overflowY: "scroll",
                }}
              >
                <JournalEntryForm mobile={mobile} />
              </Box>
            </Allotment.Pane>
          </Allotment>
        )}
      </Box>
    </Box>
  );
}
