import LineItem_Entity from "./lineItem";
import { File } from "../file";
export default class LineItem extends LineItem_Entity {
  files: Array<File>;

  constructor(lineItemJson: LineItem_Entity) {
    super(lineItemJson);

    if (lineItemJson.files) {
      this.files = File.fromList(lineItemJson.files);
    } else {
      {
        this.files = [];
      }
    }
  }

  static fromList(lineItemsJSON: unknown): Array<LineItem> {
    const lineItems: LineItem[] = [];
    if (lineItemsJSON)
      Array.isArray(lineItemsJSON) &&
        lineItemsJSON.forEach((lineItemJSON) => {
          lineItems.push(new LineItem(lineItemJSON));
        });
    return lineItems;
  }

  filesToCreate() {
    return this.files.filter((f) => f.toCreate && !f.toDelete);
  }

  filesToDelete() {
    return this.files.filter((f) => f.toDelete && !f.toCreate);
  }

  body() {
    return {
      id: this.id,
      payableID: this.payableID,
      glAccountID: this.glAccountID,
      classID: this.classID,
      amount: this.amount,
      memo: this.memo,
      internalMemo: this.internalMemo,
      glEntryID: this.glEntryID,
      position: this.position,
    };
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
