import { createSelector } from "@reduxjs/toolkit";
import { Company } from "../../entities/company";
import { mapToArray } from "../../helpers/reducerHelpers";
import { AppState } from "../store";

const selectCompanies = (state: AppState) => state.companyState.companies;
const selectCompanySearchMetadata = (state: AppState) =>
  state.companyState.searchMetadata;
const selectCompanyFilters = (state: AppState) => state.companyState.filters;
const selectCompanySearchOpen = (state: AppState) =>
  state.companyState.searchOpen;
const selectSelectedCompanyID = (state: AppState) =>
  state.companyState.selectedCompanyID;
const selectStageCompanySelector = (s: AppState) =>
  s.companyState.stagedCompany;
const selectCompanyLoading = (s: AppState) => s.companyState.loading;

export const companyLoadingSelector = createSelector(
  [selectCompanyLoading],
  (s) => s
);

export const companiesSelector = createSelector(
  [selectCompanies],
  (companies) => mapToArray(companies)
);

export const selectedCompanySelector = createSelector(
  [selectCompanies, selectSelectedCompanyID],
  (companies, selectedCompanyID) => {
    if (selectedCompanyID) return companies[selectedCompanyID];
    return undefined;
  }
);

export const stagedCompanySelector = createSelector(
  [selectStageCompanySelector],
  (s) => (s ? new Company(s) : s)
);

export const selectedCompanyIDSelector = createSelector(
  [selectSelectedCompanyID],
  (s) => s
);

export const companySearchOpenSelector = createSelector(
  [selectCompanySearchOpen],
  (s) => s
);

export const companySearchMetadataSelector = createSelector(
  [selectCompanySearchMetadata],
  (s) => s
);

export const companyFiltersSelector = createSelector(
  [selectCompanyFilters],
  (s) => s
);

export const companySelector = (id?: number) => {
  return createSelector([selectCompanies], (companies) => {
    if (id) return companies[id];
    return undefined;
  });
};
