import { File_Entity } from "../file";

/**
 * LineItem_Entity type guard.
 *
 * @param {any} lineItemJson LineItem object from API
 * @returns {boolean} Return true if type is LineItem_Entity
 */
function validator(lineItemJson: unknown): lineItemJson is LineItem_Entity {
  if (typeof lineItemJson === "object" && lineItemJson != null) {
    LineItem_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(lineItemJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class LineItem_Entity {
  static requiredFields = [
    "id",
    "payableID",
    "glAccountID",
    "glAccountName",
    "classID",
    "className",
    "amount",
    "memo",
    "internalMemo",
    "glEntryID",
    "files",
    "createdAt",
    "updatedAt",
    "position",
  ];

  constructor(lineItemJson: unknown) {
    if (validator(lineItemJson)) {
      this.id = lineItemJson.id;
      this.payableID = lineItemJson.payableID;
      this.glAccountID = lineItemJson.glAccountID;
      this.glAccountName = lineItemJson.glAccountName;
      this.classID = lineItemJson.classID;
      this.className = lineItemJson.className;
      this.amount = lineItemJson.amount;
      this.memo = lineItemJson.memo;
      this.internalMemo = lineItemJson.internalMemo;
      this.glEntryID = lineItemJson.glEntryID;
      this.files = lineItemJson.files;
      this.createdAt = lineItemJson.createdAt;
      this.updatedAt = lineItemJson.updatedAt;
      this.toUpdate = lineItemJson.toUpdate;
      this.toCreate = lineItemJson.toCreate;
      this.toDelete = lineItemJson.toDelete;
      this.position = lineItemJson.position;
    } else {
      throw new Error(
        "Failed to create new instance of ${ LineItem_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  payableID?: number;
  glAccountID?: number;
  glAccountName?: string;
  classID?: number;
  className?: string;
  amount?: number;
  memo?: string;
  internalMemo?: string;
  glEntryID?: number;
  files?: Array<File_Entity>;
  createdAt?: string;
  updatedAt?: string;
  toUpdate?: boolean;
  toCreate?: boolean;
  toDelete?: boolean;
  position?: number;
}
