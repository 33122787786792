import { Button } from "@mui/joy";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridSelectionModel,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../atoms/hooks";
import { JournalEntry } from "../../entities/journalEntry";
import { currencyFormatter } from "../../helpers/accountingHelpers";
import { NerdPaginationFooter } from "../../nerdPaginationFooter/nerdPaginationFooter";
import { useQuery } from "../../nerdPaginationFooter/utils";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  getJournalEntries,
  getJournalEntriesByFilter,
  setStagedJournalEntry,
} from "../../redux/journalEntry/journalEntryActions";
import {
  journalEntriesSelector,
  journalEntryLoadingSelector,
} from "../../redux/journalEntry/journalEntrySelector";
import { searchPresetsForKeywordSelector } from "../../redux/searchPreset/searchPresetSelector";
import { JournalEntrySearch } from "./journalEntrySearch";

/**
 *
 * @returns {ReactElement} JournalEntriesDataGrid component
 */
export function JournalEntriesDataGrid({
  journalEntries,
  noToolbar,
  noFooter,
  select = false,
  onSelect,
}: {
  journalEntries?: JournalEntry[];
  noToolbar?: boolean;
  noFooter?: boolean;
  select?: boolean;
  onSelect?: (a: GridSelectionModel) => void;
}) {
  const apiRef = useGridApiRef();
  const _journalEntries =
    journalEntries ?? useAppSelector(journalEntriesSelector);
  const loading = useAppSelector(journalEntryLoadingSelector);
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getJournalEntriesByFilter({
        filters: query.get("filters"),
        offset: query.get("offset") ?? 0,
        limit: query.get("limit") ?? 25,
      })
    );
  }, []);

  const columns: GridColumns<JournalEntry> = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (p) => {
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "end" }}>
            {p.row.locked ? (
              <i className="fa-solid fa-lock"></i>
            ) : (
              <i className="fa-regular fa-lock-open"></i>
            )}
            {p.row.journalEntryItems.length ? (
              <Tooltip title={`${p.row.journalEntryItems.length} line item(s)`}>
                <i className="fa-solid fa-list-ul"></i>
              </Tooltip>
            ) : (
              []
            )}
            {p.row.files?.length ? (
              <Tooltip title={`${p.row.files.length} file(s)`}>
                <i className="fa-regular fa-paperclip"></i>
              </Tooltip>
            ) : (
              []
            )}
            {p.row.exportID ? (
              <Tooltip title={`Batch #${p.row.exportID}`}>
                <i className="fa-regular fa-file-export"></i>
              </Tooltip>
            ) : (
              []
            )}
          </Box>
        );
      },
    },
    {
      field: "referenceNumber",
      headerName: "Ref",
      width: 150,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      width: 150,
      valueGetter: (p) => p.row.createdBy?.fullname(),
      renderCell: (p) => (
        <Box sx={{ lineHeight: 0.8 }}>
          <Typography variant="body2">{p.value}</Typography>
          {p.row.createdAt ? (
            <Tooltip title={moment(p.row.createdAt).format("HH/MM/YYYY")}>
              <Typography variant="caption">
                {moment(p.row.createdAt).fromNow()}
              </Typography>
            </Tooltip>
          ) : (
            []
          )}
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      // valueFormatter: (d) => moment(d.value).format("MM/DD/YYYY"),
    },
    {
      field: "companyName",
      headerName: "Company",
      width: 150,
    },
    {
      field: "credits",
      headerName: "Credits",
      type: "number",
      renderCell: (p) => {
        const debits = p.row.debits();
        const credits = p.row.credits();
        const debitsMatchCredits = p.row.creditsMatchDebits();
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!debitsMatchCredits ? (
              <Tooltip
                title={`Credits (${currencyFormatter.format(
                  credits
                )}) doesn't match the debits ${currencyFormatter.format(
                  debits
                )}`}
              >
                <i
                  style={{ color: "#f44336" }}
                  className="fa-solid fa-triangle-exclamation"
                ></i>
              </Tooltip>
            ) : (
              []
            )}
            <Typography
              variant="body2"
              sx={{ color: !debitsMatchCredits ? "#f44336" : undefined }}
            >
              {currencyFormatter.format(credits)}
            </Typography>
          </Box>
        );
      },
      width: 110,
    },
    {
      field: "debits",
      headerName: "Debits",
      type: "number",
      renderCell: (p) => {
        const debits = p.row.debits();
        const credits = p.row.credits();
        const debitsMatchCredits = p.row.creditsMatchDebits();
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {!debitsMatchCredits ? (
              <Tooltip
                title={`Credits (${currencyFormatter.format(
                  credits
                )}) doesn't match the debits ${currencyFormatter.format(
                  debits
                )}`}
              >
                <i
                  style={{ color: "#f44336" }}
                  className="fa-solid fa-triangle-exclamation"
                ></i>
              </Tooltip>
            ) : (
              []
            )}
            <Typography
              variant="body2"
              sx={{ color: !debitsMatchCredits ? "#f44336" : undefined }}
            >
              {currencyFormatter.format(debits)}
            </Typography>
          </Box>
        );
      },
      width: 110,
    },
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      width: 30,
      getActions: (p) => [
        <GridActionsCellItem
          key="dup"
          showInMenu
          icon={<i className="fa-solid fa-copy"></i>}
          label={`Duplicate`}
          onClick={() => {
            dispatch(setStagedJournalEntry(p.row.duplicate()));
            dispatch(setFormOpen(true, "journalEntry"));
          }}
          color="inherit"
        />,
        <GridActionsCellItem
          key="dup"
          showInMenu
          icon={<i className="fa-solid fa-rotate-left"></i>}
          label={`Reverse Journal Entry`}
          onClick={() => {
            dispatch(setStagedJournalEntry(p.row.reverse()));
            dispatch(setFormOpen(true, "journalEntry"));
          }}
          color="inherit"
        />,
      ],
    },
  ];

  const memoizedDatagrid = useMemo(() => {
    return (
      <DataGridPro
        rows={_journalEntries}
        columns={columns}
        experimentalFeatures={{ newEditingApi: true }}
        apiRef={apiRef}
        onSelectionModelChange={onSelect}
        checkboxSelection={select}
        rowHeight={60}
        density="compact"
        onRowClick={(p) =>
          navigate({ pathname: `${p.row.id}`, search: window.location.search })
        }
        getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
        components={{
          Toolbar: noToolbar ? undefined : CustomToolbar,
          Footer: noFooter ? undefined : CustomFooter,
        }}
        componentsProps={{ toolbar: {}, footer: {} }}
        initialState={{
          pinnedColumns: {
            left: ["id"],
            right: ["actions"],
          },
        }}
      />
    );
  }, [_journalEntries, loading]);

  return memoizedDatagrid;
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchPresets = useAppSelector(
    searchPresetsForKeywordSelector("journalEntry")
  );
  const apiRef = useGridApiContext();

  const handleNewJournalEntry = () => {
    navigate({
      pathname: "new",
      search: window.location.search,
    });
  };

  useHotkeys(`ctrl+enter`, handleNewJournalEntry);

  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        p: 1,
        position: "relative",
        gap: 1,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <JournalEntrySearch />
        <Tooltip title="ctrl + enter">
          <Button size="sm" onClick={handleNewJournalEntry} variant="soft">
            + New Journal Entry
          </Button>
        </Tooltip>
      </Box>
      <Button
        endDecorator={<i className="fa-regular fa-file-export"></i>}
        size="sm"
        onClick={() => dispatch(setFormOpen(true, "journalEntryExport"))}
        variant="plain"
      >
        Export
      </Button>
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom Footer
 */
function CustomFooter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const journalEntries = useAppSelector(journalEntriesSelector);

  const handleNewJournalEntry = () => {
    navigate({
      pathname: "new",
      search: window.location.search,
    });
  };

  useHotkeys(`ctrl+enter`, handleNewJournalEntry);

  return (
    <NerdPaginationFooter
      onSearch={(q) =>
        q
          ? dispatch(getJournalEntriesByFilter(q))
          : dispatch(getJournalEntries())
      }
      count={journalEntries?.length}
    />
  );
}
