export const RouterConfig = {
  root: "/",
  payables: "/payables",
  myPayables: "/myPayables",
  companies: "/companies",
  vendors: "/vendors",
  glAccounts: "/gl-accounts",
  itemClasses: "/item-classes",
  journalEntries: "/journal-entries",
  reports: "/reports",
  exports: "/exports",
  happyCoding: "/happy-coding",
};
