import { Box, Button, Tooltip } from "@mui/material";
import {
  DataGridPro,
  GridColumns,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { GlAccount } from "../../entities/glAccount";
import { useAppSelector } from "../../atoms/hooks";
import { glAccountsSelector } from "../../redux/glAccount/glAccountSelector";

/**
 *
 * @returns {ReactElement} GlAccountsDataGrid component
 */
export function GlAccountsDataGrid() {
  const apiRef = useGridApiRef();
  const glAccounts = useAppSelector(glAccountsSelector);

  const columns: GridColumns<GlAccount> = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "type",
      headerName: "type",
      width: 100,
    },
    {
      field: "accountNumber",
      headerName: "Account #",
      width: 100,
    },
  ];

  return (
    <DataGridPro
      rows={glAccounts}
      columns={columns}
      experimentalFeatures={{ newEditingApi: true }}
      apiRef={apiRef}
      density="compact"
      getCellClassName={(p) => (p.field === "id" ? "read-only" : "")}
      components={{
        Toolbar: CustomToolbar,
        Footer: CustomFooter,
      }}
      componentsProps={{ toolbar: {}, footer: {} }}
      initialState={{
        pinnedColumns: {
          left: ["id"],
          right: ["accountNumber"],
        },
      }}
    />
  );
}

/**
 *
 * @returns {ReactElement} Custom toolbar for Datagrid
 */
function CustomToolbar() {
  const dispatch = useDispatch();

  const handleNewGlAccount = () => {
    console.log("Do someting!", dispatch);
  };

  useHotkeys(`ctrl+enter`, handleNewGlAccount);

  return (
    <Box className="header" sx={{ display: "flex", p: 1 }}>
      <GridToolbarFilterButton />
    </Box>
  );
}

/**
 *
 * @returns {ReactElement} Custom footer for Datagrid
 */
function CustomFooter() {
  return <Box className="footer" sx={{ display: "flex" }}></Box>;
}
