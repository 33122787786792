import { Autocomplete, Icon, InputBase, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PriorityLevel } from "../../entities/priorityLevel";
import { useAppSelector } from "../../atoms/hooks";
import { getPriorityLevels } from "../../redux/priorityLevel/priorityLevelActions";
import {
  priorityLevelsSelector,
  priorityLevelSelector,
} from "../../redux/priorityLevel/priorityLevelSelector";

/**
 * @param {priorityLevelAutocompleteProps} priorityLevelAutocompleteProps PriorityLevel object from API
 * @returns {ReactElement} PriorityLevel Autocomplete
 */
export function PriorityLevelAutocomplete({
  priorityLevelID,
  onChange,
  variant,
  size,
  helperText,
  disabled,
  autoFocus,
}: priorityLevelAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const priorityLevel = useAppSelector(priorityLevelSelector(priorityLevelID));
  const dispatch = useDispatch();

  useEffect(() => {
    if (priorityLevels.length === 0) {
      dispatch(getPriorityLevels());
    }
  }, []);

  useEffect(() => {
    if (priorityLevel) {
      setInputValue(priorityLevel.name);
    }
  }, [priorityLevel]);

  return (
    <Autocomplete
      value={priorityLevel ?? null}
      onChange={(_e, newValue) => {
        onChange(newValue);
      }}
      disabled={disabled}
      inputValue={inputValue}
      onInputChange={(_e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      size={size}
      getOptionLabel={(option) => `${option.name}`}
      options={priorityLevels}
      clearIcon={<Icon sx={{ fontSize: 15 }} className="fa-solid fa-xmark" />}
      renderInput={(params) =>
        variant === "inputBase" ? (
          <div ref={params.InputProps.ref}>
            <InputBase
              inputProps={{ ...params.inputProps }}
              fullWidth
              sx={{ fontSize: 14 }}
              placeholder="Select a priorityLevel..."
              autoFocus={autoFocus}
            />
          </div>
        ) : (
          <TextField
            variant={variant}
            {...params}
            fullWidth
            sx={{ fontSize: 14 }}
            placeholder="Select a priorityLevel..."
            autoFocus={autoFocus}
            label="PriorityLevel"
            helperText={helperText}
          />
        )
      }
    />
  );
}

type priorityLevelAutocompleteProps = {
  priorityLevelID?: number;
  onChange: (priorityLevel: PriorityLevel | null) => void;
  variant?: "standard" | "filled" | "outlined" | "inputBase" | undefined;
  size?: "small" | "medium" | undefined;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};
