import { createReducer } from "@reduxjs/toolkit";
import { GlAccount } from "../../entities/glAccount";
import { arrayToMap } from "../../helpers/reducerHelpers";

import {
  deleteGlAccountSuccess,
  getGlAccountByIdSuccess,
  getGlAccountsSearchMetadataSuccess,
  getGlAccountsSuccess,
  setSelectedGlAccountID,
} from "./glAccountActions";
import { GlAccountState } from "./glAccountState";

function initState(): GlAccountState {
  return {
    glAccounts: {},
    searchMetadata: undefined,
    selectedGlAccountID: undefined,
  };
}

export const glAccountReducer = createReducer(initState(), (builder) => {
  builder.addCase(setSelectedGlAccountID, (state, action) => {
    state.selectedGlAccountID = action.payload.glAccountID;
    return state;
  });
  builder.addCase(getGlAccountsSuccess, (state, action) => {
    const glAccounts = action.payload.glAccounts;
    state.glAccounts = arrayToMap<GlAccount>(glAccounts);
    return state;
  });
  builder.addCase(getGlAccountsSearchMetadataSuccess, (state, action) => {
    state.searchMetadata = action.payload.glAccountsSearchMetadata;
    return state;
  });
  builder.addCase(deleteGlAccountSuccess, (state, action) => {
    const glAccountID = action.payload.id;
    if (state.glAccounts[glAccountID]) {
      delete state.glAccounts[glAccountID];
    }
    return state;
  });
  builder.addCase(getGlAccountByIdSuccess, (state, action) => {
    const glAccount = action.payload.glAccount;
    if (glAccount) {
      const glAccounts = { ...state.glAccounts };
      glAccounts[glAccount.id] = glAccount;
      state.glAccounts = glAccounts;
    }
    return state;
  });
});
