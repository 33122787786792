import { Button, IconButton, Sheet } from "@mui/joy";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouterConfig } from "../../atoms/config/routerConfig";
import { useAppSelector } from "../../atoms/hooks";
import { setFormOpen } from "../../redux/appStatus/appStatusActions";
import {
  deleteJournalEntry,
  deleteJournalEntrySuccess,
  setSelectedJournalEntryID,
  setStagedJournalEntry,
  stagedJournalEntryCreateRequest,
  stagedJournalEntryUpdateRequest,
} from "../../redux/journalEntry/journalEntryActions";
import { stagedJournalEntrySelector } from "../../redux/journalEntry/journalEntrySelector";
import { useAskQuestion } from "../askQuestion/askQuestion";

/**
 *
 * @returns {ReactElement} JournalEntryToolbar page
 */
export function JournalEntryToolbar() {
  const stagedJournalEntry = useAppSelector(stagedJournalEntrySelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const askQuestion = useAskQuestion();

  const close = () => {
    dispatch(setSelectedJournalEntryID());
    dispatch(setStagedJournalEntry());
    dispatch(setFormOpen(false, "journalEntry"));
    navigate({
      pathname: RouterConfig.journalEntries,
      search: window.location.search,
    });
  };

  const onDelete = () => {
    if (stagedJournalEntry)
      askQuestion(
        `You are about to delete the journal Entry (#${
          stagedJournalEntry?.referenceNumber ?? ""
        })`,
        ["Cancel", "Yes"]
      ).then((i) => {
        if (i == 1) {
          dispatch(
            deleteJournalEntry(stagedJournalEntry.id, deleteJournalEntrySuccess)
          );
          close();
        }
      });
  };

  const onCreate = () => {
    dispatch(stagedJournalEntryCreateRequest(navigate, true));
  };

  const onUpdate = () => {
    dispatch(stagedJournalEntryUpdateRequest(navigate, true));
  };

  const onClose = () => {
    if (!stagedJournalEntry?.canClose()) {
      askQuestion(
        `You are about to close the window without saving. All your changes will be lost if you proceed.`,
        ["Cancel", "Yes"]
      ).then((i) => {
        if (i == 1) {
          close();
        }
      });
    } else {
      close();
    }
  };

  if (!stagedJournalEntry) return <div />;
  return (
    <Sheet
      sx={{
        p: 1,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
      }}
      variant="soft"
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={onClose}
            size="sm"
            color="neutral"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}></Box>
        {stagedJournalEntry.id ? (
          <Button size="sm" variant="outlined" onClick={onUpdate}>
            Save & Close
          </Button>
        ) : (
          []
        )}

        {stagedJournalEntry.toCreate ? (
          <Button size="sm" variant="outlined" onClick={onCreate}>
            Create & Close
          </Button>
        ) : (
          []
        )}
      </Box>
    </Sheet>
  );
}
